import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
   IQueueStats,
   IQueueMonitor,
   IAgentState,
   IChannel,
   IQueueStateMonitor,
} from "src/app/_interfaces/monitor";
import { Observable, Subject, of, from, timer } from "rxjs";
import {
   tap,
   flatMap,
   filter,
   map,
   shareReplay,
   switchMap,
   finalize,
   catchError,
   reduce,
   mergeMap,
   startWith,
} from "rxjs/operators";
import { SocketService } from "src/app/_services/socket.service";
import { AuxiliaryComponent } from "../auxiliary/auxiliary.component";
import { UsuarioService } from "src/app/_services/usuario.service";
import { EUsuarioRol } from "src/app/_interfaces/usuario";
import { QueueService, IPerformance } from "src/app/_services/queue.service";
import { FileHandlerService } from "src/app/_services/file.service";
import * as orderBy from "lodash/orderBy";
import * as dayjs from "dayjs";

@Component({
   selector: "app-queue",
   templateUrl: "./queue.component.html",
   styleUrls: ["./queue.component.css"],
})
export class QueueComponent implements OnInit, OnDestroy {
   inProgress = false;
   toOpAgent: IAgentState;
   channels$: Observable<IChannel[]>;
   queue$: Observable<IQueueMonitor>;
   queueState$: Observable<IQueueStateMonitor>;
   queueStats$: Observable<IQueueStats>;
   perfomenace$: Observable<IPerformance>;
   queryTrigger = new Subject<{ fecha: string; queuename: string }>();

   currentTime$ = this.$monitor
      .getServerTime()
      .pipe(startWith(new Date().getTime()));

   @ViewChild(AuxiliaryComponent)
   private agentOpsComponent: AuxiliaryComponent;

   get usuarioPermisos() {
      return this.$usuario.usuario.permisos <= EUsuarioRol.MANAGER;
   }

   constructor(
      private $router: Router,
      private $queue: QueueService,
      private $route: ActivatedRoute,
      private $monitor: SocketService,
      private $usuario: UsuarioService,
      private $file: FileHandlerService
   ) {}

   ngOnInit() {
      this.channels$ = timer(0, 2000).pipe(
         map(() => this.$monitor.getChannels())
      );

      const selectedQueue$ = this.$route.params.pipe(
         tap(({ queueid }) =>
            this.$monitor.setRooms([`queue-${queueid}`, "channels"])
         ),
         shareReplay()
      );

      this.perfomenace$ = this.queryTrigger.pipe(
         tap(() => (this.inProgress = true)),
         switchMap(({ fecha, queuename }) =>
            this.$queue.getPerformace(queuename, fecha)
         ),
         catchError(() => of(undefined)),
         tap(() => (this.inProgress = false)),
         finalize(() => (this.inProgress = false))
      );

      this.queueState$ = selectedQueue$.pipe(
         map(({ queueid }) => queueid as string),
         mergeMap((queueName) =>
            this.$monitor.getQueuesState().pipe(
               map((queuesState) =>
                  queuesState.find((q) => q.queue === queueName)
               ),
               filter((queueState) => !!queueState),
               // Preparar el objeto final
               map((queueState) => {
                  // Agentes ordenados primero por el estado.
                  const agentesOrdenados = queueState.agents.sort((a1, a2) => {
                     const idAgente1 = Number.parseInt(a1.agent);
                     const idAgente2 = Number.parseInt(a2.agent);
                     if (idAgente1 > idAgente2) {
                        return 1;
                     } else if (idAgente1 < idAgente2) {
                        return -1;
                     } else {
                        return 0;
                     }
                  });

                  // Arreglo auxiliar para agregar el nombre
                  const agentInQueue = queueState.state.agents.map(
                     (a) => a.idagente
                  );
                  const agentesStates = [
                     ...agentesOrdenados.filter(
                        (a) =>
                           agentInQueue.includes(a.agent) &&
                           a.status !== "OFFLINE"
                     ),
                     ...agentesOrdenados.filter(
                        (a) =>
                           agentInQueue.includes(a.agent) &&
                           a.status === "OFFLINE"
                     ),
                  ].map((agent) => ({
                     ...agent,
                     nombre: queueState.state.agents?.find(
                        (a) => a.idagente === agent.agent
                     ).nombre,
                  }));

                  return {
                     ...queueState,
                     agents: agentesStates,
                  };
               })
            )
         )
      );

      this.queue$ = selectedQueue$.pipe(
         map(({ queueid }) => queueid),
         flatMap((queuename) =>
            this.$monitor.getQueues().pipe(
               map((queues) => queues.find((q) => q.nombre === queuename)),
               filter((queue) => !!queue),
               map((queue) => {
                  // Orden de agentes
                  const { agentsNotOffline, agentsOffline } =
                     queue.agents.reduce(
                        (acc, curr) => {
                           if (curr.estado.includes("OFFLINE")) {
                              acc.agentsOffline.push(curr);
                           } else {
                              acc.agentsNotOffline.push(curr);
                           }
                           return acc;
                        },
                        { agentsNotOffline: [], agentsOffline: [] }
                     );

                  // Relación de canales con llamadas en espera.
                  const channels = this.$monitor.getChannels();
                  const queueChannels = queue.callers.map((caller) => ({
                     ...caller,
                     posicion: Number.parseInt(caller.posicion as string),
                     canal: channels.find((ch) => ch.Channel === caller.canal),
                  }));
                  return {
                     ...queue,
                     agents: [...agentsNotOffline, ...agentsOffline],
                     callers: orderBy(queueChannels, "posicion"),
                  };
               })
            )
         )
      );

      this.queueStats$ = selectedQueue$.pipe(
         flatMap(({ queueid }) =>
            this.$monitor.listenQueueStats$.pipe(
               map((stats) => stats.find((s) => s.queue === queueid)),
               filter((stats) => !!stats)
            )
         )
      );
   }

   ngOnDestroy() {
      const queue = this.$route.snapshot.params as { queueid: string };
      this.$monitor.exitRooms([`queue-${queue.queueid}`, "channels"]);
   }

   cargarOPS(agente: IAgentState) {
      this.toOpAgent = agente;
      this.agentOpsComponent.lanzarOps();
   }

   performance(fecha: string, queuename: string) {
      this.queryTrigger.next({
         fecha: fecha,
         queuename: queuename,
      });
   }

   editarQueue(queuename) {
      if (queuename === "new-queue") {
         this.$router.navigateByUrl(`dashboard/(view:callcenter/setup/_)`);
      } else {
         this.$router.navigateByUrl(
            `dashboard/(view:callcenter/setup/${queuename})`
         );
      }
   }

   descargarCSV(logs: IPerformance) {
      // const fecha = moment().set('hours', 0).set('minutes', 0).set('seconds', 0);
      const fecha = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
      from(logs.agente)
         .pipe(
            map((agente) => {
               const datos: Object[] = [];
               agente.llamadas.forEach((llamada, idx) => {
                  datos.push({
                     Hora: logs.hora[idx],
                     Agente: agente.name,
                     Llamadas: llamada.cant,
                     "Promedio en Linea": fecha
                        .add(llamada.tiempo, "second")
                        .format("HH:mm:ss"),
                  });
               });
               return datos;
            }),
            reduce((acc, curr) => [...acc, ...curr], []),
            map((data) => orderBy(data, ["Hora"])),
            tap((data) =>
               this.$file.exportCSVFile(
                  Object.keys(data[0]),
                  data,
                  "Rendimiento_" + new Date().getTime()
               )
            )
         )
         .subscribe();
   }
}
