export interface ICallBack {
    readonly id?: string;
    AMD: boolean;
    prefix: string | string [];
    nombreTroncal: string;
    prepend: number;
    valor: string;
    operacion: string;
}

export interface ICallbackRequest {
    id: string;
    numero: string;
    extra?: Object;
    fecha?: string;
}

export interface ILocalCallback {
    destino: string;
    origen: string;
    extra: Object;
}

export enum EOpcionesCallback {
    ENVIO_A_DID = 'DID',
    MARCAR_A_EXTENSION = 'TOEXTEN', // 'Macro(marcacionInterna,%g,%d)'
    GRUPO_EXTENSIONES = 'GRUPO',
    COLA_CALLCENTER =  'QUEUE'    // %g = queuename,prio
}
