<form autocomplete="off" novalidate #f="ngForm" (ngSubmit)="guadarRegla(f.value); f.reset()">
    <input type="hidden" name="id" [ngModel]="regla?.id">
    
    <div class="input-row" style="margin-bottom: 10px">
        <div class="row">
            <div class="col-xs-8"><label>Detección de Máquina<br>(AMD)</label></div>
            <div class="col-xs-4 text-right">
                <ui-toggleV1 name="AMD" [ngModel]="regla?.AMD"></ui-toggleV1>
            </div>
        </div>
        <small class="text-muted">Si AMD esta activo, colgará si detecta que es una máquina contestadora.</small>
    </div>

    <div class="input-row" style="margin-bottom: 10px">
        <label>Pefijos.</label>
        <input type="text" class="form-control" name="prefix" [ngModel]="regla?.prefix" required>
        <small class="text-muted">Separados por comas. Ej: 3XXXXXXXXX, 1XXXXXXX</small>
    </div>

    <div class="input-row" style="margin-bottom: 10px">    
        <label>Troncal para ejecutar llamada.</label>
        <select class="form-control" name="nombreTroncal" [ngModel]="regla?.nombreTroncal" required>
            <option value="">Seleccione una.</option>
            <option *ngFor="let t of troncal$ | async" [value]="t.nombre">{{t.nombre}}</option>
        </select>
    </div>

    <div class="input-row" style="margin-bottom: 10px">
        <label>Dígitos a remover antes de lanzar la llamada.</label>
        <input type="number" name="prepend" [ngModel]="regla?.prepend" min="0" max="9" class="form-control" placeholder="0">
    </div>
    
    <div class="input-row" style="margin-bottom: 10px">
        <div style="margin-bottom: 10px">
            <label>Operación a ejecutar cuando sea contestada.</label>
            <select class="form-control" name="operacion" #operacion [ngModel]="regla?.operacion">
                <option value="">Selecciona una...</option>
                <option [value]="op.operacion" *ngFor="let op of ops">{{op.nombre}}</option>
            </select>
        </div>
    </div>

    <div class="input-row" [ngSwitch]="operacion.value">
        <div *ngSwitchCase="_EnumOpIVR.ENVIO_A_DID">
            <label>Selecciona un DID al que desees desbordar las llamadas.</label>
            <select class="form-control" name="valor" [ngModel]="regla?.valor" style="margin-top: 3px" required>
                <option value="{{ivr.did}}" *ngFor="let ivr of (ivr$ | async)">{{ivr.did}} {{ivr.nombre}}</option>
            </select>
        </div>
        <div *ngSwitchCase="_EnumOpIVR.MARCAR_A_EXTENSION">
            <label>Selecciona una extensión.</label>
            <select class="form-control" name="valor" [ngModel]="regla?.valor" style="margin-top: 3px" required>
                <option value="{{p.usuario}}" *ngFor="let p of (peer$ | async)">Extensión {{p.usuario}} {{p.callerid}}</option>
            </select>
        </div>
        <div *ngSwitchCase="_EnumOpIVR.GRUPO_EXTENSIONES">
            <label>Selecciona un grupo.</label>
            <select class="form-control" name="valor" [ngModel]="regla?.valor" style="margin-top: 3px" required>
                <option value="{{g.idgrupo}}" *ngFor="let g of (grupo$ | async)">Extensión {{g.idgrupo}} {{g.nombre}}</option>
            </select>
        </div>
        <div *ngSwitchCase="_EnumOpIVR.COLA_CALLCENTER">
            <label>Selecciona una cola de Callcenter.</label>
            <ui-queue-selector [queues]="queue$ | async" name="valor" [ngModel]="regla?.valor" required></ui-queue-selector>
        </div>
        <div *ngSwitchDefault>Selecciona una operación...
            <input type="hidden" ngModel name="valor" required>
        </div>
    </div>
    <hr>
    <div class="text-right">
        <button type="button" class="btn btn-default" (click)="f.reset()">Reset</button>&nbsp;
        <button type="submit" [disabled]="!f.valid" class="btn btn-primary">Guardar Cambios</button>
    </div>
</form>
