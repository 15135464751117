import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { map, filter, flatMap, tap, mapTo } from "rxjs/operators";
import { Observable, from, BehaviorSubject } from "rxjs";
import {
   ICampanaDialer,
   EEstadoCampanaDialer,
   IContactoReparto,
   EEstadoContacto,
   EEstadoContactoDialer,
   ICampanaStats,
   ETipoCampanaDialer,
} from "../_interfaces/dialer";
import { HeaderService } from "./header.service";
import {
   ICampoDirectorio,
   ETipoCampoDirectorio,
} from "../_interfaces/directorio";
import { ObjectID } from "./_objectID";
import { IContacto } from "../_interfaces/contacto";

@Injectable()
export class DialerService {
   reparto: { current: IContactoReparto; previous: IContactoReparto };

   private readonly DIALER_URL = "dialer";
   private readonly repartos$ = new BehaviorSubject<IContactoReparto[]>([]);
   constructor(
      private $http: HttpClient,
      private readonly $header: HeaderService
   ) {}

   initStats(campana: ICampanaDialer): ICampanaStats {
      return {
         idcampana: campana.idcampana,
         nombre: campana.nombre,
         tipo: campana.tipo,
         estado: campana.estado,
         pendientes: 0,
         atendidas: 0,
         fallidas: 0,
         enCurso: 0,
         enAudio: 0,
         enEspera: 0,
         total: 0,
         reIntento: 0,
      };
   }

   getRepartoState() {
      return !!this.reparto ? this.reparto.current : ({} as IContactoReparto);
   }

   setRepartoState(reparto: IContactoReparto) {
      this.reparto = {
         previous: !!this.reparto ? this.reparto.current : undefined,
         current: reparto,
      };
   }

   clearReaprtoState() {
      this.setRepartoState({} as IContactoReparto);
   }

   crearContactosDialer(data: {
      campos: ICampoDirectorio[];
      contactos: IContacto[];
   }) {
      const { campos, contactos } = data;
      const campoContactoPrincipal = campos.find(
         (c) => c.tipo === ETipoCampoDirectorio.TELEFONO
      );
      const camposTelefonicos = campos
         .filter((c) => c.tipo === ETipoCampoDirectorio.TELEFONO_ALTERNATIVO)
         .map((c) => c.nombre);

      return from(contactos).pipe(
         map((contactoAsignado) => {
            const numeroPrincipal = campoContactoPrincipal
               ? contactoAsignado[campoContactoPrincipal.nombre]
               : undefined;

            const numeros = [
               numeroPrincipal,
               ...camposTelefonicos.map((c) => contactoAsignado[c]),
            ]
               .filter((numero: string) => !!numero)
               .map((numero) => numero.replace(/\D/g, ""))
               .filter((numero) => !!numero && numero.length > 1)
               .map((numero) => ({
                  estado: EEstadoContacto.EN_ESPERA,
                  numero: numero,
               }));

            return numeros.length > 0
               ? ({
                    id: ObjectID(),
                    idContacto: contactoAsignado._id,
                    estado: EEstadoContactoDialer.EN_ESPERA,
                    contacto: numeros,
                 } as IContactoReparto)
               : undefined;
         }),
         filter((reparto) => !!reparto)
      );
   }

   getCampanas$(): Observable<ICampanaDialer[]> {
      return this.$http.get<ICampanaDialer[]>(
         `${this.$header.getAPIurl()}/${this.DIALER_URL}`,
         { headers: this.$header.getHttpHeaders() }
      );
   }

   getCampana$(idCampana: string): Observable<ICampanaDialer> {
      return this.$http.get<ICampanaDialer>(
         `${this.$header.getAPIurl()}/${this.DIALER_URL}/${idCampana}`,
         { headers: this.$header.getHttpHeaders() }
      );
   }

   guardarCampana$(
      campana: ICampanaDialer,
      nueva = false
   ): Observable<ICampanaDialer> {
      const url = `${this.$header.getAPIurl()}/${this.DIALER_URL}`;
      const header = { headers: this.$header.getHttpHeaders() };

      return nueva
         ? this.$http
              .post(`${url}.v2`, campana, {
                 headers: this.$header.getHttpHeaders(),
                 responseType: "text",
                 observe: "events",
              })
              .pipe(
                 filter((event) => event.type === HttpEventType.Response),
                 mapTo({} as ICampanaDialer)
              )
         : this.$http.put<ICampanaDialer>(url, campana, header);
   }

   eliminarCampana(idCampana: string): Observable<boolean> {
      return this.$http.delete<boolean>(
         `${this.$header.getAPIurl()}/${this.DIALER_URL}/${idCampana}`,
         { headers: this.$header.getHttpHeaders() }
      );
   }

   consultarCampanas(
      estado: EEstadoCampanaDialer,
      tipo?: ETipoCampanaDialer,
      queueid?: string
   ) {
      return this.$http.post<ICampanaDialer[]>(
         `${this.$header.getAPIurl()}/${this.DIALER_URL}/search`,
         { estado, tipo, queueid },
         { headers: this.$header.getHttpHeaders() }
      );
   }

   cargarReparto(
      idCampana: string,
      agenteid?: string,
      estado?: EEstadoContactoDialer
   ) {
      return this.$http.post<IContactoReparto[]>(
         `${this.$header.getAPIurl()}/${this.DIALER_URL}/reparto/${idCampana}`,
         { estado, agenteid },
         { headers: this.$header.getHttpHeaders() }
      );
   }

   actualizarItemReparto(idCampana, reparto: IContactoReparto) {
      return this.$http.put<IContactoReparto>(
         `${this.$header.getAPIurl()}/${this.DIALER_URL}/reparto/${idCampana}`,
         reparto,
         { headers: this.$header.getHttpHeaders() }
      );
   }
}
