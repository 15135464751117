import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { IAgentMonitor } from "src/app/_interfaces/_all";
import { BehaviorSubject, of, throwError, timer } from "rxjs";
import {
   debounceTime,
   defaultIfEmpty,
   flatMap,
   map,
   takeUntil,
} from "rxjs/operators";
import {
   EComando,
   EResponse,
   SocketService,
} from "src/app/_services/socket.service";
import { MIXED_SKILL } from "src/app/_interfaces/agente";
import { IAgentStateMonitor } from "src/app/_interfaces/monitor";

@Component({
   selector: "app-manual",
   templateUrl: "./manual.component.html",
   styleUrls: ["./manual.component.css"],
})
export class ManualComponent implements OnInit {
   numero: FormControl;
   outboundCallRespose$ = new BehaviorSubject<{
      failed: boolean;
      inProgress: boolean;
      callid?: string;
      otherCallid?: string;
   }>({
      inProgress: false,
      failed: false,
   });

   @Input() agente: IAgentMonitor;
   @Output() callResponse = new EventEmitter<{
      callid?: string;
      otherCallid?: string;
   }>();

   get colasDisponibles() {
      return this.queuesNotOffline(this.agente.states).map((s) => s.queuename);
   }

   constructor(private $monitor: SocketService) {}

   ngOnInit() {
      this.numero = new FormControl("", Validators.required);
   }

   ejecutarLlamada(
      { numero },
      cola?: string,
      dialOutContext = EComando.MAKE_OUTBOUND_CONNECT,
      idcontacto?: string
   ): void {
      this.outboundCallRespose$.next({ failed: false, inProgress: true });

      let agentState: IAgentStateMonitor;
      if (!!cola) {
         agentState = this.agente.states.find((st) => st.queuename === cola);
      } else {
         agentState = this.queuesNotOffline(this.agente.states).shift();
      }

      if (!agentState) {
         console.error("No queue available");
         return;
      }

      // Colocar el agente en modo outbound si no lo esta
      const isAgentOutboundOrMixed = agentState.penalty >= MIXED_SKILL.MIN;

      const agenteEnCanal = `Local/${this.agente.idagente}@agent${
         agentState.modalidad_agente.includes("RINGBACK") ? "Callback/n" : ""
      }`;

      of([agenteEnCanal, numero])
         .pipe(
            // Prevents multiple actions.
            debounceTime(600),
            map(([agent, numero]) => {
               return {
                  numero: numero.replace(/\D/g, ""),
                  agente: agent,
                  penalty: isAgentOutboundOrMixed
                     ? agentState.penalty
                     : undefined,
                  cola: agentState.queuename,
                  idcontacto: idcontacto,
               };
            }),
            flatMap((data) =>
               this.$monitor
                  .enviarComando({
                     comando: dialOutContext,
                     data: data,
                  })
                  .pipe(
                     takeUntil(timer(6000)),
                     defaultIfEmpty({
                        tipo: EResponse.TIME_OUT,
                        data: { response: "Timeout Error" },
                     })
                  )
            ),
            map(
               ({ tipo, data }) =>
                  data as {
                     response: string;
                     callid?: string;
                     otherCallid?: string;
                  }
            ),
            flatMap((data) =>
               !!data && !!data.response && !data.response.includes("Error")
                  ? of(data)
                  : throwError({
                       message: data.response || "No data in response",
                       responseContent: data,
                    })
            )
         )
         .subscribe(
            (data) => {
               const { response, callid, otherCallid } = data as {
                  response: string;
                  callid: string;
                  otherCallid: string;
               };

               this.outboundCallRespose$.next({
                  failed: response.includes("Error"),
                  inProgress: false,
                  callid: callid,
                  otherCallid: otherCallid,
               });
               this.callResponse.emit({ callid, otherCallid });
            },
            (err) => {
               console.error(err);
               this.outboundCallRespose$.next({
                  failed: true,
                  inProgress: false,
               });
            }
         );
   }

   queuesNotOffline(agentStates: IAgentStateMonitor[]) {
      return [...agentStates.filter((st) => st.status !== "OFFLINE")];
   }
}
