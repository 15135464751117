import { NG_VALIDATORS, Validator, FormControl, ValidationErrors } from '@angular/forms';
import { Component, OnInit, SimpleChange, SimpleChanges, ViewChild, ElementRef, Directive } from '@angular/core';
import { Queue } from '../../../_interfaces/queue';
import { Agente } from '../../../_interfaces/agente';
import { IDBResponse } from '../../../_interfaces/responses';
import { Observable ,  of, empty, throwError, Subject } from 'rxjs';
import * as _ from 'lodash';

import { AgenteService } from '../../../_services/agente.service';
import { QueueService } from '../../../_services/queue.service';
import { SocketService, EComando } from '../../../_services/socket.service';
import { PollsService, IEncuentaFrm } from '../../../_services/polls.service';
import { flatMap, filter, map, tap, debounceTime, toArray, finalize, defaultIfEmpty, first, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { EUsuarioRol } from 'src/app/_interfaces/usuario';
import { FileHandlerService, IUploadFile } from 'src/app/_services/file.service';
import { HttpEventType } from '@angular/common/http';
import { DirectorioService } from 'src/app/_services/directorio.service';
import { IDirectorio } from 'src/app/_interfaces/directorio';

interface IChanges extends SimpleChanges {
    queue: SimpleChange
}

@Directive({
    selector: '[queuenameValidator]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: QueuenameValidatorDirective, multi: true }
    ]
})
export class QueuenameValidatorDirective implements Validator {
    validate(control: FormControl): ValidationErrors | null {
        if (!!control) {
            return /[^A-Za-z0-9_-]/.test(control.value) ? { invalidName: true } : null;  
        }
        return null;
    }
}

@Component({
    selector: 'queue-edit',
    templateUrl: 'queuesetup.component.html'
})
export class QueueSetupComponent implements OnInit {
    
    readOnly: boolean;
    audioPlayer = new Audio();
    queue$: Observable<Queue>;
    agentes$: Observable<Agente[]>
    polls$: Observable<IEncuentaFrm[]>
    directorios$: Observable<IDirectorio[]>
    agentesEdicion: { idagente: string }[] = [];
    uploadProgres$ = new Subject<{ fileType: string, progress: number }>();
    inProgress = false;
    actionResponse = {
        error: '',
        mensaje: ''
    };

    get isPrivileged() { return this.$usr.usuario.permisos <= EUsuarioRol.MANAGER } 
    readonly UPLOAD_URL = 'queue/audio';

    @ViewChild('fileUpload')
    fileUpload: ElementRef; 

    constructor(
        private $usr: UsuarioService, 
        private $queue: QueueService, 
        private $agente: AgenteService,
        private $monitor: SocketService, 
        private $poll: PollsService,
        private $directorio: DirectorioService,
        private $router: Router,
        private $route: ActivatedRoute,
        private $fileService: FileHandlerService
    ) { }
    
    ngOnChanges() {

        /*if (!!cambio.queue && !!cambio.queue.currentValue) {
            
            this.agentesEdicion = [...this.queue$.agentes as {idagente: string}[]];

            if (!!this.queue$.nombre) {
                this.audio_hold.src = '/ivr/q_' + this.queue$.nombre + '/hold.wav';
                this.audio_ann.src = '/ivr/' + this.queue$.nombre + '_ann.wav';
            }
        }*/
    }

    ngOnInit() { 

        this.agentes$ = this.$agente.getAgentes();
        this.polls$ = this.$poll.getEncuestas();
        this.directorios$ = this.$directorio.getDirectorios();

        this.queue$ = this.$route.params.pipe(
            first(),
            flatMap(({ queueid }) => this.$queue.getQueue(queueid)),
            // Filtar si encuentra la cola.
            flatMap(queue => !!queue && !!queue.nombre ? of(queue) : empty() ),
            // si no hay cola, iniciar una nueva.
            defaultIfEmpty(new Queue()),
            tap(queue => {
                this.readOnly = !!queue.nombre;
                this.agentesEdicion = [...queue.agentes as { idagente: string }[]];
            })
        );
    }

    startUpload(type: string, queuename: string) {
        const uploader = this.fileUpload.nativeElement; 
        uploader.onchange = () => {
            const nativeElementFiles = this.fileUpload.nativeElement.files;
            const file = nativeElementFiles.length > 0 ? nativeElementFiles[0] : undefined;
            if (!!file) {
                this.upload(type, queuename, { data: file, inProgress: false, progress: 0 });
            }
        };

        uploader.click();
    }

    private upload(type: string, queuename: string, file: IUploadFile) {
        this.inProgress = true;
        const form = new FormData();
        form.append('audio_attach', file.data);
        this.$fileService.upload(this.UPLOAD_URL, form).pipe(
            map(event => {  
                switch (event.type) {  
                  case HttpEventType.UploadProgress:  
                    const progress = Math.round(event.loaded * 100 / event.total);
                    this.uploadProgres$.next({ fileType: type, progress: progress })  
                    break;  
                  case HttpEventType.Response:  
                    return event;  
                }  
            }),
            filter(response => !!response),
            map(response => response.body as IDBResponse),
            flatMap(({ data }) => this.$monitor.enviarComando({
                comando: EComando.AUDIO_FORMAT,
                data: { in: data, out: this.audioUrl(type, queuename)}
            })),
            finalize(() => {
                this.inProgress = false;
                this.fileUpload.nativeElement.value = '';
                this.uploadProgres$.next({ fileType: type, progress: 100 })  
            }),
        ).subscribe(
            res => console.log(res),
            err => console.error(err)
        )
    }

    private audioUrl(type: string, queuename: string) {
        switch (type) {
            case 'HOLD': 
                return `ivr/q_${queuename}/hold.wav`;
            case 'ANN': 
                return `ivr/${queuename}_ann.wav`;
            case 'CALLBACK': 
                return `ivr/${queuename}-periodic-announce.wav`;
            default:
                return '';
        }
    }
    
    playAudio(queuename: string, type: string) {
        this.audioPlayer.src = this.audioUrl(type, queuename);
        this.audioPlayer.play();
    }

    stopAudio() {
        this.audioPlayer.pause();
        this.audioPlayer.currentTime = 0;
    }

    descargar(type: string, queuename: string) {
        this.$fileService.download(`/${this.audioUrl(type, queuename)}`, `${queuename}_${type.toLowerCase()}.wav`);
    }
    
    agregarAgente(idagente: string) {
        const _agentes = [...this.agentesEdicion];
        if (!_agentes.find(a => a.idagente === idagente)) {
            this.agentesEdicion = [..._agentes, { idagente: idagente }];
        }
    }
    
    removerAgente(idagente: string) {
        this.agentesEdicion = [...this.agentesEdicion.filter(a => a.idagente !== idagente)];
    }
    
    guardarCola(queue: Queue) {
        queue.nombre = queue.nombre.split(' ').join('_');
        queue = { ...queue, agentes: [...this.agentesEdicion]};

        of(queue).pipe(
            tap(() => this.inProgress = true),
            debounceTime(200),
            flatMap(cola => this.$queue.modificarQueue(({
                queue: cola,
                update: this.readOnly
            }))),
            // Escribir archivo de colas.
            flatMap(() => this.$monitor.enviarComando({
                comando: EComando.KERBERUS_FILE,
                data: {tipo: 'QUEUES'}
            }).pipe(
                // Diferenciar entre crear cola y editar cola. QUEUE_PARAMETERS = parametros
                map(() => (this.readOnly ? EComando.QUEUE_PARAMETERS : EComando.QUEUE_RELOAD)),
                flatMap(tipoComando => this.$monitor.enviarComando({
                    comando: tipoComando,
                    data: { queue: queue.nombre }
                }))
            )),
            toArray(),
            catchError(err => {
                this.actionResponse = {
                    error: 'No se pudo realizar esta operación correctamente.',
                    mensaje: ''
                };
                return throwError(err);
            }),
            tap(() => {
                this.actionResponse = {
                    error: '',
                    mensaje: !this.readOnly ? 'Cola creada exitosamente.' : 'Cola actualizada correctamente'
                };
                if (!this.readOnly) {
                    setTimeout(() => this.$router.navigateByUrl(`/dashboard/(view:callcenter)`), 2000);
                }
            }),
            finalize(() => this.inProgress = false)
        ).subscribe()
    }
    
    eliminarCola(queue: Queue) {
        const eliminar = confirm(`Desea eliminar la cola ${queue.nombre}?`)
        if (eliminar === true) {
            of(queue).pipe(
                tap(() => this.inProgress = true),
                debounceTime(200),
                flatMap(cola => this.$queue.modificarQueue({
                    queue: cola, 
                    update: undefined
                })),
                flatMap(() => this.$monitor.enviarComando({
                    comando: EComando.KERBERUS_FILE,
                    data: {tipo: 'QUEUES'}
                }).pipe(
                    flatMap(() => this.$monitor.enviarComando({
                        comando: EComando.QUEUE_RELOAD,
                        data: { queue: queue.nombre }
                    }))
                )),
                toArray(),
                // remover esta cola de las stats.
                tap(() => this.$monitor.removeQueueStats$ = queue.nombre),
                finalize(() => {
                    this.inProgress = false;
                    // modal
                    this.$router.navigateByUrl(`/dashboard/(view:callcenter)`);
                }),
            ).subscribe()
        }
    }

    regresar(queuename: string) {
        if (!queuename) {
            this.$router.navigateByUrl(`/dashboard/(view:callcenter)`);
        } else {
            this.$router.navigateByUrl(`/dashboard/(view:callcenter/${queuename})`);
        }
    }

} 
