import {Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {Subscription , Observable } from 'rxjs';

import * as _ from 'lodash';

import { SocketService, EComando } from '../../_services/socket.service';
import { PeerService } from '../../_services/peer.service';
import { UsuarioService } from '../../_services/usuario.service';
import { EUsuarioRol } from '../../_interfaces/usuario';
import { Peer } from '../../_interfaces/peer';
import { IPeerMonitor, ETipoPeer } from '../../_interfaces/monitor'
import { VpnTableComponent } from './vpn-table/vpn-table.component';
import { map, flatMap } from 'rxjs/operators';

@Component({
    selector: 'consola-ext',
    templateUrl: './consola-ext.component.html'
})
export class ConsolaComponent implements OnInit, OnDestroy {

    @ViewChild(VpnTableComponent) 
	 vpnKeysListTableComponenet: VpnTableComponent;
	 
	 get usuario() { return this.$usuario.usuario }
    _rol = EUsuarioRol;
    readOnly: boolean;
    peerEdicion: IPeerMonitor;
    peers: IPeerMonitor[] = [];
    troncales$: Observable<IPeerMonitor[]>;
    
    private peersSubs: Subscription;
    
    
    constructor(private $monitor: SocketService, private $peer: PeerService, private $usuario: UsuarioService) {
        this.peersSubs = this.$monitor.getPeers().pipe(
            map(peers => peers.filter(p => p.tipo === ETipoPeer[ETipoPeer.PEER])),
            // Buscar si los peers aun estan en this.peers sino borrarlos
            map(peers => {
                let i = this.peers.length;
                while (i--) {
                    if (_.findIndex(peers, ['usuario', this.peers[i].usuario]) === -1) { // no existe
                        this.peers.splice(i, 1);
                    }
                }
                return peers;
            }),
            flatMap(peers => peers),
            map(peer =>  {
                const channel = this.$monitor.getChannels().find(c => c.Channel.indexOf('/' + peer.usuario + '-') > -1)
                
                // Si existe un canal que apunte a esta extensión
                if (!!channel) {
                    peer.estado = channel.Stats;
                    // let time = new Date(channel.Duration * 1000);
                    // time.setHours(time.getHours() - 19);
                    let time = new Date('2017-01-01 00:00:00');
                    time = new Date(time.getTime() + Number.parseInt(channel.Duration) * 1000)

                    if (channel.Application === 'AppDial') {
                        // Llamada entrante
                        peer.extra = {
                            tiempo: time.getTime(),
                            canal:  'Entrante',
                            app:    channel.Application
                        }
                    } else {
                        peer.extra = {
                            tiempo: time.getTime(),
                            canal:  'O: ' + channel.Exten,
                            app:    channel.Application
                        }
                    }
                }
                
                
                // Setear estados
                if (peer.estado.indexOf('OK') > -1 || peer.estado.indexOf('LAGGED') > -1) {
                    peer.estado_class = 'btn-success';
                } else if (peer.estado.indexOf('Up') > -1 || peer.estado.indexOf('Ring') > -1) {
                    peer.estado_class = 'btn-danger';
                } else {
                    peer.estado_class = 'btn-default';
                }
                return peer;
            })
        ).subscribe(
            peer => {
                const index = this.peers.findIndex(p => p.usuario === peer.usuario);

                if (index < 0) { // No existe
                    this.peers.push(peer);
                    this.peers = _.cloneDeep(_.sortBy(this.peers, 'usuario'));

                } else if (JSON.stringify(peer) !== JSON.stringify(this.peers[index])) { 
                    // Comparar si los valores del objeto han cambiado
                    this.peers[index] = peer;
                }
                
            },
            err => console.warn(err)
        );
            
        this.troncales$ = this.$monitor.getPeers().pipe(
            map(peers => peers.filter(p => p.tipo === ETipoPeer[ETipoPeer.TRUNK])),
            map(peers => {
                peers.forEach(peer =>  {

                    peer.channels = this.$monitor.getChannels().filter( c => {
                        return c.Channel.indexOf('/' + peer.usuario + '-') > -1;
                    });
                    
                    if (peer.estado.indexOf('OK') > -1 || peer.estado.indexOf('LAGGED') > -1) {
                        peer.estado_class = 'btn-success';

                    } else if (peer.estado.indexOf('BUSY') > -1) { // Solo en canales dahdi
                        peer.estado_class = 'btn-danger';
                    } else if (peer.estado.indexOf('RESERVED') > -1) {  // Solo en canales dahdi
                        peer.estado_class = 'none';
                    } else {
                        peer.estado_class = 'btn-default';
                    }
                });
                return peers;
            })
        );
    }
    
    ngOnInit() { 
        // Escuchar eventos
        this.$monitor.setRooms([`peers`, `channels`]);
    }
    
    ngOnDestroy() {
        // dejar de escuchar
        this.$monitor.exitRooms([`peers`, `channels`]);
        this.peersSubs.unsubscribe()
    }

    recargarArchvos() {
        this.vpnKeysListTableComponenet.listarArchivos();
    }
    
    nuevaExt() {
        this.readOnly = false;
        this.peerEdicion = <IPeerMonitor>{};
        $('#modalEditPeer').modal()
    }
    
    editarExt(peer: IPeerMonitor) {
        this.readOnly = true;
        this.peerEdicion = peer;
        $('#modalEditPeer').modal()
    }
    
    actualizarPeer(data: { peer: Peer, update: boolean}) {
        this.$peer.updatePeer(data).pipe(
            map(() => data.peer.protocolo),
            flatMap(protocolo => this.$monitor.enviarComando({
                comando: EComando.KERBERUS_FILE,
                data: { tipo: protocolo + '_CONF'}
            })),
            flatMap(() => this.$monitor.enviarComando({
                comando: EComando.KERBERUS_FILE,
                data: { tipo: 'EXTEN_EXTRA'}
            }))
        ).subscribe(res => {
            console.log(res);
            this.recargarArchvos();
            $('#modalEditPeer').modal('hide')
        })
    }
} 
