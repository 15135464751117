<div class="row">
    <div class="col-sm-3">

        <div class="panel panel-primary">
            <div class="panel-heading"><i class="fa fa-globe"></i> Troncales</div>
            <ul class="list-group" style="margin-bottom: 0">
                <li style="padding: 10px; border-bottom: 0" class="list-group-item" *ngFor="let t of (troncales$ | async)">
                    <troncal [troncal]="t"></troncal>
                </li>
            </ul>
        </div>

    </div>
    <div class="col-sm-9">

    <div>
    <!-- Nav tabs -->
    <ul class="nav nav-tabs" role="tablist" style="font-size: 13px">
        <li role="presentation" class="active">
            <a href="#home" aria-controls="home" role="tab" data-toggle="tab">
                <i class="fa fa-headphones"></i> Extensiones
            </a>
        </li>
        <li role="presentation" *ngIf="usuario.permisos === _rol.ADMIN">
            <a href="#vpn" aria-controls="vpn" role="tab" data-toggle="tab" (click)="recargarArchvos()">
                <i class="fa fa-lock"></i> Certificados VPN
            </a>
        </li>                
        <li role="presentation" *ngIf="usuario.permisos === _rol.ADMIN">
            <a href="javascript:void(0)" (click)="nuevaExt()">
                <i class="fa fa-plus-circle"></i> Crear Extensión
            </a>
        </li>
    </ul>

    <div class="tab-content" style="margin-top: 10px" id="peerTabs">
        <div role="tabpanel" class="tab-pane active" id="home">
            <div class="col-md-2 col-sm-3 col-xs-6 text-center" style="margin-bottom: 10px; padding: 5px" *ngFor="let e of peers">
                <extension [exten]="e" (editar)="editarExt($event)" [mod]="usuario.permisos === _rol.ADMIN"></extension>
            </div>
        </div>
        <div role="tabpanel" class="tab-pane" id="vpn">
            <app-vpn-table></app-vpn-table>
        </div>
    </div>
    </div>
</div>
<div class="modal fade" tabindex="-1" role="dialog" id="modalEditPeer">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h5 class="modal-title"><strong><i class="fa fa-phone"></i> Edición de extensión {{peerEdicion?.usuario}}</strong></h5>
        </div>
        <div class="modal-body">
            <peer-tag [peerMonitor]="peerEdicion" [readOnly]="readOnly" (peerOutput)="actualizarPeer($event)"></peer-tag>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
