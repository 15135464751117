
<div style="margin-top: 10px;">
   <div class="panel panel-default">
      <div class="panel-heading text-left" style="background-color: #f3f3f3">
            <div class="row">
               <div class="col-xs-9"><span style="font-weight: 600" class="text-muted"> {{titulo?.texto}}</span></div>
               <div class="col-xs-3 text-right">
                  <a (click)="regresar()">
                        <i class="fa fa-arrow-circle-left"></i> Regresar
                  </a>
               </div>
            </div>
      </div>
      <div class="modal-body" *ngIf="!actionInprogress.loading; else loading">
            <div class="alert alert-danger text-justify" *ngIf="!channelSnapshot && !actionInprogress.loading" style="margin-bottom: 10px">
               <strong>Precacución!</strong><br>
               Esta información no esta relacionada con ninguna llamada, si guarda no será asociada a ninguna llamada.
            </div>

            <!-- En caso de que no haya llamada en snapshot, escucha eventos del agente. -->
            <ng-container *ngIf="!channelSnapshot && !actionInprogress.loading">
               <div class="alert alert-info" *ngIf="channelInCall$ | async" style="margin-bottom: 10px">
                  <i class="fa fa-info-circle"></i> ...Pero puedes asociarla a la llamada en curso dando 
                  <a href="javascript:void(0)" (click)="asignarLlamada()"><strong>click aquí!</strong></a>
               </div>
            </ng-container>

            <div class="alert alert-success text-center" *ngIf="!!channelSnapshot" style="margin-bottom: 10px; padding: 10px">
               <i class="fa fa-info-circle"></i> Llamada 
               <ng-container *ngIf="!!channelSnapshot && !!channelSnapshot.CallerID">
                  con <strong>"{{channelSnapshot?.CallerID}}" </strong> 
               </ng-container> asociada correctamente.
            </div>
            
            <p *ngIf="titulo && titulo.subtitulo && titulo.subtitulo !== ''" class="text-muted text-justify" style="padding: 0px 5px">
               {{titulo.subtitulo}}
            </p>
            
            <form novalidate autocomplete="off" [formGroup]="frmTipificacion">
               <input type="hidden" formControlName="callid">
               <input type="hidden" formControlName="fecha">
               <input type="hidden" formControlName="idautor">
               <input type="hidden" formControlName="autor">
               <input type="hidden" formControlName="cola">
               <input type="hidden" formControlName="numero">
               <input type="hidden" formControlName="idformulario">
               <div formArrayName="preguntas">
                  <ng-container *ngFor="let registro of preguntasArray.controls; let i = index">
                        <div [formGroupName]="i" class="form-group">
                           <input type="hidden" formControlName="tipo" #tipoPregunta>
                           <h5 style="font-weight: 600; font-size: 15px;">
                              <i class="fa fa-question-circle-o" style="margin-right: 10px;"></i> {{registro.value.enunciado}}
                           </h5>

                           <div [ngSwitch]="tipoPregunta.value" style="margin-left: 30px; margin-top: 15px;">
                              <div *ngSwitchCase="'ABIERTA'">
                                    <input type="text" formControlName="respuesta" placeholder="Respuesta"
                                       style="border: 0; 
                                       border-bottom: 1px dotted #cccccc; padding: 5px; width: 100%">
                              </div>
                              <div *ngSwitchCase="'NUMERO'">
                                    <input type="text" formControlName="respuesta" placeholder="Respuesta"
                                       style="border: 0; 
                                       border-bottom: 1px dotted #cccccc; padding: 5px; width: 100%">
                              </div>
                              <div *ngSwitchCase="'TEL'">
                                    <input type="tel" formControlName="respuesta" placeholder="Respuesta"
                                       style="border: 0; 
                                       border-bottom: 1px dotted #cccccc; padding: 5px; width: 100%">
                              </div>
                              <div *ngSwitchCase="'FECHA'">
                                    <input type="text" ngDatePicker formControlName="respuesta">
                              </div>
                              <div *ngSwitchCase="'SELECCION'">
                                    <ng-container *ngFor="let posible of registro.value.respuestas">
                                       <div [ngSwitch]="posible.tipo">
                                          <div class="radio radio-success" *ngSwitchCase="'SIMPLE'">
                                                <!---->
                                                <input type="radio" formControlName="respuesta" [value]="posible.respuesta"> 
                                                <label>{{posible.respuesta}}</label>
                                          </div>
                                          <div class="radio radio-success" *ngSwitchDefault>
                                                <input type="radio" #otro formControlName="respuesta" [value]="otroTxt.value">
                                                <label>Otro</label>
                                                <input type="text" formControlName="respuesta" #otroTxt [readonly]="!otro.checked" placeholder="Cual?"
                                                   style="display: inline; border: 0; 
                                                   border-bottom: 1px dotted #cccccc; padding: 5px; margin-left: 10px;">
                                          </div>
                                       </div>
                                    </ng-container>
                              </div>
                              <div *ngSwitchDefault>
                                    <input type="text" formControlName="respuesta" placeholder="Respuesta"
                                       style="border: 0; 
                                       border-bottom: 1px dotted #cccccc; padding: 5px; width: 100%">
                              </div>
                              <small class="text-danger" *ngIf="registro.controls.respuesta.errors && (registro.dirty || registro.touched)">
                                    <h6 *ngIf="registro.controls.respuesta.errors.required">* Este campo es necesario.</h6>
                                    <h6 *ngIf="registro.controls.respuesta.errors.invalidDate">* Este valor de fecha es inválido.</h6>
                                    <h6 *ngIf="registro.controls.respuesta.errors.hasInvalidChars">
                                       * Este campo contiene caracteres inválidos, solo se permiten números y guiones medios.
                                    </h6>
                              </small>
                           </div>
                        </div>
                  </ng-container>
               </div>  
            </form>

            <div style="font-size: 11px; margin: 10px 5px " class="text-muted">
               * Las tipificaciones quedarán firmadas por el agente o usuario en esta sesión.
            </div>
            <div class="alert alert-danger text-left" style="margin: 0 5px;" *ngIf="actionInprogress.error">
               <strong><i class="fa fa-warning"></i> Algo salió mal!</strong><br>
               Ocurrio un error de comunicación al tratar de guardar esta tipificación, intenta nuevamente.
            </div>
      </div>
      <ng-template #loading>
         <div class="modal-body text-center text-muted" style="margin-top: 40px; margin-bottom: 37px;">
            <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br><br>Cargando preguntas, un momento por favor...
         </div>
      </ng-template>
      <div class="panel-footer text-right" style="padding-right: 10px;">
            <a (click)="regresar()" style="margin-right: 20px;">Cancelar</a>
            <button type="button" class="btn btn-primary" 
            [disabled]="!frmTipificacion.valid ||actionInprogress.saving" 
            (click)="guardarFormulario(frmTipificacion.value)"> 
               <span  *ngIf="actionInprogress.saving; else defaultBtn">
                  <i class="fa fa-spinner fa-spin"></i> Guardando
               </span>
               <ng-template #defaultBtn>Guardar tipificación</ng-template>
            </button>
      </div>
   </div>
</div><!-- /.modal-content -->



<div class="modal fade" tabindex="-1" role="dialog" id="modal_tipitificacion_ok">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-body text-center">
            <i class="fa fa-check-circle text-success"></i> Tipificación guardada exitosamente.
        </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->