<div class="text-muted">
   <!-- Nav tabs -->
   <ul class="nav nav-pills nav-justified" role="tablist">
      <li role="presentation" class="active" style="padding-right: 5px;">
         <a href="#manual" aria-controls="manual" role="tab" data-toggle="tab">Manual</a>
      </li>
      <li role="presentation">
         <a href="#vistaprevia" aria-controls="vistaprevia" role="tab" data-toggle="tab">Vista previa</a>
      </li>
      <li role="presentation" style="padding-left: 5px;">
         <a href="#progresivo" aria-controls="progresivo" role="tab" data-toggle="tab">Progresivo</a>
      </li>
   </ul>
   <!-- Tab panes -->
   <div class="tab-content" style="margin-top: 10px;">
      <div role="tabpanel" class="tab-pane fade in active" id="manual">
         <app-manual [agente]="agente" (callResponse)="callResponse$.next($event)"></app-manual>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="vistaprevia">
         <app-vista-previa [colas]="agentInQueues" [agente]="agente" [callStatus]="callStatus"
            (contacto)="cargarContacto($event)"></app-vista-previa>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="progresivo">
         <app-progresivo></app-progresivo>
      </div>
   </div>
</div>