import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "statusColor",
})
export class StatusColorPipe implements PipeTransform {
   transform(estado: string): string {
      switch (estado) {
         case "OFFLINE": {
            return "default";
         }
         case "PAUSE": {
            return "warning";
         }
         case "INBOUND_BUSY_PAUSE":
         case "INBOUND_BUSY": {
            return "danger";
         }
         case "OUTBOUND_BUSY_PAUSE":
         case "OUTBOUND_BUSY": {
            return "primary";
         }
         case "INBOUND_IDLE": {
            return "success";
         }
         case "OUTBOUND_IDLE": {
            return "info";
         }
         default: {
            return "default";
         }
      }
   }
}
