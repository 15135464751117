import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Agente } from '../../_interfaces/agente';

@Component({
    selector: 'agente-detail',
    template: `
    <form #f="ngForm" novalidate class="form-group form-group-sm" style="font-size: 13px; margin-bottom:0">
    <div class="row" *ngIf="agente && readOnly !== undefined">
        <div class="col-md-7">
            <div class="row">
                <div class="col-xs-12" style="margin-bottom: 10px">
                    <label>Nombre del Agente</label>
                    <input type="text" [(ngModel)]="agente.nombre" name="nombre" class="form-control" required>
                </div>
                <div class="col-xs-6" style="margin-bottom: 10px">
                    <label>ID Agente</label>
                    <input type="text" [(ngModel)]="agente.idagente" [readonly]="readOnly" name="idagente" class="form-control text-center" required>
                </div>
                <div class="col-xs-6" style="margin-bottom: 10px">
                    <label>Contraseña</label>
                    <input type="text" [(ngModel)]="agente.pass" name="pass" class="form-control text-center" placeholder="Contraseña" required>  
                </div>
                <div class="col-xs-12" style="margin-bottom: 10px">
                    <label>ID de Agente para integraciones</label>
                    <input type="text" [(ngModel)]="agente.idintegracion" name="idintegracion" class="form-control">
                    <small class="text-muted">ID de Agente en otras plataformas, Ej: Freshdesk</small>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col-xs-12" style="margin-bottom: 10px">
                    <label>Skill por defecto</label>
                    <select class="form-control text-center" name="penalidad" [(ngModel)]="agente.penalidad" required>
                        <option [value]="100">Mixto</option>
                        <option [value]="0">Inbound</option>
                        <option [value]="-1">Outbound</option>
                        <option [value]="i" *ngFor="let i of [1,2,3,4,5,6,7,8,9]">{{i}}</option>
                    </select>
                    <small class="text-muted">* Cada vez que se loguee</small>
                </div>
                <div class="col-xs-12" style="margin-bottom: 5px"></div>
                <div class="col-xs-8" style="margin-bottom: 10px">
                    <label>Puede Pausar?</label>
                </div>
                <div class="col-xs-4" style="margin-bottom: 10px">
                    <ui-toggleV1 name="canPause" [(ngModel)]="agente.canPause" required></ui-toggleV1>    
                </div>
                <div class="col-xs-12" style="margin-bottom: 5px"></div>
                <div class="col-xs-8" style="margin-bottom: 10px">
                    <label>Puede Colgar?</label>
                </div>
                <div class="col-xs-4" style="margin-bottom: 10px">
                    <ui-toggleV1 name="canHangup" [(ngModel)]="agente.canHangup" required></ui-toggleV1>    
                </div>
                <div class="col-xs-12" style="margin-bottom: 5px"></div>
                <div class="col-xs-8" style="margin-bottom: 10px">
                    <label>Puede Transferir?</label>
                </div>
                <div class="col-xs-4" style="margin-bottom: 10px">
                    <ui-toggleV1 name="canXFER" [(ngModel)]="agente.canXFER" required></ui-toggleV1>    
                </div>
            </div>
        </div>
        <div class="col-xs-12 text-right" style="margin-bottom: 0px">
            <hr>
            <button *ngIf="readOnly === true" class="btn btn-danger btn-sm" (click)="eliminar(f.value)"><i class="fa fa-trash"></i></button>&nbsp;
            <button class="btn btn-primary btn-sm" (click)="guardar(f.value)" [disabled]="f.invalid" ><i class="fa fa-save" style="margin-right: 5px"></i> Guardar</button>
        </div>
    </div>
    </form>
    `
})
export class AgenteDetalleComponent implements OnInit {
    
    @Input() readOnly: boolean;
    @Input() agente: Agente;
    @Output() agenteEmitter = new EventEmitter <{agente: Agente, update: boolean}>();
    constructor() { }
    
    ngOnInit() { }
    
    guardar(agente: Agente) {
        this.agenteEmitter.emit({
            agente: agente, 
            update: this.readOnly
        });
    }
    
    eliminar(agente: Agente) {
        this.agenteEmitter.emit({
            agente: agente, 
            update: undefined
        });
    }
} 
