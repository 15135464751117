import { Router } from '@angular/router';
import { Observable, from, zip, of } from 'rxjs';
import { ITipificacionLog } from 'src/app/_interfaces/_all';
import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { filter, map, flatMap, groupBy, mergeMap, toArray, tap } from 'rxjs/operators';
import { ETipoRespuestaTipificacion } from 'src/app/_interfaces/forma';
import * as cloneDeep from 'lodash.clonedeep';

interface ISimpleChanges extends SimpleChanges {
    resultados: ISimpleChange
}

interface ISimpleChange extends SimpleChange {
    currentValue: ITipificacionLog[];
}

@Component({
  selector: 'app-graficos-tipificacion',
  templateUrl: './graficos-tipificacion.component.html',
  styleUrls: ['./graficos-tipificacion.component.css']
})
export class GraficosTipificacionComponent implements OnInit, OnChanges {

    plots$: Observable<Highcharts.Options[]>;
    private plotOptions: Highcharts.Options;
    
    @Input() resultados: ITipificacionLog[];

    constructor(private $router: Router) { }

    ngOnInit() {
        this.plotOptions = {
                chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                width: 450,
                height: 270,
            },
            colors: [ '#109618', '#3366CC', '#FF9900', '#0099c6', '#DD4477', '#DB4437', '#4285F4', '#990099', '#00CCFF', '#33CC99', '#CC3300', '#666666' ],
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b> ({point.percentage}%)'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f} %: ({point.y})'
                    }
                }
            },
            series: [],
            credits: {
                enabled: false
            }
        };
    }

    ngOnChanges(cambios: ISimpleChanges) {
        if (!!cambios.resultados) {
            this.plots$ = from(cambios.resultados.currentValue).pipe(
                map(resultado => resultado.preguntas.filter(p => p.tipo === ETipoRespuestaTipificacion.SELECCION)),
                filter(resultado => resultado.length > 0),
                flatMap(resultado => resultado),
                groupBy(pregunta => pregunta.enunciado),
                mergeMap(grupo => zip(of(grupo.key), grupo.pipe(toArray()))),
                map(([ enunciado, resultados ]) => ({ enunciado, resultados: resultados })),
                // tap(data => console.log(data)),
                // Agrupar por respuesta
                flatMap(({ enunciado, resultados }) => from(resultados).pipe(
                    groupBy(preg => preg.respuesta),
                    mergeMap(agrupado => zip(of(agrupado.key), agrupado.pipe(toArray()))),
                    map(([ respuesta, cantidad ]) => {
                        // slices of pie
                        return {
                            name: respuesta,
                            y: cantidad.length
                        };
                    }),
                    toArray(),
                    map(slices => {
                        const plotOption = cloneDeep(this.plotOptions);
                        plotOption.title.text = enunciado;
                        plotOption.subtitle.text = `Respuesta(s): ${resultados.length}`;
                        plotOption.series.push({
                            name: 'Respuesta(s)',
                            data: slices
                        });

                        return plotOption;
                    }),
                )),
                toArray()
            );
        }
    }

    regresar() {
        this.$router.navigateByUrl('/dashboard/(view:call-typing)');
    }

}
