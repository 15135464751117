<div class="btn-group" role="group" aria-label="...">
    <button type="button" class="btn btn-sm" style="width: 110px" [ngClass]="exten?.estado_class" (click)="editarExt(exten)">
        <strong><i class="fa fa-phone"></i> {{exten?.usuario}}</strong><br>
        {{(exten?.extra?.tiempo || zeroDate) | date:'HH:mm:ss'}}<br>
        {{exten?.extra?.canal || exten?.callerid || 'N/A'}}
    </button>  
    <button type="button" class="btn btn-sm" style="width: 30px" [ngClass]="exten?.estado_class" (click)="editarExt(exten)">
        <i class="fa fa-check"></i><br>
    <br><i class="fa fa-caret-down"></i></button>
</div>
