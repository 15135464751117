import { Injectable } from "@angular/core";
import io, { Socket } from "socket.io-client";
import { sortBy, isEqual } from "lodash";
import {
   Observable,
   Observer,
   Subject,
   BehaviorSubject,
   Subscription,
   of,
} from "rxjs";

import {
   IPeerMonitor,
   IChannel,
   IQueueMonitor,
   IQueueAlert,
   IAgentState,
   IQueueStats,
   IQueueStateMonitor,
   IAgentStateMonitor,
   IQueueState,
} from "../_interfaces/monitor";
import { HeaderService } from "./header.service";
import { SOCKET_SERVER } from "../../_environments/environment";
import { share, filter, shareReplay } from "rxjs/operators";
import { ICampanaStats, IContactoReparto } from "../_interfaces/dialer";

@Injectable()
export class SocketService {
   private socket: Socket;
   private heartbeart$ = new Subject<number>();
   private dbChanges$ = new Subject<String>();
   private agents$ = new Subject<IAgentState>();
   private agentState$ = new BehaviorSubject<IAgentStateMonitor[]>(undefined);
   private peers$ = new BehaviorSubject<IPeerMonitor[]>([]);
   private queues$ = new BehaviorSubject<IQueueMonitor[]>([]);
   private queueState$ = new BehaviorSubject<IQueueStateMonitor[]>([]);
   private channels$ = new BehaviorSubject<IChannel[]>([]);
   private queueAlerts$ = new Subject<IQueueAlert>();
   private queueCallback$ = new Subject<Object>();
   private sysInfo$ = new BehaviorSubject<Object>(undefined);
   private queueStats$ = new BehaviorSubject<IQueueStats[]>([]);
   private dialer$ = new Subject<any>();
   private reparto$ = new BehaviorSubject<IContactoReparto[]>([]);

   private messages$ = new Subject<{ fuente: string; mensaje: string }>();
   private roomsLogued = [];

   private monitorSubscription: Subscription;

   get listenQueueStats$() {
      return this.queueStats$.asObservable().pipe(share());
   }
   set removeQueueStats$(queuename: string) {
      this.queueStats$.next(
         this.queueStats$.value.filter((q) => q.queue !== queuename)
      );
   }

   constructor(private $header: HeaderService) {
      this.socket = io(
         `${SOCKET_SERVER || ""}/kerberusipbx?token=${this.$header.getHeader(
            "Authorization"
         )}&ipbxid=${this.$header.getHeader("ipbxid")}`
      );
      this.socket.connect();

      this.monitorSubscription = this.runMonitor().subscribe(
         () => {},
         (err) => console.log(err)
      );
   }

   detenerMonitor(): void {
      if (!this.monitorSubscription && !this.monitorSubscription.closed) {
         this.monitorSubscription.unsubscribe();
      }
   }

   private iniciarHeartbeat() {
      this.socket.emit(
         "join",
         `ipbx-${this.$header.getHeader("ipbxid")}.heartbeat`
      );
   }

   setRooms(rooms: string[]) {
      rooms.forEach((room) => {
         this.socket.emit(
            "join",
            `ipbx-${this.$header.getHeader("ipbxid")}.${room}`,
            (response: string) => {
               if (!!response) {
                  const data = JSON.parse(response) as { allowed: boolean };
                  if (data.allowed) {
                     this.roomsLogued = [...this.roomsLogued, room];
                  }
               }
            }
         );
      });
   }

   exitRooms(rooms: string[]) {
      rooms.forEach((room) => {
         this.socket.emit(
            "leave",
            `ipbx-${this.$header.getHeader("ipbxid")}.${room}`
         );
         this.roomsLogued = [...this.roomsLogued.filter((r) => r !== room)];
      });
   }

   getServerTime(): Observable<number> {
      return this.heartbeart$.pipe(shareReplay(1));
   }

   getCurrenRooms(): string[] {
      return [...this.roomsLogued];
   }

   enviarMensaje(mensaje: { fuente: string; mensaje: string }) {
      this.socket.emit("message", JSON.stringify(mensaje));
   }

   getStreamDBChanges(): Observable<String> {
      return this.dbChanges$.asObservable().pipe(share());
   }

   getChannels(): IChannel[] {
      return <IChannel[]>this.channels$.value;
   }

   getChannels$(): Observable<IChannel[]> {
      return this.channels$.pipe(shareReplay(1));
   }

   getPeers(): Observable<IPeerMonitor[]> {
      return this.peers$.asObservable().pipe(share());
   }

   getQueues(): Observable<IQueueMonitor[]> {
      return this.queues$.asObservable();
   }

   getQueuesState(): Observable<IQueueStateMonitor[]> {
      return this.queueState$.asObservable().pipe(share());
   }

   getAgents$(): Observable<IAgentState> {
      return this.agents$.asObservable().pipe(share());
   }

   getAgentState$(): Observable<IAgentStateMonitor[]> {
      return this.agentState$.pipe(
         filter((states) => !!states),
         shareReplay(1)
      );
   }

   getDialerStats$(): Observable<ICampanaStats[]> {
      return of([]);
   }

   getDialerProgress(
      initState: IContactoReparto[]
   ): Observable<IContactoReparto[]> {
      this.reparto$.next(initState);
      return this.reparto$.asObservable();
   }

   getQueueAlerts(): Observable<IQueueAlert> {
      return this.queueAlerts$.asObservable();
   }

   getQueueCallbacks(): Observable<Object> {
      return this.queueCallback$.asObservable();
   }

   getSysInfo(): Observable<Object> {
      return this.sysInfo$.asObservable();
   }

   getMensajes(
      idAgente: string
   ): Observable<{ fuente: string; mensaje: string }> {
      return this.messages$.asObservable().pipe(
         share(),
         filter((mensaje) => mensaje.mensaje.indexOf("@" + idAgente + " ") > -1)
      );
   }

   /**
    * @param rooms Colas de llamada de las cuales es manager el usuario
    * @returns Observable { tipo: ETipoMonitor, data: Object}
    */
   private runMonitor(): Observable<any> {
      return new Observable((o: Observer<any>) => {
         try {
            this.socket.on("heartbeat", (time: number) => {
               this.heartbeart$.next(time);
            });

            this.socket.on("db_changes", (jsonDBtables) => {
               const tablas: string[] = JSON.parse(jsonDBtables);
               tablas.forEach((tabla) => this.dbChanges$.next(tabla));
            });

            this.socket.on("channels", (jsonChannels) => {
               this.channels$.next(JSON.parse(jsonChannels));
            });

            this.socket.on("queueAlert", (jsonAlertas) => {
               this.queueAlerts$.next(JSON.parse(jsonAlertas));
            });

            this.socket.on("queueCallback", (jsonCallback) => {
               this.queueCallback$.next(JSON.parse(jsonCallback));
            });

            this.socket.on("peers", (jsonPeers) => {
               const peersState = JSON.parse(jsonPeers);
               if (!isEqual(this.peers$.value, peersState)) {
                  this.peers$.next(peersState);
               }
            });

            this.socket.on("sysinfo", (jsonSysInfo) => {
               this.sysInfo$.next(JSON.parse(jsonSysInfo));
            });

            /**
             * @deprecated
             */
            this.socket.on("queues", (jsonQueues) => {
               let queues: IQueueMonitor[] = JSON.parse(jsonQueues);
               queues = sortBy(queues, ["nombre"]);
               // this.queues$.next(queues);
            });

            /**
             * Nueva implementación del estado de las colas.
             */
            this.socket.on("queues.v2", (jsonQueues) => {
               const queuesEvent: {
                  queue: string;
                  state: { agents: string; callers: string };
                  agents: IAgentStateMonitor[];
               }[] = JSON.parse(jsonQueues);
               const rawQueueState = queuesEvent.pop();

               const queueStateMonitor: IQueueState = {
                  agents: JSON.parse(rawQueueState.state.agents),
                  callers: JSON.parse(rawQueueState.state.callers),
               };

               const currenState = [...this.queueState$.value];
               this.queueState$.next([
                  ...currenState.filter((q) => q.queue !== rawQueueState.queue),
                  {
                     ...rawQueueState,
                     state: queueStateMonitor,
                  },
               ]);
            });

            this.socket.on("queues.v2.stats", (stringQueueStats) => {
               const incomingQueueStats = JSON.parse(
                  stringQueueStats
               ) as IQueueStats;
               const actualStats = [...this.queueStats$.value];
               this.queueStats$.next([
                  ...actualStats.filter(
                     (s) => s.queue !== incomingQueueStats.queue
                  ),
                  incomingQueueStats,
               ]);
            });

            /**
             * @deprecated
             */
            this.socket.on("agents", (jsonAgents) => {
               const agents: IAgentState[] = JSON.parse(jsonAgents);
               this.agents$.next(agents.shift());
            });

            /**
             * Nueva implementación de stats
             */
            this.socket.on("agents.stats", (agentStateString) => {
               const agentState = JSON.parse(
                  agentStateString
               ) as IAgentStateMonitor[];

               if (agentState.length === 1) {
                  // Solo es un estado
                  const receivedState = agentState.shift();
                  const actualState = this.agentState$.value || [];
                  const newState = [
                     ...actualState.filter(
                        (a) => a.queuename !== receivedState.queuename
                     ),
                     receivedState,
                  ];
                  this.agentState$.next(newState);
               } else {
                  // Son todos los estados.
                  this.agentState$.next(agentState);
               }
            });

            this.socket.on("message", (jsonMessage) => {
               this.messages$.next(JSON.parse(jsonMessage));
            });

            this.socket.on("dialer", (jsonMessage) => {
               this.dialer$.next(JSON.parse(jsonMessage));
            });

            this.socket.on("reparto", (jsonMessage) => {
               const { newItem } = JSON.parse(jsonMessage) as {
                  newItem: IContactoReparto;
               };
               if (!!newItem) {
                  const repartos = [
                     ...this.reparto$.value.filter((r) => r.id !== newItem.id),
                     newItem,
                  ];
                  this.reparto$.next(repartos);
               }
            });

            this.socket.on("connect", () => {
               // Clear rooms
               const rooms = [...this.roomsLogued];
               this.roomsLogued = [];
               this.setRooms(rooms);

               // Init heartbeat
               this.iniciarHeartbeat();
            });
         } catch (e) {
            o.error(e);
         }

         return () => {
            this.socket.disconnect();
         };
      });
   }

   /**
    * Función para enviar comandos
    * @param comando IComando
    */
   enviarComando(
      comando: IComando
   ): Observable<{ tipo: EResponse; data: Object }> {
      return new Observable(
         (o: Observer<{ tipo: EResponse; data: Object }>) => {
            const cmdSocket = io(
               `${
                  SOCKET_SERVER || ""
               }/kerberusipbx?token=${this.$header.getHeader(
                  "Authorization"
               )}&ipbxid=${this.$header.getHeader("ipbxid")}`
            );

            cmdSocket.on("cmd-res", (jsonCMDResponse) => {
               const response: { tipo: EResponse; msj: Object } =
                  JSON.parse(jsonCMDResponse);
               if (response.tipo === EResponse.FALLIDO) {
                  o.error({ msj: response.msj });
               } else {
                  o.next({ tipo: response.tipo, data: response.msj });
               }
               o.complete();
            });

            cmdSocket.on("connect", () => {
               comando.comando = EComando[comando.comando];
               comando.socketID = "kerberusipbx#" + cmdSocket.id;
               cmdSocket.emit("cmd-in", JSON.stringify(comando));
            });

            return () => {
               if (!cmdSocket.disconnected) {
                  cmdSocket.disconnect();
               }
            };
         }
      );
   }
}

export enum EResponse {
   EXITOSO,
   FALLIDO,
   TIME_OUT,
}

export interface IComando {
   comando: string | EComando;
   data: Object;
   socketID?: string;
}

export enum EComando {
   PING,
   XFER,
   RESET_STATS,
   AGENT_INFO,
   AGENT_LOGOUT,
   AGENT_PAUSE,
   QUEUE_RELOAD,
   QUEUE_PARAMETERS,
   HANGUP_CALL,
   AUDIO_XFORM,
   AUDIO_FORMAT,
   KERBERUS_FILE,
   KERBERUS_OPS,
   KERBERUS_POWER,
   MAKE_CALL,
   BACK_UP_CONF,
   SQL_MTT,
   KERBERUS_NETS,
   FIREWALL,
   KERBERUS_ROUTES,
   SYS_INFO,
   SET_DATE,
   MAKE_OUTBOUND_CALL,
   // Auxiliar
   REMOVE_QUEUE_MEMBER,
   CHANGE_AGENT_MODE,
   MAKE_OUTBOUND,
   MAKE_OUTBOUND_CONNECT,
   MAKE_OUTBOUND_DIRECT,
   MAKE_OUTBOUND_DIALER,
   MAKE_OUTBOUND_AGENDA,
   SET_CHANNEL_VAR,
}
