export class Queue {
    nombre: string;
    notificacion = false;
    callback = false;
    peso = 0;
    wrapuptime = 0;
    holdtimeout = -1;

    estrategia = 'rrmemory';
    timeout = '16';
    modelo: string;
    enEspera = 'default';
    servicelevel = 60;
    poll? = '';
    directorio? = '';
    directo ? = '';
    useauth = false;
    //
    agentes: string | {idagente: string}[] = [];
    
    constructor() {} 
}
