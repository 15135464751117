import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PauseReasonService } from 'src/app/_services/pauseReasons.service';
import { PauseReasonsComponent } from './pause-reasons/pause-reasons.component';
import { AgentDetailsComponent } from './agent-details/agent-details.component';
import { AgenteEdicionComponent } from './agente.component';
import { AgenteDetalleComponent } from './agentedetalles.component';
import { UIModule } from 'src/app/_extra/ui/ui.module';

@NgModule({
    providers: [ PauseReasonService ],
    declarations: [PauseReasonsComponent, AgentDetailsComponent, AgenteEdicionComponent, AgenteDetalleComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UIModule
    ],
    exports: [ AgenteEdicionComponent ]
})
export class AgenteModule { }
