<form autocomplete="off" novalidate class="form-group" style="margin-bottom: 0" #f="ngForm">
      <div class="row">
          <div class="col-sm-4" style="margin-bottom: 15px">
              <div class="input-group" ngModelGroup="fecha">
                  <div class="input-group-btn">
                    <button type="button" class="btn btn-success" style="min-width: 100px">Fecha</button>
                  </div>
                  <input type="text" ngDatePicker ngModel name="inicio" required>
                  <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                  <input type="text" ngDatePicker ngModel name="fin" required>
              </div>
          </div>
          <div class="col-sm-4" style="margin-bottom: 15px">
              <div class="input-group" ngModelGroup="fuente">
                  <div class="input-group-btn">
                    <button type="button" class="btn btn-success" style="min-width: 100px">Fuente</button>
                  </div>
                  <select class="form-control" [(ngModel)]="queryParams.fuente" name="operador" style="min-width: 120px">   
                      <option value="{{o[o[indexOp]]}}" *ngFor="let indexOp of [0,1,2,3]">{{o[indexOp] | stringReplace:'_':' '}}</option>
                  </select>
                  <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                  <input type="text" ngModel name="valor" class="form-control" placeholder="3303030...">
              </div>
          </div>
          <div class="col-sm-4" style="margin-bottom: 15px">
            <div class="input-group"  ngModelGroup="destino">
                <div class="input-group-btn">
                    <button type="button" class="btn btn-success" style="min-width: 100px">Destino</button>
                </div>
                <select class="form-control" [(ngModel)]="queryParams.destino" name="operador" style="min-width: 120px">
                      <option value="{{o[o[indexOp]]}}" *ngFor="let indexOp of [0,1,2,3]">{{o[indexOp] | stringReplace:'_':' '}}</option>
                </select>
                <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                <input type="text" ngModel name="valor" class="form-control" placeholder="3303030...">
              </div>
          </div>
          
          <div class="col-sm-4" style="margin-bottom: 15px">
            <div class="input-group">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-success" style="min-width: 100px">Cola</button>
                </div>
                <span class="input-group-addon"><i class="fa fa-dashboard"></i></span>
                <select class="form-control" ngModel name="cola" *ngIf="autosearch_colas$ | async | queuesAllowed : usuario as autosearch_colas">
                    <option *ngFor="let nombreCola of autosearch_colas" [value]="nombreCola">{{nombreCola}}</option>
                    <option value="" *ngIf="isPrivilged">Todas las colas</option>
                </select>
             </div>
          </div>
          <div class="col-sm-4" style="margin-bottom: 15px">
              <div class="input-group">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-success" style="min-width: 100px">Agente</button>
                </div>
                <span class="input-group-addon"><i class="fa fa-headphones"></i></span>
                <input type="text" ngModel name="idagente" class="form-control" placeholder="Número de Agente">
              </div>
          </div>
          <div class="col-sm-4" style="margin-bottom: 15px">
              <div class="input-group">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-success" style="min-width: 100px">Modalidad</button>
                </div>              
                <select class="form-control" #modoAgente [(ngModel)]="queryParams.modoAgente" name="modoAgente" style="min-width: 120px">
                    <option value="{{m.TODAS}}">{{m[m.TODAS]}}</option>
                    <option value="{{m.ENTRANTES}}">{{m[m.ENTRANTES]}}</option>
                    <option value="{{m.AGENDADAS}}">{{m[m.AGENDADAS]}}</option>
                    <option value="{{m.CLICK2CALL}}">{{m[m.CLICK2CALL]}}</option>
                    <option value="{{m.DIALER_PREDICTIVO}}">{{m[m.DIALER_PREDICTIVO]}}</option>
                    <option value="{{m.DIALER_REPARTO}}">{{m[m.DIALER_REPARTO] | stringReplace:'_':' '}}</option>
                    <option value="{{m.SALIENTES_MANUAL}}">{{m[m.SALIENTES_MANUAL] | stringReplace:'_':' '}}</option>
                </select>
              </div>
          </div>
          <div class="col-sm-4" style="margin-bottom: 15px">
              <div class="input-group">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-success" style="min-width: 100px">Disposición</button>
                </div>              
                <select class="form-control" ngModel name="disposicion" style="min-width: 120px">
                    <option value="{{q.TODAS}}">{{q[q.TODAS]}}</option>
                    <option value="{{q.CONTESTADA}}">{{q[q.CONTESTADA]}}</option>
                    <option value="{{q.ABANDONADA}}">{{q[q.ABANDONADA]}}</option>
                    <option value="{{q.TIEMPO_EXPIRADO}}">{{q[q.TIEMPO_EXPIRADO] | stringReplace:'_':' '}}</option>
                    <option value="{{q.TIMBRE_NO_CONTESTA}}">{{q[q.TIMBRE_NO_CONTESTA] | stringReplace:'_':' '}}</option>
                    <option value="{{q.COLGADA_POR_AGENTE}}">{{q[q.COLGADA_POR_AGENTE] | stringReplace:'_':' '}}</option>
                    <option value="{{q.COLGADA_POR_USUARIO}}">{{q[q.COLGADA_POR_USUARIO] | stringReplace:'_':' '}}</option>
                </select>
              </div>
          </div>          
          <div class="col-sm-8 text-right" style="margin-bottom: 15px">
              <!--<button class="btn btn-default" type="button">Limpiar</button>-->
              <!--<button class="btn btn-primary" type="button" (click)="ejecutarBusqueda(f.value, _q.LIST)">Ejecutar Búsqueda</button>-->
              <button class="btn btn-primary" type="button" (click)="ejecutarBusquedaLog(f.value)">Ejecutar Búsqueda</button>
          </div>
      </div>
</form>

<ng-container *ngIf="agentes$ | async as agenteActuales">
    <div class="table-responsive">
        <table class="table table-striped" style="margin-bottom: 0; font-size: 12px; border: 1px solid #e2e2e2; min-width: 1100px">
            <thead style="background-color: #0d1e40; color: white">
                <tr>
                    <td class="text-center">ID Llamada</td>
                    <td class="text-center">ID Interacción</td>
                    <td class="text-center">
                        <a href="javascript:void(0)" (click)="sortBy('calldate')"> 
                            <i class="fa fa-sort-alpha-asc"></i>
                        </a> Fecha
                    </td>
                    <td><a href="javascript:void(0)" (click)="sortBy('src')"> 
                            <i class="fa fa-sort-alpha-asc"></i>
                        </a> Evento</td>            
                    <td><a href="javascript:void(0)" (click)="sortBy('dst')"> 
                            <i class="fa fa-sort-alpha-asc"></i>
                        </a> Modo de llamada</td>
                    <td><a href="javascript:void(0)" (click)="sortBy('agent')"> 
                            <i class="fa fa-sort-alpha-asc"></i>
                        </a> Cola</td>
                    <td><a href="javascript:void(0)" (click)="sortBy('queuename')"> 
                            <i class="fa fa-sort-alpha-asc"></i>
                        </a> Fuente</td>
                    <td><a href="javascript:void(0)" (click)="sortBy('event')"> 
                            <i class="fa fa-sort-alpha-asc"></i>
                        </a> Destino</td>            
                    <td class="text-center th"><a href="javascript:void(0)" (click)="sortBy('data2')"> 
                            <i class="fa fa-sort-alpha-asc"></i>
                        </a> Agente</td>
                    <td class="text-center"><a href="javascript:void(0)" (click)="sortBy('data2')"> 
                            <i class="fa fa-sort-alpha-asc"></i>
                        </a> Duración</td>
                    <td class="text-center"><a href="javascript:void(0)" (click)="sortBy('data1')"> 
                            <i class="fa fa-sort-alpha-asc"></i>
                        </a> T. Espera</td>
                    <td class="text-center">AUDIO</td>
                </tr>
            </thead>
            <tbody *ngIf="llamadas && llamadas.length > 0">
                <ng-container *ngFor="let registro of llamadas | paginate: config; let i = index">
                    <ng-container *ngFor="let llamadaItem of registro.data">
                        <tr>
                            <td [attr.rowspan]="(registro.cantidad + 1)">{{registro.masterid}}</td>
                            <td [attr.rowspan]="(llamadaItem.cantidad +1)">{{llamadaItem.callid}}</td>
                        </tr>
                        <tr *ngFor="let evento of (llamadaItem.data | sortByField : 'time')">
                            <td class="text-center">
                                <ng-container *ngIf="evento.event.includes('COMPLETE'); else noCompleteEvent">
                                    <!-- Calcular el tiempo en el que realmente entro la llamada al agente -->
                                    {{evento.time | toTimeFormat:'YYYY-MM-DD HH:mm:ss': ( -1 * evento.data2 )}}
                                </ng-container>
                                <ng-template #noCompleteEvent>
                                    {{evento.time | toTimeFormat:'YYYY-MM-DD HH:mm:ss'}}
                                </ng-template>
                            </td>
                            <td>{{evento.event}}</td>
                            <ng-container [ngSwitch]="evento.event">
                                <ng-container *ngSwitchCase="'QUEUE_CONNECT'"> 
                                    <td>{{evento.data5}}</td>       <!--tipo-->
                                    <td>{{evento.queuename}}</td>   <!--tipo-->
                                    <td>{{evento.data2}}</td>       <!--Fuente-->
                                    <td>{{evento.data3}}</td>       <!--Destino-->
                                    <td colspan="4"></td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'BLINDTRANSFER'"> 
												<td colspan="3"></td>
												<!-- Destino de la transferida -->
                                    <td class="text-left">{{evento.data1}}</td>
                                    <td class="text-left">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">-</td> <!--t.duracion-->
                                    <td class="text-center">-</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'ATTENDEDTRANSFER'"> 
												<td colspan="3"></td>
												<!-- Destino de la transferida -->
												<td class="text-left">{{evento.data1}}</td>
                                    <td class="text-left">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">-</td> <!--t.duracion-->
                                    <td class="text-center">-</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'COMPLETECALLER'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-left">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">{{evento.data2 | toTime | date:'HH:mm:ss'}}</td> <!--t.duracion-->
                                    <td class="text-center">{{evento.data1 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center">
                                        <button class="btn btn-xs btn-info"
                                        (click)="escucharLlamada(evento)"><i class="fa fa-music"></i></button>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'COMPLETEAGENT'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-left">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">{{evento.data2 | toTime | date:'HH:mm:ss'}}</td> <!--t.duracion-->
                                    <td class="text-center">{{evento.data1 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center">
                                        <button class="btn btn-xs btn-info"
                                        (click)="escucharLlamada(evento)"><i class="fa fa-music"></i></button>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'NOANSWER'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-center">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">{{evento.data2 | toTime | date:'HH:mm:ss'}}</td> <!--t.duracion-->
                                    <td class="text-center">{{evento.data1 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center">
                                        <button class="btn btn-xs btn-info"
                                        (click)="escucharLlamada(evento)"><i class="fa fa-music"></i></button>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'RINGNOANSWER'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-center">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">-</td> <!--t.duracion-->
                                    <td class="text-center">{{(evento.data1 / 1000) | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'AGENT_INVALID'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-center">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">-</td> <!--t.duracion-->
                                    <td class="text-center">{{evento.data1 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'AGENT_NOT_LOGGED_IN'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-center">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">-</td> <!--t.duracion-->
                                    <td class="text-center">{{evento.data1 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'AGENT_NOT_CONNECTED'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-center">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">-</td> <!--t.duracion-->
                                    <td class="text-center">{{evento.data1 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'AGENT_BUSY'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-center">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">-</td> <!--t.duracion-->
                                    <td class="text-center">{{evento.data1 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'AGENT_ERROR'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-center">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">-</td> <!--t.duracion-->
                                    <td class="text-center">{{evento.data1 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'BUSY'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-center">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">-</td> <!--t.duracion-->
                                    <td class="text-center">{{evento.data1 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'ERROR'"> 
                                    <td colspan="2"></td>
                                    <td colspan="2" class="text-right"></td>
                                    <td class="text-center">{{evento.agent}} - {{evento.agent | serializeAgent: agenteActuales}}</td>
                                    <td class="text-center">-</td> <!--t.duracion-->
                                    <td class="text-center">{{evento.data1 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'ABANDON'"> 
                                    <td colspan="5"></td>
                                    <td class="text-center">00:00:00</td>
                                    <td class="text-center">{{evento.data3 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'USERCALLBACK'"> 
                                    <td colspan="5"></td>
                                    <td class="text-center">00:00:00</td>
                                    <td class="text-center">{{evento.data3 | toTime | date:'HH:mm:ss'}}</td> <!--t.espera-->
                                    <td class="text-center text-muted">sin-audio</td>
                                </ng-container>
                                <ng-container *ngSwitchDefult> 

                                </ng-container>
                            </ng-container>
                        </tr>
                    </ng-container>
                </ng-container>
                <tr>
                    <td colspan="12" class="text-right">
                        <pagination-template #p="paginationApi"
                            [id]="config.id"
                            (pageChange)="config.currentPage = $event">

                            <div class="btn-group">
                                <button type="button" class="btn btn-default" 
                                    [disabled]="p.isFirstPage()"
                                    (click)="p.previous()">
                                    <i class="fa fa-arrow-circle-o-left"></i>
                                </button>

                                <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                                    [disabled]="p.getCurrent() === page.value"
                                    [ngClass]="{'active': p.getCurrent() === page.value}"
                                    (click)="p.setCurrent(page.value)">
                                    {{ page.label }}
                                </button>
                                
                                <button type="button" class="btn btn-default" 
                                    [disabled]="p.isLastPage()"
                                    (click)="p.next()">
                                    <i class="fa fa-arrow-circle-o-right"></i>
                                </button>
                            </div>
                        </pagination-template>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!llamadas || llamadas.length === 0">
                <tr>
                    <td colspan="12">
                        <h5 class="text-muted">No existen registros...</h5>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <hr>
    <div class="panel panel-primary">
    <div class="panel-heading">
        <strong><i class="fa fa-bar-chart"></i>  Estadísticas de la Consulta</strong>
    </div>
    <div class="panel-body">
        <div class="row" *ngIf="estadisticasDeLlamada$ | async as stats">
            <div class="col-md-3">
                <ul class="list-group">
                    <li class="list-group-item">Llamadas únicas <span class="badge">{{llamadas?.length || 0}}</span></li>
                    <li class="list-group-item">Llamadas atendidas <span class="badge">
                        {{llamadas.length === 0 ? 0 : stats | cantCalc : ['COMPLETECALLER', 'COMPLETEAGENT']}}
                    </span></li>
                    <li class="list-group-item">Llamadas transferidas <span class="badge">
                        {{llamadas.length === 0 ? 0 : stats | cantCalc : ['ATTENDEDTRANSFER', 'BLINDTRANSFER']}}
                    </span></li>
                    <li class="list-group-item">Intentos fallidos a agente <span class="badge">
                        {{llamadas.length === 0 ? 0 : stats | cantCalc : ['RINGNOANSWER']}}
                    </span></li>
                    <li class="list-group-item">Llamadas abandonadas <span class="badge">
                        {{llamadas.length === 0 ? 0 : stats | cantCalc : ['ABANDON']}}
                    </span></li>
                </ul>
            </div>
            <div class="col-md-5">
                <ul class="list-group">
                    <li class="list-group-item">
                        Tiempo de conversación <span class="badge">
                            {{
                                (llamadas.length === 0 ? 0 : stats | tiemposStats : ['COMPLETECALLER', 'COMPLETEAGENT'] : 'totalConversacion') | 
                                toTime | date:'HH:mm:ss'
                            }}
                        </span>
                    </li>
                    <li class="list-group-item">
                        Tiempo promedio de llamada atendida (TPC)<span class="badge">
                            {{
                                (llamadas.length === 0 ? 0 : stats | tiemposStats : ['COMPLETECALLER', 'COMPLETEAGENT'] : 'promedio' : true) | 
                                toTime | date:'HH:mm:ss'
                            }}
                        </span>
                    </li>
                    <li class="list-group-item">
                        Tiempo de espera promedio (Atendidas) (TPE)<span class="badge">
                            {{
                                (llamadas.length === 0 ? 0 : stats | tiemposStats : ['COMPLETECALLER', 'COMPLETEAGENT'] : 'promedioEspera' : true) | 
                                toTime | date:'HH:mm:ss'
                            }}
                        </span>
                    </li>
                    <li class="list-group-item">
                        Tiempo promedio de llamada abandonada (TPA) <span class="badge">
                            {{
                                (llamadas.length === 0 ? 0 : stats | tiemposStats : ['ABANDON'] : 'promedioEspera') | 
                                toTime | date:'HH:mm:ss'
                            }}
                        </span>
                    </li>
                </ul>
            </div>
            <div class="col-md-4">
                Puede descargar la información en CSV para ser filtrada en MSExcel&trade;
                <hr>
                <div class="text-center">
                    <button type="button" 
                            (click)="descargarReporte(agenteActuales)"
                            [disabled]="!llamadas || llamadas?.length === 0" class="btn btn-warning">Descargar en *.CSV
                    </button>
                </div>
            </div>
        </div>
    </div>
    </div>
</ng-container>
<div class="modal fade" tabindex="-1" role="dialog" id="modalAudioQueue">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h5 style="font-weight: 500" class="modal-title"><i class="fa fa-music"></i> De {{llamada?.src}} <i class="fa fa-arrow-circle-o-right"></i> {{llamada?.dst}}</h5>
      </div>
      <div class="modal-body" *ngIf="llamada">
          <audio-player [src]="llamada.audio"></audio-player>
      </div>      
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->