import { NgModule } from '@angular/core';

import { TimePipe } from './pipes';
import { UploadProgessPipe } from './uploadprogess/uploadprogress.pipe';

@NgModule({
    imports: [],
    declarations: [TimePipe, UploadProgessPipe],
    exports: [TimePipe, UploadProgessPipe],
    providers: [],
})
export class PipesModule { }
