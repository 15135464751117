import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { ITipificacionLog } from 'src/app/_interfaces/_all';
import { PaginationInstance } from 'ngx-pagination';
import { map, toArray, tap } from 'rxjs/operators';
import { FileHandlerService } from 'src/app/_services/file.service';
import { from } from 'rxjs';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-tabla-tipificacion',
  templateUrl: './tabla-tipificacion.component.html',
  styleUrls: ['./tabla-tipificacion.component.css']
})
export class TablaTipificacionComponent implements OnInit {

    public config: PaginationInstance = {
        id: 'custom',
        itemsPerPage: 15,
        currentPage: 1
    };

    @Input() resultados: ITipificacionLog[];
    constructor(private $file: FileHandlerService, private $router: Router) { }

    ngOnInit() {

    }

    descargar(enunciados: string[], cabecerasContactos: string[]) {
        from(this.resultados).pipe(
            map(resultado => {
                const { preguntas, contacto, fecha, ...resto } = resultado;
                const preguntasOrdenadas = preguntas.reduce((acc, p) => ({...acc, [`Pregunta: ${p.enunciado}`]: p.respuesta }), {});

                const { _id, iddb, ...restoContacto } = contacto;
                const contactoOrdenado = Object.keys(restoContacto).reduce((acc, c) => ({ ...acc, [`Contacto: ${c}`]: restoContacto[c]}), {})
                return {
                    fecha: dayjs(fecha).format('YYYY-MM-DD'),
                    hora: dayjs(fecha).format('HH:mm:ss'),
                    ...resto,
                    ...preguntasOrdenadas,
                    ...contactoOrdenado
                }
            }),
            // Remoción de saltos de línea.
            map(resultado => JSON.stringify(resultado).replace(/(?:\r\n|\r|\n|\\r\\n|\\r|\\n)/g, "")),
            map(data => JSON.parse(data)),
            toArray()
        ).subscribe(
            resultados => {
                const cabeceras = [
                    'callid', 'fecha', 'hora', 'idautor', 'autor', 'numero', 'cola',
                    ...cabecerasContactos.map(c => `Contacto: ${c}`),
                    ...enunciados.map(e => `Pregunta: ${e}`)
                ];
                this.$file.exportCSVFile(cabeceras, resultados, `Kerberus tipificacion ${new Date().getTime()}`);
            }
        );
    }

    regresar() {
        this.$router.navigateByUrl('/dashboard/(view:call-typing)');
    }

}
