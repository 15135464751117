import { HttpEventType, HttpResponse } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
   FormGroup,
   FormBuilder,
   Validators,
   FormControl,
} from "@angular/forms";
import { UsuarioService } from "src/app/_services/usuario.service";
import { EUsuarioRol } from "src/app/_interfaces/usuario";
import { EComando, SocketService } from "src/app/_services/socket.service";
import { TroncalService } from "src/app/_services/troncal.service";
import { QueueService } from "src/app/_services/queue.service";
import { Queue, Troncal, IDBResponse } from "src/app/_interfaces/_all";
import { IDirectorio, ICampoDirectorio } from "src/app/_interfaces/directorio";
import { DirectorioService } from "src/app/_services/directorio.service";
import { ContactosService } from "src/app/_services/contactos.service";
import { IContacto } from "src/app/_interfaces/contacto";
import { DialerService } from "src/app/_services/dialer.service";
import {
   ETipoCampanaDialer,
   EEstadoCampanaDialer,
   ICampanaDialer,
} from "src/app/_interfaces/dialer";
import { FileHandlerService } from "src/app/_services/file.service";
import { Observable, of, zip } from "rxjs";
import {
   defaultIfEmpty,
   map,
   share,
   filter,
   tap,
   flatMap,
   debounceTime,
   switchMap,
   concat,
   finalize,
   toArray,
   delay,
} from "rxjs/operators";

@Component({
   selector: "app-dialer-setup",
   templateUrl: "./dialer-setup.component.html",
   styleUrls: ["./dialer-setup.component.css"],
})
export class DialerSetupComponent implements OnInit {
   readonly UPLOAD_URL = "ivr/audio";
   @ViewChild("fileUpload")
   fileUpload: ElementRef;

   inProgress: {
      progress: boolean;
      message?: string;
      errorMessage?: string;
      action?: string;
   } = {
      progress: false,
      action: "",
      message: "",
      errorMessage: "",
   };

   formDialer: FormGroup;
   campana$: Observable<any>;
   troncales$: Observable<any>;
   directorios$: Observable<IDirectorio[]>;
   contactos$: Observable<{
      campos: ICampoDirectorio[];
      contactos: IContacto[];
   }>;
   queues$: Observable<Queue[]>;
   agentes$: Observable<{ idagente: string }[]>;

   get ruta() {
      return this.formDialer.get("ruta");
   }
   get basico() {
      return this.formDialer.get("basico");
   }

   get cola() {
      return this.basico.get("cola");
   }
   get tipo() {
      return this.basico.get("tipo");
   }
   get nombre() {
      return this.basico.get("nombre");
   }
   get reparto() {
      return this.formDialer.get("reparto");
   }
   get directorio() {
      return this.basico.get("directorio");
   }
   get tiempoIntento() {
      return this.formDialer.get("tiempoIntento");
   }
   get isPrivileged() {
      return this.$usr.usuario.permisos <= EUsuarioRol.MANAGER;
   }
   get canActivateReparto() {
      return (
         this.basico.get("directorio").valid && this.basico.get("cola").valid
      );
   }

   constructor(
      private $router: Router,
      private $fb: FormBuilder,
      private $usr: UsuarioService,
      private $route: ActivatedRoute,
      private $troncal: TroncalService,
      private $queue: QueueService,
      private $directorio: DirectorioService,
      private $contacto: ContactosService,
      private $dialer: DialerService,
      private $file: FileHandlerService,
      private $monitor: SocketService
   ) {}

   ngOnInit() {
      const { idcampana } = this.$route.snapshot.params;
      this.queues$ = this.$queue.getQueues().pipe(share());
      this.directorios$ = this.$directorio.getDirectorios().pipe(share());
      this.troncales$ = this.$troncal
         .getTroncales()
         .pipe(
            map((troncales) =>
               troncales.map((t) => ({
                  nombre: t.nombre,
                  protocolo: t.protocolo,
               }))
            )
         );
      this.campana$ = this.$dialer.getCampana$(idcampana).pipe(
         filter((campana) => !!campana),
         defaultIfEmpty({
            idcampana: idcampana,
            nombre: "",
            tipo: ETipoCampanaDialer.PROGRESIVO,
            estado: EEstadoCampanaDialer.DETENIDA,
            directorio: "",
            cola: "",
            troncal: "",
            prefijo: "",
            timeout: 18,
            amd: false,
            intentos: 0,
            tiempoIntento: 10,
            tasa: 0,
            reparto: [],
         } as ICampanaDialer),
         tap((campana) => this.initFrm(campana, !!campana.nombre))
      );
   }

   initFrm(dialer?: ICampanaDialer, readOnly = false) {
      this.formDialer = this.$fb.group({
         idcampana: ["", Validators.required],
         estado: "",
         basico: this.$fb.group({
            nombre: ["", Validators.required],
            tipo: ["", Validators.required],
            directorio: [
               { value: "", disabled: readOnly },
               Validators.required,
            ],
            cola: ["", Validators.required],
         }),
         ruta: this.$fb.group({
            troncal: ["", Validators.required],
            prefijo: "",
            timeout: [
               "",
               [Validators.required, Validators.min(10), Validators.max(60)],
            ],
         }),
         amd: "",
         intentos: ["", Validators.required],
         tiempoIntento: ["", Validators.min(5)],
         tasa: ["", [Validators.required, Validators.min(0)]],
         reparto: [{ value: [], disabled: readOnly }],
      });

      if (!!dialer) {
         const {
            troncal,
            prefijo,
            timeout,
            nombre,
            tipo,
            directorio,
            cola,
            ...rest
         } = dialer;
         this.formDialer.patchValue({ ...rest });
         this.basico.patchValue({ nombre, tipo, directorio, cola });
         this.ruta.patchValue({ troncal, prefijo, timeout });
      }
   }

   vistaAddressBook() {
      $('#tabDialer a[href="#addressbook"]').tab("show");
   }

   vistaEnrutamiento() {
      $('#tabDialer a[href="#outgoing"]').tab("show");
   }

   setAgentes(queuename: string) {
      this.agentes$ = of(queuename).pipe(
         debounceTime(300),
         filter((queue) => !!queue),
         switchMap((queue) => this.$queue.getQueue(queue)),
         map((queue) => queue.agentes as { idagente: string }[]),
         defaultIfEmpty([])
      );
   }

   setContactos(iddb: string) {
      this.contactos$ = of(iddb).pipe(
         debounceTime(300),
         filter((id) => !!id),
         flatMap((idDirectorio) =>
            this.$directorio.getDirectorio(idDirectorio).pipe(
               map((directorio) => directorio.campos),
               flatMap((campos) =>
                  zip(of(campos), this.$contacto.getContactos(idDirectorio))
               ),
               map(([campos, contactos]) => ({
                  campos: campos,
                  contactos: contactos,
               }))
            )
         )
      );
   }

   seleccionarArchivo() {
      const uploader = this.fileUpload.nativeElement;
      uploader.click();
   }

   private startUpload(filename: string): Observable<any> {
      const nativeElementFiles = this.fileUpload.nativeElement.files;
      const file =
         nativeElementFiles.length > 0 ? nativeElementFiles[0] : undefined;

      console.log(this.fileUpload.nativeElement.files);

      if (!!file) {
         const form = new FormData();
         form.append("audio_attach", file);

         return of(form).pipe(
            switchMap((form) => this.$file.upload(this.UPLOAD_URL, form)),
            filter((event) => event.type === HttpEventType.Response),
            map((response: HttpResponse<IDBResponse>) => response.body),
            flatMap(({ data }) =>
               this.$monitor.enviarComando({
                  comando: EComando.AUDIO_FORMAT,
                  data: { in: data, out: `ivr/${filename}_dialer.wav` },
               })
            )
         );
      }

      return of(true);
   }

   guardarCampana(campanaFrm: any, nueva = false) {
      const { basico, ruta, ...rest } = campanaFrm;
      const campana = {
         ...rest,
         ...basico,
         ...ruta,
         prefijo: ruta.prefijo || undefined,
      };
      console.log(campana);
      of(campana)
         .pipe(
            debounceTime(200),
            tap(() => (this.inProgress = { progress: true })),
            // guardar la campaña y subir audio en caso de que exista.
            flatMap((campana) =>
               this.$dialer
                  .guardarCampana$(campana, nueva)
                  .pipe(concat(this.startUpload(campana.idcampana)))
            ),
            toArray(),
            tap(() => {
               this.inProgress = {
                  progress: false,
                  message: "Campaña guardarda exitosamente.",
               };
            }),
            delay(2100)
         )
         .subscribe(
            (response) => {
               console.log(response);
               this.$router.navigateByUrl("dashboard/(view:dialer)");
            },
            (error) => {
               console.error(error);
               this.inProgress = {
                  progress: false,
                  errorMessage:
                     "Hubo un error al tratar de guardar esta campaña.",
               };
            }
         );
   }

   eliminarCampana(campana: ICampanaDialer) {
      of(campana.idcampana)
         .pipe(
            debounceTime(200),
            tap(() => (this.inProgress = { progress: true, action: "delete" })),
            flatMap((idcampana) => this.$dialer.eliminarCampana(idcampana)),
            tap(() => {
               this.inProgress = {
                  progress: false,
                  message: "Campaña eliminada exitosamente.",
               };
            }),
            delay(2100)
         )
         .subscribe(
            (response) => {
               console.log(response);
               this.$router.navigateByUrl("dashboard/(view:dialer)");
            },
            (error) => {
               console.error(error);
               this.inProgress = {
                  progress: false,
                  errorMessage:
                     "Hubo un error al tratar de guardar esta campaña.",
               };
            }
         );
   }

   cancelar() {
      this.$router.navigateByUrl("/dashboard/(view:dialer)");
   }
}
