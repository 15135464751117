
<div class="row" *ngIf="queue$ | async as queue; else loading">
    <div class="col-md-3">
        <div class="bs-callout bs-callout-primary">
            <h4 class="text-primary"><i class="fa fa-wrench"></i> {{queue.nombre || 'Nueva cola de llamadas'}}.</h4>
            Configuración de algoritmos de asignación de llamadas, agentes, audios e integraciones.
        </div>

        <ul class="nav nav-pills nav-stacked">
            <li role="presentation" class="active">
                <a href="#basic" aria-controls="basic" role="tab" data-toggle="tab">Básico</a></li>
            <li role="presentation">
                <a href="#agents" aria-controls="agents" role="tab" data-toggle="tab">Agentes</a></li>
            <li role="presentation">
                <a href="#audio" aria-controls="audio" role="tab" data-toggle="tab">Audios</a></li>
            <li role="presentation">
                <a href="#hook" aria-controls="hook" role="tab" data-toggle="tab">Integraciones</a></li>
            <li role="presentation">
                <a href="#advanced" aria-controls="advanced" role="tab" data-toggle="tab">Avanzado</a></li>
        </ul>
    </div>
    <div class="col-lg-offset-1 col-lg-7 col-md-9">
        <form novalidate class="form-group" #f="ngForm" style="margin-bottom: 0">
        <div class="tab-content" style="padding-top: 20px; color: #585858;">
            <div role="tabpanel" class="tab-pane fade in active" id="basic">
                <h4><i class="fa fa-check-square-o"></i> Configuraciones básicas.</h4>
                <hr>
                <div class="alert alert-success" *ngIf="!queue.nombre">
                    <strong><i class="fa fa-info-circle"></i> Que necesita para crear una nueva cola?</strong>
                    <small>
                        <div style="margin-top: 5px;">
                            (1). Asignar un nombre de la cola o campaña. (2). Seleccionar una Estrategia de asignación de llamadas y (3). 
                            Agregar agentes que puedan atender o realizar llamadas. Las demas configuraciones se estableceran por defecto.
                        </div>
                    </small>
                </div>
                <div class="form-group">
                    <label>Nombre de la cola o campaña.</label><br>
                    <div class="input-group col-md-6" style="margin-bottom: 5px;">
                        <input [ngModel]="queue.nombre" #queuename="ngModel" [readonly]="readOnly"
                        name="nombre" type="text" class="form-control" placeholder="Nombre sin espacios" queuenameValidator required>
                        <span class="input-group-addon"><i class="fa fa-map-signs"></i></span>
                    </div>
                    <small *ngIf="queuename.errors?.invalidName && (queuename.dirty || queuename.touched); else defaultMsg" class="text-danger">
                        * El nombre no puede contener caracteres especiales ni espacios.
                    </small>
                    <ng-template #defaultMsg>
                        <small>Debe ser un nombre único que le permita identificar la cola o campaña de las demás.</small>
                    </ng-template>
                </div>
                <hr>
                <div class="form-group">
                    <label>Estrategia de asignación de llamadas.</label>
                    <div class="input-group col-md-6" style="margin-bottom: 5px;">
                        <select [ngModel]="queue.estrategia" name="estrategia"  class="form-control" required>
                            <option value="">Seleccione uno</option>
                            <option value="linear">En cascada</option>
                            <option value="random">Aleatorio</option>
                            <option value="rrmemory">Carrousel</option>
                            <option value="rrordered">Carrousel en orden</option>
                            <option value="ringall">Todos a la vez</option>
                        </select>
                    </div>
                    <small>Los algoritmos estan basados en asignaciones lógicas:
                        <ul>
                            <li><strong>En cascada:</strong> Asigna llamadas en cascada y es util en agentes logueados en modo RingBack, 
                            no es recomendado en agentes en modo Automático.</li>
                            <li><strong>Aleatorio:</strong> Asigna llamadas basado en disponiblidad y cálculo aleatorio.</li>
                            <li><strong>Carrousel:</strong> Asigna llamadas en donde los usuarios y los agentes se encuentran en carrouseles. (Recomendado)</li>
                            <li><strong>Carrousel en orden:</strong> Asigna llamadas en donde los usuarios y los agentes se encuentran en carrouseles pero tiene en cuenta el orden de logueo de agentes.</li>
                            <li><strong>Todos a la vez:</strong> Asigna llamadas en timbres simultáneos, no tiene efecto en agentes logueados en modo RingBack.</li>
                        </ul>
                    </small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Marcación directa.</label><br>
                    <div class="input-group col-md-6" style="margin-bottom: 5px;">
                        <input [ngModel]="queue.directo" name="directo" type="text" class="form-control" placeholder="Ej: *3000">
                        <span class="input-group-addon"><i class="fa fa-random"></i></span>
                    </div>
                    <small>Esta opción permite hacer marcaciones directas a la cola desde su PBX, útil para transferencias internas desde una extensión a una cola de llamadas.</small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Eliminar cola</label>
                    <br>
                    <small>
                        Si esta cola ya no es últil, puede eliminar, se conservaran los registros de llamadas existentes.
                        <div class="alert alert-warning">
                            <i class="fa fa-warning text-danger"></i> Esta operación no puede deshacerse.
                        </div>
                    </small>
                    <button type="button" style="margin-bottom: 7px;" [disabled]="!queue.nombre || !isPrivileged"
                    class="btn btn-default" (click)="eliminarCola(queue)"><i class="fa fa-trash-o"></i> Eliminar cola</button>
                </div>
            </div>
            <div role="tabpanel" class="tab-pane fade" id="agents">
                <h4><i class="fa fa-user-circle-o"></i> Agentes en la cola de llamada.</h4>
                <hr>
                <div class="form-group">
                    <label>Seleccione un agente.</label>
                    <div class="input-group" style="margin-bottom: 5px;">
                        <select #agenteSel class="form-control">
                            <option value="">...</option>
                            <option value="{{a.idagente}}" *ngFor="let a of (agentes$ | async)">{{a.idagente}} {{a.nombre}}</option>
                        </select>
                        <span class="input-group-btn">
                            <button class="btn btn-default" type="button" (click)="agregarAgente(agenteSel.value)"><i class="fa fa-plus"></i> Agregar</button>
                        </span>                
                    </div><!-- /input-group -->
                    <small>Puede agregar tantos agentes como requiera, recuerde que los agentes también pueden estar en múltiples colas</small>
                </div>
                <hr>
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <label>Agentes asignados <span class="badge">{{queue.agentes.length}}</span></label>
                    </div>
                    <div class="panel-body">
                    <div class="col-sm-4" style="padding: 5px" *ngFor="let a of agentesEdicion"> 
                        <div class="row panel" style="margin: 1px; background-color: #f1f1f1">
                            <div class="col-xs-9" style="padding: 8px">
                            <i class="fa fa-user-circle-o" style="margin-right: 6px"></i> Agente {{a.idagente}}
                            </div>
                            <div class="col-xs-3 text-right" style="padding: 5px">
                            <button class="btn btn-xs btn-info" (click)="removerAgente(a.idagente)">
                                <i class="fa fa-close"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
            <div role="tabpanel" class="tab-pane fade" id="audio">
                <h4><i class="fa fa-volume-up"></i> Audios específicos para esta cola.</h4>
                <div class="alert alert-danger" *ngIf="!queue.nombre">
                    <small><i class="fa fa-info-circle"></i> Podrá subir los audios, una vez haya creado la cola de llamadas.</small>
                </div>
                <hr>
                <!-- FileUpload -->
                <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="audio/*" style="display:none;" />
                <!-- FileUpload -->
                <div class="form-group">
                    <label>Audio de espera.</label>
                    <div class="input-group col-md-6" style="margin-bottom: 5px;">
                        <input type="text" class="form-control" aria-label="..." readonly>
                        <div class="input-group-btn">
                            <button class="btn btn-default" [disabled]="!readOnly || !isPrivileged || inProgress || !queue.nombre" (click)="startUpload('HOLD', queue.nombre)"><i class="fa fa-folder-open"></i></button>
                            <button class="btn btn-info" (click)="playAudio(queue.nombre, 'HOLD')"><i class="fa fa-play"></i></button>
                            <button class="btn btn-info" (click)="stopAudio()"><i class="fa fa-stop"></i></button>
                            <button class="btn btn-info" [disabled]="!isPrivileged || !queue.nombre" (click)="descargar('HOLD', queue.nombre)"><i class="fa fa-download"></i></button>
                        </div>
                    </div>
                    <div class="progress" *ngIf="uploadProgres$ | async | uploadProgress : 'HOLD' as upProgress">
                        <div class="progress-bar" 
                        [style.width]="upProgress.progress + '%'"
                        role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                          {{upProgress.progress}}%
                        </div>
                    </div>
                    <small>Audio que será reproducido en el usuario mientras espera ser atendido por un agente.
                        <div class="alert alert-info">
                            <i class="fa fa-info-circle"></i> Por defecto se reproducirá una música en espera estándard.
                        </div>
                    </small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Audio de anuncio entrante.</label>
                    <div class="input-group col-md-6" style="margin-bottom: 5px;">
                        <input type="text" class="form-control" aria-label="..." readonly>
                        <div class="input-group-btn">
                            <button class="btn btn-default" [disabled]="!readOnly || !isPrivileged || inProgress || !queue.nombre" (click)="startUpload('ANN', queue.nombre)"><i class="fa fa-folder-open"></i></button>
                            <button class="btn btn-info" (click)="playAudio(queue.nombre, 'ANN')"><i class="fa fa-play"></i></button>
                            <button class="btn btn-info" (click)="stopAudio()"><i class="fa fa-stop"></i></button>
                            <button class="btn btn-info" [disabled]="!isPrivileged || !queue.nombre" (click)="descargar('ANN', queue.nombre)"><i class="fa fa-download"></i></button>
                        </div>
                    </div>
                    <div class="progress" *ngIf="uploadProgres$ | async | uploadProgress : 'ANN' as upProgress">
                        <div class="progress-bar" 
                        [style.width]="upProgress.progress + '%'"
                        role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                          {{upProgress.progress}}%
                        </div>
                    </div>
                    <small>Audio que será reproducido en el agente indicandole que ha entrado una llamada; adicionalmente escuchará un beep.</small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Audio de Callback.</label>
                    <div class="input-group col-md-6" style="margin-bottom: 5px;">
                        <input type="text" class="form-control" aria-label="..." readonly>
                        <div class="input-group-btn">
                            <button class="btn btn-default" [disabled]="!readOnly || !isPrivileged || inProgress || !queue.nombre" (click)="startUpload('CALLBACK', queue.nombre)"><i class="fa fa-folder-open"></i></button>
                            <button class="btn btn-info" (click)="playAudio(queue.nombre, 'CALLBACK')"><i class="fa fa-play"></i></button>
                            <button class="btn btn-info" (click)="stopAudio()"><i class="fa fa-stop"></i></button>
                            <button class="btn btn-info" [disabled]="!isPrivileged || !queue.nombre" (click)="descargar('CALLBACK', queue.nombre)"><i class="fa fa-download"></i></button>
                        </div>
                    </div>
                    <div class="progress" *ngIf="uploadProgres$ | async | uploadProgress : 'CALLBACK' as upProgress">
                        <div class="progress-bar" 
                        [style.width]="upProgress.progress + '%'"
                        role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                          {{upProgress.progress}}%
                        </div>
                    </div>
                    <small>Audio que será reproducido 10 segundos antes de que se cumpla el tiempo de <strong>nivel de servicio</strong> para que el usuario 
                        deje su número telefónico en caso de requerir un retorno de llamada. Consulte <a href="#advanced">Opciones avanzadas</a> para más información.
                    </small>
                </div>
            </div>
            <div role="tabpanel" class="tab-pane fade" id="hook">
                <h4><i class="fa fa-link"></i> Integraciones y webhooks.</h4>
                <hr>
                <div class="form-group">
                    <label>Encuesta de servicio.</label>
                    <div class="input-group col-md-6" style="margin-bottom: 5px;">
                    <select name="poll" [ngModel]="queue.poll" class="form-control">
                        <option value="">-</option>
                        <option *ngFor="let poll of (polls$ | async)" [value]="poll.id">{{poll.nombre}}</option>
                    </select>
                    </div>
                    <small>Los agentes vinculados a esta cola podrán pasar a los usuarios a encuentas de servicio digitando <strong>*0</strong> durante la llamada.</small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Directorio de contactos.</label>
                    <div class="input-group col-md-6" style="margin-bottom: 5px;">
                    <select name="directorio" [ngModel]="queue.directorio" class="form-control">
                        <option value="">-</option>
                        <option *ngFor="let directorio of (directorios$ | async)" [value]="directorio.iddb">{{directorio.nombre}}</option>
                    </select>
                    </div>
                    <small>Formulario con el que se tratarán contactos nuevos o la búsqueda de contactos.</small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Campañas de marcador predictivo.</label>
                    <div class="input-group col-md-6" style="margin-bottom: 5px;">
                    <select name="dialer" [disabled]="true"  class="form-control">
                        <option value="">...</option>
                    </select>
                    </div>
                    <small>En caso de que las llamadas <strong>entrantes</strong> en esta queden abandondas en esta cola, los números de origen se agregarán a esta campaña de dialer; de esta manera podrá devolver 
                        llamadas telefónicas de manera automatizada.
                        <div class="alert alert-warning" style="margin-top: 10px;">
                            <i class="fa fa-warning"></i> Verifique que esta campaña no esté siempre actva, de lo contrario realizará llamadas no deseadas. 
                        </div>
                    </small>
                </div>
                <ng-container *ngIf="isPrivileged">
                <hr>
                <div class="form-group">
                    <label>URL eventos de llamadas</label>
                    <input type="text" class="form-control" name="hookurl" [ngModel]="queue.hookurl" style="margin-bottom: 5px;" placeholder="https://mi-api.dominio.com/hook">
                    <small>URL para el envio de eventos en método <strong><code>POST</code></strong>:
                        <ul>
                            <li>Llamadas entrantes a la campaña.</li>
                            <li>Llamadas contestadas por agente.</li>
                            <li>Llamadas abandonadas.</li>
                            <li>Callbacks</li>
                        </ul>
                        <div class="alert alert-warning">
                            <i class="fa fa-warning"></i> Asegurese de contar con un plan adecuado de consumo de API, si recibe muchas llamadas podría saturar su endpoint causando una falla en su plataforma.
                        </div>
                    </small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Autenticación de hook desde KerberusIPBX</label>
                    <div class="row" style="margin: 5px 0;">
                        <div class="col-md-1 col-xs-4" style="margin-top: 3px;"><ui-toggleV1 name="useauth" [ngModel]="queue.useauth"></ui-toggleV1></div>
                        <div class="col-md-11 col-xs-8">Activar autenticación</div>
                    </div>
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-6 form-group">
                            <label>Método de autenticación.</label>
                            <select name="authmethod" [ngModel]="queue.authmethod" class="form-control" #authmethod>
                                <option value="">Ninguno</option>
                                <option value="header">Header / Encabezado</option>
                                <option value="get">Consulta en URL / Query en URL</option>
                                <option value="body">Cuerpo de petición</option>
                            </select>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Nombre del campo de autenticación.</label>
                            <input type="text" name="authfield" [ngModel]="queue.authfield" class="form-control" #authfield>
                        </div>
                        <div class="col-md-12 form-group">
                            <label>Llave para autenticar el webhook</label>
                            <input name="authapikey" [ngModel]="queue.authapikey" type="text" class="form-control">
                        </div>
                    </div>
                    <small>En caso de que su web-hook requiera autenticación, ingrese alguno de los métodos disponibles y el nombre del campo. Ej: 
                        
                        <ul style="margin-top: 10px;">
                            <strong>Autenticación</strong> : Header / Encabezado<br>
                            <strong>Nombre del campo</strong> : <i>"authorization"</i>
                        </ul>
                    </small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Logs de webhooks</label>
                    <div style="margin-bottom: 5px;">
                    <button type="button" class="btn btn-success"><i class="fa fa-file-text-o"></i> Descargar</button> &nbsp;
                    <button type="button" class="btn btn-default"><i class="fa fa-trash"></i> Eliminar logs</button> 
                    </div>
                    <small>Los logs se retienen por un periodo de 8 días.</small>
                </div>
                </ng-container>
            </div>
            <div role="tabpanel" class="tab-pane fade" id="advanced">
                <h4><i class="fa fa-gear"></i> Configuraciones avanzadas.</h4>
                <hr>
                <div class="form-group">
                    <label>Peso de cola.</label>
                    <div class="input-group col-md-6" style="margin-bottom: 5px;">
                        <input name="peso" type="number" [ngModel]="queue.peso" class="form-control" required>
                        <span class="input-group-addon"><i class="fa fa-balance-scale"></i></span>
                    </div>
                    <small>Valor numerico de peso asignado a esta cola. En caso de que los agentes en esta cola pertenezcan también a otras colas, las llamadas asignadas a los agentes compartidos tedrán prioridad para aquella cola que posea el valor más alto en peso.</small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Tiempo de nivel de servicio.</label>
                    <div class="input-group col-md-6">
                        <input [ngModel]="queue.servicelevel" name="servicelevel" type="number" class="form-control" placeholder="En segundos..." required>
                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>
                    </div>
                    <small>Tiempo en segundos para contabilizar la cantidad de llamadas antendias antes de este tiempo.</small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Tiempo de espera en agentes.</label>
                    <div class="input-group col-md-6">
                        <input [ngModel]="queue.timeout" name="timeout" type="number" class="form-control" placeholder="En segundos..." required>
                        <span class="input-group-addon"><i class="fa fa-hourglass-start"></i></span>
                    </div>
                    <small>Tiempo en segundos que dispone el agente para atender una llamada asignada. Vencido este tiempo la llamada querá registrada como <strong>RINGNOANSWER</strong> en los Registro de Callcenter.</small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Tiempo de trabajo después de llamada (ACW).</label>
                    <div class="input-group col-md-6">
                        <input name="wrapuptime" [ngModel]="queue.wrapuptime" type="number" class="form-control" placeholder="En segundos..." required>
                        <span class="input-group-addon"><i class="fa fa-hourglass-end"></i></span>
                    </div>
                    <small>Tiempo en segundos que dispone el agente en esta cola para estar nuevamente disponible después de la ultima llamda recibida.</small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Tiempo máximo de espera por agente.</label>
                    <div class="input-group col-md-6">
                        <input name="holdtimeout" [ngModel]="queue.holdtimeout" type="number" class="form-control" placeholder="En segundos..." required>
                        <span class="input-group-addon"><i class="fa fa-hourglass-half"></i></span>
                    </div>
                    <small>Tiempo en segundos que dispone el agente para retomar una llamada en espera. Vencido este tiempo se generará una alerta visible y audible en <strong>Consola de Callcenter.</strong>
                    <p><strong>Tiempo = -1</strong> deshabilita esta función.</p>
                    </small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Anuncio de posición en la cola.</label>
                    <div class="row" style="margin: 5px 0;">
                        <div class="col-md-1 col-xs-4" style="margin-top: 3px;"><ui-toggleV1 name="notificacion" [ngModel]="queue.notificacion"></ui-toggleV1></div>
                        <div class="col-md-11 col-xs-8">Activar</div>
                    </div>
                    <small>Habilitar la repoducción de audio al usuario indicandole posición en la cola. Se reproducirá al inicio de la llamada y cada tres veces se haya cumplido el tiempo de nivel de servicio.</small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Anuncio de tiempo de espera en la cola.</label>
                    <div class="row" style="margin: 5px 0;">
                        <div class="col-md-1 col-xs-4" style="margin-top: 3px;"><ui-toggleV1 name="annPromedioEspera" [ngModel]="queue.annPromedioEspera"></ui-toggleV1></div>
                        <div class="col-md-11 col-xs-8">Activar</div>
                    </div>
                    <small>Habilitar la repoducción de audio al usuario indicandole el tiempo promedio de espera. Se reproducirá al inicio de la llamada y cada tres veces se haya cumplido el tiempo de nivel de servicio.</small>
                </div>
                <hr>
                <div class="form-group">
                    <label>Callback.</label>
                    <div class="row" style="margin: 5px 0;">
                        <div class="col-md-1 col-xs-4" style="margin-top: 3px;"><ui-toggleV1 name="callback" [ngModel]="queue.callback"></ui-toggleV1></div>
                        <div class="col-md-11 col-xs-8">Activar</div>
                    </div>
                    <small>Habilitar la devolución de llamadas en caso de que el usuario complete el tiempo máximo de nivel de servicio. Cumplido este tiempo la plataforma podrá tomar su número y retornarle la llamada.</small>
                </div>
            </div>
        </div>
        </form>
    </div>
    <div class="col-md-offset-3 col-md-9">
        <hr>
        <div class="text-right">
            <!--Action Responses-->
            <div *ngIf="!!actionResponse.error" class="alert alert-danger text-left">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <i class="fa fa-warning"></i> {{actionResponse.error}}
            </div>
            <div *ngIf="!!actionResponse.mensaje" class="alert alert-success text-left">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <i class="fa fa-check-circle-o"></i> {{actionResponse.mensaje}}
            </div>

            <button type="button" class="btn btn-default" (click)="regresar(queue.nombre)">Regresar</button> &nbsp;
            <button type="button"
            [disabled]="!f.valid || inProgress"
            (click)="guardarCola(f.value)" class="btn btn-primary">
                <span *ngIf="!inProgress">
                    Guardar cambios
                </span>
                <span *ngIf="inProgress"><i class="fa fa-spinner fa-spin"></i> Guardando cambios...</span>
            </button>
        </div>
    </div>
</div>
<ng-template #loading>
    <div style="padding: 70px 0;" class="text-muted text-center">
        <i class="fa fa-spinner fa-spin fa-2x text-primary" style="margin-bottom: 15px;"></i><br>
        Un momento...
    </div>
</ng-template>