import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   ViewChild,
   ElementRef,
} from "@angular/core";
import { Secuencia, EOpcion } from "../../../_interfaces/ivr";
import { Peer } from "../../../_interfaces/peer";
import { Grupo } from "../../../_interfaces/grupo";
import { Queue } from "../../../_interfaces/queue";
import { IVR } from "../../../_interfaces/ivr";
import * as _ from "lodash";
import { Observable, Subject, of, empty, throwError } from "rxjs";
import { EDBResponseType, IDBResponse } from "../../../_interfaces/responses";

import { PeerService } from "../../../_services/peer.service";
import { QueueService } from "../../../_services/queue.service";
import { GruposService } from "../../../_services/grupo.service";
import { IVRService } from "../../../_services/ivr.service";
import { TroncalService } from "../../../_services/troncal.service";
import { filter, map, finalize, flatMap, tap, delay } from "rxjs/operators";
import { FileHandlerService } from "src/app/_services/file.service";
import { HttpEventType } from "@angular/common/http";
import { SocketService, EComando } from "src/app/_services/socket.service";

interface IOpcionesIVR {
   opcion: EOpcion;
   operacion: string;
}

@Component({
   selector: "secuencia-tag",
   templateUrl: "secuencia.component.html",
   styles: [
      `
         .li-ivr {
            padding-top: 2px;
            padding-bottom: 2px;
            font-size: 13px;
         }
      `,
   ],
})
export class SecuenciaComponent implements OnInit {
   @Input() did: string;
   @Input() readOnly: boolean;
   @Input() secuencia: Secuencia[];

   readonly UPLOAD_URL = "ivr/audio";
   inProgress = false;
   nombreAudio: string;
   uploadProgres$ = new Subject<{ fileType: string; progress: number }>();

   audioSeleccionado: string;
   private secuenciaEdicion: Secuencia; // Secuencia inicializada
   private secArray: Secuencia[]; // Secuencia actual donde se empuja el nuevo valor

   private audio_ivr = new Audio();
   ops = <IOpcionesIVR[]>[];
   o = EOpcion;

   // Variable para inicializar el formulario de creación de
   secuenciaForm = { index: "", opcion: "" };

   peer$: Observable<Peer[]>;
   grupo$: Observable<Grupo[]>;
   queue$: Observable<Queue[]>;
   ivr$: Observable<IVR[]>;
   troncal$: Observable<any[]>;

   @ViewChild("fileUpload")
   fileUpload: ElementRef;

   constructor(
      private $peer: PeerService,
      private $grupo: GruposService,
      private $queue: QueueService,
      private $ivr: IVRService,
      private $troncal: TroncalService,
      private $fileService: FileHandlerService,
      private $monitor: SocketService
   ) {
      this.peer$ = this.$peer.getPeers();
      this.grupo$ = this.$grupo.getGrupos();
      this.queue$ = this.$queue.getQueues();
      this.ivr$ = this.$ivr.getDids();
      this.troncal$ = this.$troncal.getTroncalesPorContexto("interx");
   }

   ngOnInit() {
      this.ops = [
         {
            opcion: EOpcion.EXTENSION,
            operacion: "Gosub(marcacionInterna,s,1(%g,%d))", // 'Macro(marcacionInterna,%g,%d)'
         },
         {
            opcion: EOpcion.GRUPO,
            operacion: "Gosub(marcacionGrupo,s,1(${G_%g},%d))",
         },
         {
            opcion: EOpcion.QUEUE,
            operacion: "Gosub(Queues,${EXTEN},1(%g))", // %g = queuename,prio
         },
         {
            opcion: EOpcion.SUBIVR,
            operacion: "GoSub(%g_%d,%d,1)", // s, Goto(context, did, 1)
         },
         {
            opcion: EOpcion.BACKGROUND,
            operacion: "Background(ivr/%d_%g)",
         },
         {
            opcion: EOpcion.PLAYBACK,
            operacion: "Playback(ivr/%d_%g)",
         },
         {
            opcion: EOpcion.DID,
            operacion: "Goto(Entrante,%g,1)",
         },
         {
            opcion: EOpcion.DATABASE,
            operacion: "-",
         },
         {
            opcion: EOpcion.INTERX,
            operacion: "Gosub(interx,s,1(%g))", // %g Troncal, DID
         },
         {
            opcion: EOpcion.MARCACION_EXT,
            operacion: "Gosub(kerberus,%g,1(FROM_IVR))",
         },
         {
            opcion: EOpcion.COLGAR,
            operacion: "Hangup()",
         },
         {
            opcion: EOpcion.RETORNO,
            operacion: "Goto(${ARG1},${ARG2},1)",
         },
      ];

      this.ops = _.sortBy(this.ops, ["opcion"]);
   }

   startUpload(nombre: string) {
      this.nombreAudio = nombre.split(" ").join("_");
      const uploader = this.fileUpload.nativeElement;
      uploader.onchange = () => {
         const nativeElementFiles = this.fileUpload.nativeElement.files;
         const file =
            nativeElementFiles.length > 0 ? nativeElementFiles[0] : undefined;
         this.audioSeleccionado = file.name;
      };
      uploader.click();
   }

   private upload(file, nombre: string) {
      this.inProgress = true;
      const form = new FormData();
      form.append("audio_attach", file);

      return this.$fileService.upload(this.UPLOAD_URL, form).pipe(
         map((event) => {
            switch (event.type) {
               case HttpEventType.UploadProgress:
                  const progress = Math.round(
                     (event.loaded * 100) / event.total
                  );
                  this.uploadProgres$.next({
                     fileType: "IVR",
                     progress: progress,
                  });
                  break;
               case HttpEventType.Response:
                  return event;
            }
         }),
         filter((response) => !!response),
         map((response) => response.body as IDBResponse),
         flatMap(({ data }) =>
            this.$monitor.enviarComando({
               comando: EComando.AUDIO_FORMAT,
               data: { in: data, out: `ivr/${this.did}_${nombre}.wav` },
            })
         )
      );
   }

   play(src: string) {
      try {
         this.audio_ivr.src = "ivr/" + this.did + "_" + src + ".wav";
         console.log(this.audio_ivr.src);
         this.audio_ivr.play();
      } catch (e) {
         console.log();
      }
   }

   stop() {
      this.audio_ivr.pause();
      this.audio_ivr.currentTime = 0;
   }

   /**
    * Funcion para eliminar un objeto del IVR
    * @param Secuencia[] actual
    * @param Secuencia a eliminar
    */
   eliminarSecuencia(secuencia: Secuencia[], toDel: Secuencia) {
      // Encontrar el objeto exacto a eliminar
      const index = _.findIndex(secuencia, (s) => {
         return (
            s.did === toDel.did &&
            s.contexto === toDel.contexto &&
            s.index === toDel.index &&
            s.opcion_ivr === toDel.opcion_ivr &&
            s.valor === toDel.valor
         );
      });

      if (index > -1) {
         let clone = false;
         if (this.secuencia === secuencia) {
            // Si es base de raiz
            clone = true;
         }
         secuencia.splice(index, 1);

         // Reordenar indexes
         const _seq = _.filter(secuencia, ["contexto", toDel.contexto]);
         for (let i = 0; i < _seq.length; i++) {
            if (_seq[i].opcion_ivr === "") {
               _seq[i].index = i + 1;
            }
         }

         if (!!clone) {
            this.secuencia = _.cloneDeep(secuencia);
         }
      }
   }
   // </editor-fold>

   agregarSecuencia(secuencia: Secuencia[], contexto: string) {
      this.audioSeleccionado = "";
      this.secuenciaEdicion = new Secuencia();
      this.secuenciaForm = {
         index: "",
         opcion: "",
      };

      // <editor-fold defaultstate="collapsed" desc="Secuencia SUBMENU">
      if (!!secuencia) {
         this.secuenciaEdicion = {
            did: this.did,
            contexto: contexto,
            index:
               _.filter(secuencia, (sec) => {
                  return sec.contexto === contexto && sec.opcion_ivr === "";
               }).length + 1,
            opcion: "",
            valor: "",
            operacion: "",
            opcion_ivr: "",
         };
         this.secArray = secuencia;
         // secuencia.push(this.secuenciaEdicion);
      } else {
         // Si no existe, es raiz de IVR
         this.secuenciaEdicion = {
            did: this.did,
            contexto: contexto,
            index:
               _.filter(this.secuencia, (sec) => {
                  return sec.contexto === contexto && sec.opcion_ivr === "";
               }).length + 1,
            opcion: "",
            valor: "",
            operacion: "",
            opcion_ivr: "",
         };
         // Sanitize pure filter
         this.secArray = this.secuencia;
      }

      $("#modalOpcionIVR").modal();
   }

   generarSecuencia(_secForm: Secuencia) {
      const secForm = _.cloneDeep(_secForm);
      secForm.opcion = EOpcion[secForm.opcion];

      this.secuenciaEdicion.index =
         secForm.index || this.secuenciaEdicion.index;
      this.secuenciaEdicion.opcion = secForm.opcion;
      this.secuenciaEdicion.valor = secForm.valor.split(" ").join("_");
      this.secuenciaEdicion.operacion = _.find(this.ops, [
         "opcion",
         EOpcion[secForm.opcion],
      ]).operacion;
      this.secuenciaEdicion.opcion_ivr = secForm.opcion_ivr;
      this.secuenciaEdicion.subMenu = secForm.subMenu;

      if (this.secArray === this.secuencia) {
         this.secuencia.push(this.secuenciaEdicion);
         this.secuencia = _.cloneDeep(this.secuencia);
      } else {
         this.secArray.push(this.secuenciaEdicion);
      }

      // console.log(secForm);
      // Iniciar la carga del archivo
      if (
         EOpcion[secForm.opcion as string] === EOpcion.BACKGROUND ||
         EOpcion[secForm.opcion as string] === EOpcion.PLAYBACK
      ) {
         // this.upload.emit(true);
         const nativeElementFiles = this.fileUpload.nativeElement.files;
         const file =
            nativeElementFiles.length > 0 ? nativeElementFiles[0] : undefined;

         if (!!file) {
            this.upload(file, this.nombreAudio)
               .pipe(
                  finalize(() => {
                     this.inProgress = false;
                     this.fileUpload.nativeElement.value = "";
                     this.uploadProgres$.next({
                        fileType: "IVR",
                        progress: 100,
                     });
                     $("#modalOpcionIVR").modal("hide");
                  })
               )
               .subscribe();
         }
      } else {
         $("#modalOpcionIVR").modal("hide");
      }
   }
}
