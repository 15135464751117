<div class="btn-group" role="group" *ngIf="!!agente">
    <button class="btn" [ngClass]="agentColor">
        <i class="fa" [ngClass]="{
            'fa-clock-o': isAgentInPause, 
            'fa-user-circle-o' : !isAgentInPause
        }" ></i> {{agente?.idagente}} {{agente?.nombre}}
    </button>
    <button type="button" *ngIf="agente | agenteModoSkill as skill"
        class="btn" 
        [disabled]="disabled"
        [ngClass]="{
            'btn-success': (skill === 'Inbound') && !isAgentOffline, 
            'btn-primary': (skill !== 'Inbound') && !isAgentOffline,
            'btn-default' : isAgentOffline,
            'dropdown-toggle ' : !isAgentOffline
        }"
        id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-star-o"></i> {{skill}} 
        <i class="fa fa-caret-down" *ngIf="!isAgentOffline" style="margin-left: 6px;"></i>
    </button>
    <ul *ngIf="!isAgentOffline" class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdownMenu2">
        <li><a href="javascript:void(0)" (click)="cambiarSkill(agente.defaultPenalty)">
            <i class="fa fa-star"></i> Cambiar a Skill por defecto</a></li>
        <li><a href="javascript:void(0)" (click)="cambiarSkill(100)">
            <i class="fa fa-arrows-h"></i> Cambiar a Mixed</a></li>
        <li><a href="javascript:void(0)" (click)="cambiarSkill(0)">
            <i class="fa fa-arrow-right"></i> Cambiar a Inbound</a></li>
        <li><a href="javascript:void(0)" (click)="cambiarSkill(-1)">
            <i class="fa fa-arrow-left"></i> Cambiar a Outbound</a></li>
    </ul>
</div>
<!-- Observable para emitir el formulario y el numero -->
<ng-container *ngIf="directorio$ | async"></ng-container>