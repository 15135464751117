<div class="row">
    <div class="col-xs-2 text-center" style="padding-top: 12px;">
        <span class="label label-default" 
            [ngClass]="{ 'label-danger': isFailed, 'label-success': isSuccess, 'label-warning' : isInProgress, 'label-info' : isReintento }">
            {{reparto?.estado}}</span>
    </div>
    <div class="col-xs-6">
        <a href="javascript:void(0)" (click)="cargarContacto()">
            <i class="fa fa-address-card-o"></i> Ver detalles del contacto 
        </a>
        <br>
        <ng-container *ngIf="!hasRepartoState">
            <a href="javascript:void(0)" class="btn btn-xs btn-primary" 
                [ngClass]="{'btn-primary': i === 0, 'btn-info': i > 0}"
                style="margin-right: 5px; margin-top: 4px;" 
                (click)="marcarNumero(contacto.numero)"
                *ngFor="let contacto of reparto.contacto; index as i">
                
                    <i class="fa fa-phone-square"></i> {{contacto.numero | hidePhone}}
            </a>
        </ng-container>
        <ng-container *ngIf="hasRepartoState">
            <a href="javascript:void(0)" class="btn btn-xs btn-default disabled" style="margin-right: 5px; margin-top: 4px;"
                *ngFor="let contacto of reparto.contacto; index as i"> 
                <i class="fa fa-phone-square"></i> {{contacto.numero | hidePhone}}</a>
        </ng-container>
    </div>
    <div class="col-xs-4 text-right" style="padding-top: 8px;">
        <ng-container *ngIf="isFailed || isSuccess">
            <div class="btn-group btn-group-sm">
                <button type="button"
                    (click)="actualizar(successEnum)"
                    class="btn btn-success" style="font-weight: 500;">
                    <i class="fa fa-check-square-o"></i> Resultado
                </button>
                <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li><a href="javascript:void(0)" (click)="actualizar(successEnum)">
                        <i class="fa fa-check-square-o" style="margin-right: 7px;"></i> Contacto efectivo</a></li>
                    <li><a href="javascript:void(0)" (click)="actualizar(reintentarEnum)">
                        <i class="fa fa-clock-o" style="margin-right: 7px;"></i> Contacto a reintentar</a></li>
                    <li class="disabled"><a href="javascript:void(0)">
                        <i class="fa fa-calendar" style="margin-right: 7px;"></i> Agendar llamada en calendario</a></li>
                    <li role="separator" class="divider"></li>
                    <li><a href="javascript:void(0)" (click)="actualizar(failedEnum)">
                        <i class="fa fa-times-circle-o" style="margin-right: 7px;"></i> Contacto fallido</a></li>
                </ul>
            </div>
        </ng-container>
    </div>
</div>