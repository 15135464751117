import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as dayjs from "dayjs";
import {
   Subscription,
   Observable,
   Subject,
   BehaviorSubject,
   of,
   combineLatest,
} from "rxjs";

import { AgenteService } from "../_services/agente.service";
import {
   SocketService,
   EComando,
   EResponse,
} from "../_services/socket.service";
import { QueueService } from "../_services/queue.service";
import {
   IAgentState,
   IAgentStateMonitor,
   IChannel,
} from "../_interfaces/monitor";
import {
   EDBResponseType,
   Queue,
   IDBResponse,
   IAgentMonitor,
} from "../_interfaces/_all";

import * as uuid from "../_services/_objectID";

import {
   flatMap,
   map,
   toArray,
   tap,
   filter,
   finalize,
   debounceTime,
   delay,
   switchMap,
   withLatestFrom,
   mergeMap,
   shareReplay,
   startWith,
   defaultIfEmpty,
   mapTo,
} from "rxjs/operators";
import { ICallStatus, ECallStatus } from "../_interfaces/callstatus";
import { IContacto } from "../_interfaces/contacto";
import { MarcadorComponent } from "./marcador/marcador.component";
import { PauseReasonService } from "../_services/pauseReasons.service";
import { IPauseReason } from "../_interfaces/pauseReason";
import { IScheduleJob, ETipoScheduleEvent } from "../_interfaces/schedule";

@Component({
   selector: "agente-comp",
   templateUrl: "./agente.component.html",
   styles: [
      `
         body {
            background-color: white;
         }
         .container-nav {
            margin-right: 25px;
         }
         .li-stat {
            color: #dcdcdc;
            padding: 17px;
         }
      `,
   ],
})
export class AgenteComponent implements OnInit, OnDestroy {
   noAgent$: Observable<boolean>;
   agente$: Observable<IAgentState>;
   agenteStates$: Observable<IAgentStateMonitor[]>;
   pauseReason$: Observable<IPauseReason[]>;

   private mensajeSubscription: Subscription;
   private queuesAvailabletoXfer$: Observable<Queue[]>;
   private xfer: { destino: string; cod?: string; tipo: string; src?: string };

   operacionEncurso$ = new BehaviorSubject<{ inProgress: boolean }>({
      inProgress: false,
   });

   outboundCallRespose$ = new BehaviorSubject<{
      failed: boolean;
      inProgress: boolean;
      callid?: string;
      otherCallid?: string;
   }>({
      inProgress: false,
      failed: false,
   });

   // Tiempo en el estado de pausa.
   pauseStateTime$: Observable<Date>;
   // Tiempo en llamada
   incallStateTime$: Observable<Date>;
   currentTime$ = this.$sockeService
      .getServerTime()
      .pipe(startWith(new Date().getTime()));
   // channelSnapshot
   channelSnapshot$ = new Subject<IChannel>();
   // Observable de contactoID
   idContacto$ = new Subject<IContacto>();
   // observable del id del directorio
   directorio$ = new Subject<{ iddb: string; numero: string }>();
   // Variable para pasar como argumento a los componentes con el estado de llamada.
   callStatus: ICallStatus;

   @ViewChild(MarcadorComponent)
   marcador: MarcadorComponent;

   constructor(
      private $route: ActivatedRoute,
      private $router: Router,
      private $queue: QueueService,
      private $agente: AgenteService,
      private $sockeService: SocketService,
      private $pauseReason: PauseReasonService
   ) {}

   ngOnInit() {
      // Notificaciones
      if (
         Notification["permission"] !== "granted" &&
         Notification["permission"] !== "denied"
      ) {
         Notification.requestPermission();
      }

      // Iniciar el estado de posible llamada en NO_CALL
      this.initCallStatus();

      // Colas disponibles para ser transferidas llamadas
      this.queuesAvailabletoXfer$ = this.$queue.getQueues().pipe(
         flatMap((queues) => queues),
         filter((q) => q.directo !== ""),
         toArray()
      );

      // Manejador de mensajes
      if (Notification["permission"] === "granted") {
         this.mensajeSubscription = this.$route.data
            .pipe(
               map(
                  (data: { agente: { idagente: string } }) =>
                     data.agente.idagente
               ),
               flatMap((idAgente) => this.$sockeService.getMensajes(idAgente)),
               tap((mensaje) => console.log(mensaje)),
               map((mensaje) => {
                  const options = {
                     icon: "/public/img/icon-512x512.png",
                     body: mensaje.mensaje,
                     requireInteraction: true,
                  };
                  const audio = new Audio("/public/sounds/sound4.ogg");
                  audio.play();

                  const not = new Notification(
                     "Mensaje de " + mensaje.fuente,
                     options
                  );
               })
            )
            .subscribe();
      }

      // Observable to show message in case agent is not receiving info from any queue
      // this.noAgent$ = timer(4000).pipe(takeUntil(this.agente$), mapTo(true))

      /**
       * Render the agent
       */
      this.agente$ = this.$route.data.pipe(
         map((data: { agente: { idagente: string } }) => data.agente),
         tap((agente) => console.log("Bienvenido Agente ", agente.idagente)),
         mergeMap(({ idagente }) => this.$agente.getAgente(idagente)),
         map(
            (dbAgent) =>
               ({
                  fecha: new Date().getTime().toString(),
                  idagente: dbAgent.idagente,
                  nombre: dbAgent.nombre,
                  defaultPenalty: dbAgent.penalidad,
                  canHangup: dbAgent.canHangup,
                  canPause: dbAgent.canPause,
                  canXFER: dbAgent.canXFER,
                  estado: "OFFLINE",
               } as IAgentState)
         ),
         tap((agente) =>
            // Logueo de agente en socket service
            this.$sockeService.setRooms([
               `agent-${agente.idagente}`,
               `qmessage-${agente.idagente}`,
               "channels",
            ])
         ),
         mergeMap((agentInit) =>
            combineLatest([of(agentInit), this.$sockeService.getAgentState$()])
         ),
         map(
            ([agentInit, states]) =>
               ({
                  ...agentInit,
                  estado:
                     states.length > 0
                        ? this.orderStates(states)[0].status
                        : agentInit.estado,
                  states: states.sort((a, b) =>
                     a.queuename > b.queuename ? 1 : -1
                  ),
               } as IAgentState)
         ),
         mergeMap((agente) =>
            // Obteniendo canales en tiempo en el que lleguen
            this.$sockeService.getChannels$().pipe(
               tap((channels) => {
                  const currentStateInCall = this.getCurrentChannelInCall(
                     agente,
                     channels
                  );
                  this.channelSnapshot$.next(currentStateInCall);
                  this.setCallStatusForDialer(currentStateInCall);
               }),
               mapTo(agente)
            )
         ),
         shareReplay(1)
      );

      this.initStateTimes();
   }

   /**
    * Función para iniciar los timers
    */
   initStateTimes() {
      this.pauseStateTime$ = this.currentTime$.pipe(
         withLatestFrom(this.agente$),
         // filtrar por evento
         map(([current, agente]) => {
            let timeInState: number;
            const agentState = agente.states[0];
            // Si esta en pausa
            if (agentState.status === "PAUSE") {
               if (agentState.last_call_date > agentState.last_status_date) {
                  // Tiempo desde que terminó la última llamda.
                  timeInState = Number.parseInt(agentState.last_call_date);
               } else if (
                  agentState.last_call_date < agentState.last_status_date
               ) {
                  // Tiempo desde que terminó la última pausa.
                  timeInState = Number.parseInt(agentState.last_status_date);
               }
            } else {
               timeInState = current;
            }
            //
            const auxTime = new Date().setHours(0, 0, 0, 0);
            // Evitar tiempos 23:59:XX
            if (current - timeInState > 0) {
               return new Date(auxTime + (current - timeInState));
            } else {
               return new Date(auxTime);
            }
         })
      );

      this.incallStateTime$ = this.currentTime$.pipe(
         withLatestFrom(this.agente$),
         // filtrar por evento
         map(([current, agente]) => {
            let timeInState: number;
            // Averiguar en que llamada esta!!
            const agentState = agente.states.find(
               (s) => s.bound_channel_id !== ""
            );
            // Si esta en llamdaa
            if (!!agentState && agentState.status.includes("_BUSY")) {
               // Tiempo en el que inicio la llamada
               timeInState = Number.parseInt(agentState.current_status_date);
               // Tomar el canal.
            } else {
               timeInState = current;
            }
            //
            const auxTime = new Date().setHours(0, 0, 0, 0);
            // Evitar tiempos 23:59:XX
            if (current - timeInState > 0) {
               return new Date(auxTime + (current - timeInState));
            } else {
               return new Date(auxTime);
            }
         })
      );
   }

   orderStates(agentStates: IAgentStateMonitor[]) {
      return agentStates
         .filter((st) => st.status !== "OFFLINE" && st.status !== "PAUSE")
         .concat(agentStates.filter((st) => st.status === "PAUSE"))
         .concat(agentStates.filter((st) => st.status === "OFFLINE"));
   }

   setCallStatusForDialer(channel: IChannel) {
      this.callStatus = {
         duracion: "0",
         numero: channel?.CallerID,
         queue: channel?.Accountcode,
         contactId: channel?.peerAccount,
         estado: !!channel
            ? (channel.Stats as ECallStatus)
            : ECallStatus.NO_CALL,
      };
   }

   cargarMotivosPausa() {
      // Motivos de pausa
      this.pauseReason$ = this.$pauseReason
         .obtenerRazones()
         .pipe(map((auxiliary) => auxiliary.filter((a) => a.agentVisible)));
   }

   cerrarSesion() {
      this.$agente
         .cerrarSesion()
         .pipe(
            flatMap(() => this.$route.data),
            map(
               (data: { agente: { idagente: string } }) => data.agente.idagente
            ),
            // Eliminar la suscripción del agente
            tap((agenteid) =>
               this.$sockeService.exitRooms([`agent-${agenteid}`, "channels"])
            )
         )
         .subscribe(
            () => {
               console.log("out");
               this.$router.navigateByUrl("/login");
            },
            (err) => console.warn(err)
         );
   }

   enviarMensaje(origen: IAgentState, mensaje: string) {
      // Impedir mandar mensajes a @agente
      if (mensaje.indexOf("@") > -1) {
         const destino = mensaje.substring(mensaje.indexOf("@"));
         if (destino.length > 0) {
            if (isNaN(Number.parseInt(destino.substring(1, 2)))) {
               this.$sockeService.enviarMensaje({
                  fuente: "@" + origen.idagente + " " + origen.nombre,
                  mensaje: mensaje,
               });
            } else {
               console.log("Impedir", destino.substring(1, 2));
            }
         }
      }
   }

   /**
    * Función para ejecutar operaciones de la agenda.
    * @param tarea IScheduleJob
    */
   ejecutarAccionAgenda(tarea: IScheduleJob, estado: string) {
      switch (tarea.tipoEvento) {
         case ETipoScheduleEvent.CALL: {
            if (estado.includes("IDLE")) {
               this.marcador.marcadorManual.ejecutarLlamada(
                  { numero: tarea.numero },
                  undefined,
                  EComando.MAKE_OUTBOUND_AGENDA
               );
            } else {
               alert(
                  "En este momento no estas disponible para ejecutar una llamada, deberás ejecutar esta llamada de manera manual."
               );
            }
            break;
         }
         default:
            break;
      }
   }

   transferirLlamada(xfer: { destino: string; cod?: string }) {
      let tipo = "Cola de Callcenter";
      if (xfer.destino === "**") {
         tipo = "Agente";
      } else if (xfer.destino === "***") {
         tipo = "Extensión";
      }

      this.xfer = {
         destino: xfer.destino,
         cod: xfer.cod,
         tipo: tipo,
      };
      $("#xferConfirm").modal();
   }

   ejecutarXfer(agente: IAgentState) {}

   cambiarModo(skillNumber: number, agente: IAgentState): void {
      console.log(agente);

      $("#modalWait_kerberus").modal({
         backdrop: "static",
         keyboard: false,
      });

      // Invocar el servicio
      const modoAgente =
         agente.states.length > 0
            ? agente.states[0].modalidad_agente
            : "AUTOMATIC";
      this.$agente
         .cambiarSkill(agente.idagente, modoAgente, skillNumber)
         .pipe(
            delay(300),
            finalize(() => this.operacionEncurso$.next({ inProgress: false }))
         )
         .subscribe(
            (response) => {
               $("#modalWait_kerberus").modal("hide");
               console.log(response);
            },
            (err) => {
               console.error(err);
               $("#modalWait_kerberus").modal("hide");
            }
         );
   }

   Pausa(agente: IAgentState, motivo: string, modo: string) {
      const idReason = uuid.ObjectID();
      const modoAgente =
         agente.states.length > 0
            ? agente.states[0].modalidad_agente
            : "AUTOMATIC";

      const pauseCommand$ = (idreason: string) =>
         flatMap((response: IDBResponse) => {
            if (response.tipo === EDBResponseType.QUERY_OK) {
               // let data = {agente: this.agente.idagente, modo: modo, modoAgente: this.agente.modoAgente, cola: this.agente.stats[0].cola, reason: idreason };
               // Pausa en cada cola pero enviar en paquete
               return of(agente.states.map((s) => s.queuename)).pipe(
                  map((agentInQueues) => ({
                     agente: agente.idagente,
                     modo: modo,
                     modoAgente: modoAgente,
                     cola: agentInQueues.join("^"),
                     reason: idreason,
                  })),
                  flatMap((dataCommand) =>
                     this.$sockeService.enviarComando({
                        comando: EComando.AGENT_PAUSE,
                        data: dataCommand,
                     })
                  )
               );
            } else {
               const res = new Subject<{ tipo: EResponse; data: Object }>();
               res.next({
                  tipo: EResponse.FALLIDO,
                  data: response.data,
               });
               return res.asObservable();
            }
         });

      of(idReason)
         .pipe(
            tap(() => this.operacionEncurso$.next({ inProgress: true })),
            debounceTime(600),
            switchMap((idreason: string) =>
               this.$agente
                  .setReason({
                     idreason: idreason,
                     tipo: "PAUSA POR AGENTE",
                     motivo: motivo,
                     autor: "AGENTE " + agente.idagente,
                     fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                  })
                  .pipe(pauseCommand$(idreason))
            ),
            delay(1000),
            finalize(() => this.operacionEncurso$.next({ inProgress: false }))
         )
         .subscribe(
            (response) => {
               console.log(response);
               // $('#agente-ops').modal('hide');
            },
            (err) => console.log(err)
         );
   }

   colgarLlamada(agente: IAgentState, motivo: string) {
      $("#modalWait_kerberus").modal({
         backdrop: "static",
         keyboard: false,
      });

      const idReason = uuid.ObjectID();
      of(true)
         .pipe(
            tap(() => this.operacionEncurso$.next({ inProgress: true })),
            debounceTime(600),
            // Obtener el canal.
            map(() => this.getCurrentChannelInCall(agente)),
            filter((channel) => !!channel),
            mergeMap((channel) =>
               this.$agente
                  .setReason({
                     idreason: idReason,
                     tipo: "CUELGUE_POR_AGENTE",
                     motivo: motivo,
                     autor: "AGENTE" + agente.idagente,
                     fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                  })
                  .pipe(
                     map((_) => ({
                        agente: agente.idagente,
                        callid: channel.UniqueID,
                        channel: channel.Channel,
                     })),
                     mergeMap((request) => this.$agente.colgarLlamada(request))
                  )
            ),
            defaultIfEmpty(),
            delay(200),
            finalize(() => this.operacionEncurso$.next({ inProgress: false }))
         )
         .subscribe(
            (res) => {
               $("#modalWait_kerberus").modal("hide");
               console.log(res);
            },
            (err) => {
               $("#modalWait_kerberus").modal("hide");
               console.error(err, agente);
            }
         );
   }

   cargarContacto(idContacto: string) {
      $('#tabContacto a[href="#Contacto"]').tab("show");
      this.idContacto$.next({ _id: idContacto, iddb: "" });
   }

   cargarFormularioContacto(data: { iddb: string; numero: string }) {
      $('#tabContacto a[href="#Contacto"]').tab("show");
      this.directorio$.next(data);
   }

   /**
    * Colocar el contactid en el snapshot.
    * @param _id
    */
   actualizaCanalAgente(_id: string) {
      of(_id)
         .pipe(
            withLatestFrom(this.agente$),
            map(([id, agente]) => {
               const channel = this.getCurrentChannelInCall(agente);
               return { ...channel, peerAccount: id };
            })
         )
         .subscribe((channel) => this.channelSnapshot$.next(channel));
   }

   ngOnDestroy() {
      if (this.mensajeSubscription && !this.mensajeSubscription) {
         this.mensajeSubscription.unsubscribe();
      }
   }

   private initCallStatus() {
      this.callStatus = {
         queue: "",
         numero: "",
         duracion: "0",
         estado: ECallStatus.NO_CALL,
         contactId: "",
      };
   }

   private getCurrentChannelInCall(
      agente: IAgentMonitor,
      canales = this.$sockeService.getChannels()
   ): IChannel | null {
      // Corrección del canal si la llamada es outbound
      const currentAgent = agente.states.find(
         (st) => st.bound_channel_id !== ""
      );
      // Si la llamada es outbound
      if (!!currentAgent && !this.isCallInbound(currentAgent)) {
         // Buscar el canal con el cual esta unido.
         const canalDelUsuario = canales.find(
            (ch) => ch.UniqueID === currentAgent.bound_channel_id
         );

         const channelName = !!canalDelUsuario
            ? canalDelUsuario?.Channel.split(";").shift()
            : // No existe, construirlo
              `Local/${currentAgent.agent}@`;

         const finalChannel = canales.find((ch) => {
            return (
               ch.Channel.startsWith(channelName) &&
               ch.UniqueID !== canalDelUsuario?.UniqueID
            );
         });

         return !!canalDelUsuario
            ? finalChannel
            : { ...finalChannel, UniqueID: currentAgent.bound_channel_id };
      } else if (!!currentAgent) {
         return canales.find(
            (ch) => ch.UniqueID === currentAgent.bound_channel_id
         );
      }

      return undefined;
   }

   private isCallInbound(agentState: IAgentStateMonitor) {
      return (
         agentState.call_type === "INBOUND" ||
         agentState.call_type === "CALLBACK" ||
         agentState.call_type === "CLICK2CALL" ||
         agentState.call_type === "DIALER_PREDICTIVO"
      );
   }
}
