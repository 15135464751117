<nav class="navbar navbar-inverse navbar-fixed-top bk">
    <div class="navbar-header" style="height: 58px">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" style="padding-top: 0px" href="javascript:void(0)" (click)="home()">
            <img src="public/img/logo_pbx_kerberus.png" alt="Kerberus IPBX" style="max-height: 58px;">
        </a>
    </div>
    <div id="navbar" class="navbar-collapse collapse">
        <ul class="nav navbar-nav" >
        </ul>
        <ul class="nav navbar-nav navbar-right" style="margin-right: 12px">
            
            <li style="padding: 19px;" class="text-white">
                <i class="fa fa-user-circle-o"></i> {{usuario?.usuario}}
            </li>

            <li class="dropdown">
                <div class="dropdown" style="margin-top: 12px; margin-left: 12px">
                    <button class="btn btn-primary dropdown-toggle hidden-sm hidden-xs" data-toggle="dropdown" >
                        <i class="fa fa-bars"></i>
                    </button>
                    <a href="#" class="dropdown-toggle hidden-md hidden-lg text-center" data-toggle="dropdown">Ver Opciones del Menú</a>
                    
                    <ul class="dropdown-menu multi-column columns-2">
                        <div class="row" style="font-size: 13px">
                            <div class="col-sm-6">
                                <ul class="multi-column-dropdown">
                                    <li class="dropdown-header">IPPBX</li>
                                    <li>
                                        <!--<a href="javascript: void(0)" (click)="setOperacion(vm.EXTENSIONES)">-->
                                        <a href="javascript: void(0)" (click)="navigateTo()">
                                            <i class="fa fa-phone text-muted"></i> Consola de extensiones</a></li>
                                            
                                    <li *ngIf="usuario.permisos === _rol.ADMIN">
                                        <a href="javascript: void(0)" (click)="navigateTo('groups', 'view')">
                                            <i class="fa fa-users text-muted"></i> Grupos de extensiones</a></li>
                                            
                                    <li *ngIf="usuario.permisos <= _rol.SUPER">
                                        <a href="javascript: void(0)" (click)="navigateTo('call-log', 'view')">
                                            <i class="fa fa-list-ul text-muted"></i> Registros de llamadas</a></li>
                                    
                                    <li class="divider"></li>
                                    <li class="dropdown-header">Callcenter</li>
                                    <li *ngIf="usuario.permisos <= _rol.MANAGER">
                                        <a href="javascript: void(0)" (click)="navigateTo('agents', 'view')">
                                            <i class="fa fa-headphones text-muted"></i> Agentes</a></li>
                                            
                                    <li *ngIf="usuario.permisos <= _rol.AUDITOR">
                                        <a href="javascript: void(0)" (click)="navigateTo('callcenter', 'view')">
                                        <!--<a href="javascript: void(0)" (click)="setOperacion(vm.CALLCENTER)">-->
                                            <i class="fa fa-dashboard text-muted"></i> Consola Callcenter</a></li>

                                    <li *ngIf="usuario.permisos  <= _rol.AUDITOR">
                                        <a href="javascript: void(0)" (click)="navigateTo('callcenter-log', 'view')">
                                            <i class="fa fa-list-ul text-muted"></i> Registros de Callcenter</a></li>
                                            
                                    <li *ngIf="usuario.permisos <= _rol.AUDITOR">
                                        <a href="javascript: void(0)" (click)="navigateTo('agents-report', 'view')">
                                            <i class="fa fa-line-chart text-muted"></i> Informes de Agentes</a></li>
                                    
                                    <li *ngIf="usuario.permisos <= _rol.AUDITOR">
                                        <a href="javascript: void(0)" (click)="navigateTo('callcenter-report', 'view')">
                                            <i class="fa fa-pie-chart text-muted"></i> Informes de Callcenter</a></li>

                                    <li class="divider"></li>
                                    <li class="dropdown-header">Servicios para Callcenter</li>
                                    
                                    <li *ngIf="usuario.permisos  <= _rol.MANAGER">
                                        <a href="javascript: void(0)" (click)="navigateTo('dialer', 'view')">
                                            <i class="fa fa-tty text-muted"></i> Marcación Automática</a></li>

                                    <li *ngIf="usuario.permisos <= _rol.MANAGER">
                                        <a href="javascript: void(0)" (click)="navigateTo('call-typing', 'view')">
                                                <i class="fa fa-check-square-o text-muted"></i> Tipificación de llamadas</a></li>            
                                </ul>
                            </div>
                            <div class="col-sm-6">
                                <ul class="multi-column-dropdown">
                                    <li class="dropdown-header">Servicios</li>
                                    
                                    <li *ngIf="usuario.permisos  <= _rol.MANAGER">
                                        <a href="javascript: void(0)" (click)="navigateTo('address-book', 'view')">
                                            <i class="fa fa-address-book text-muted"></i> Directorios de contacto</a></li>

                                    <li *ngIf="usuario.permisos  <= _rol.MANAGER">
                                        <a href="javascript: void(0)" (click)="navigateTo('polls', 'view')">
                                            <i class="fa fa-heart text-muted"></i> Encuestas de servicio</a></li>
                
                                    <li *ngIf="usuario.permisos === _rol.ADMIN">
                                        <!--<a [routerLink]="['/admin/callback']" routerLinkActive="router-link-active" >-->
                                        <a href="javascript: void(0)" (click)="navigateTo('click2call', 'view')">
                                            <i class="fa fa-refresh text-muted"></i> Callback - Click2Call</a></li>
                                    
                                    <li *ngIf="usuario.permisos <= _rol.MANAGER">
                                            <!--<a [routerLink]="['/admin/sms']" routerLinkActive="router-link-active" >-->
                                            <a href="javascript: void(0)" (click)="navigateTo('sms', 'view')">
                                                <i class="fa fa-envelope-open-o text-muted"></i> Envío de SMS</a></li>

                                    <li class="divider"></li>
                                    <li class="dropdown-header" *ngIf="usuario.permisos === _rol.ADMIN">Opciones del IPPBX</li>

                                    <li *ngIf="usuario.permisos  <= _rol.MANAGER">
                                        <a href="javascript: void(0)" (click)="navigateTo('apimanager', 'view')">
                                            <i class="fa fa-key text-muted"></i> API Manager</a></li>
                
                                    <li *ngIf="usuario.permisos === _rol.ADMIN">
                                        <a href="javascript: void(0)" (click)="navigateTo('ivr', 'view')">
                                            <i class="fa fa-filter text-muted" style="margin-right: 3px"></i> DID's & IVR</a></li>
                                            
                                    <li *ngIf="usuario.permisos === _rol.ADMIN">
                                        <a href="javascript: void(0)" (click)="navigateTo('trunks', 'view')">
                                            <i class="fa fa-plug text-muted"></i> Troncales</a></li>
                                            
                                    <li *ngIf="usuario.permisos === _rol.ADMIN" role="separator" class="divider"></li>
                                    <li *ngIf="usuario.permisos === _rol.ADMIN">
                                        <a href="javascript: void(0)" (click)="navigateTo('users', 'view')">
                                            <i class="fa fa-user-circle text-muted"></i> Usuarios & Permisos</a></li>
                                            
                                    <li *ngIf="usuario.permisos === _rol.ADMIN">
                                        <a href="javascript: void(0)" (click)="navigateTo('system', 'view')">
                                            <i class="fa fa-cog text-muted"></i> Sistema</a></li>
                    
                                </ul>
                            </div>
                            <div class="col-sm-12 text-right">
                                <ul class="multi-column-dropdown">
                                    <li class="divider"></li>
                                    <li>
                                        <a href="javascript: void(0)" (click)="cerrarSesion()">
                                        <i class="fa fa-sign-out text-muted"></i> Cerrar Sesión</a></li>
                                </ul>
                            </div>
                        </div>
                    </ul>
                </div>
            </li>
        </ul>
      </div><!--/.nav-collapse -->
</nav>
