<div class="row" *ngIf="sysInfo">
    <div class="col-md-5">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
        <div class="panel panel-primary">
            <div class="panel-heading">
                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#SysInfo" aria-expanded="true" aria-controls="SysInfo" style="color: white; text-decoration: none">
                    <h3 class="panel-title"><i class="fa fa-check-square-o"></i> Información del Sistema</h3>
                </a>
            </div>
            <div id="SysInfo" class="panel-collapse collapse in" role="tabpanel">
                <div class="panel-body" style="font-size: 13px; line-height: 20px">
                    <div class="col-xs-4 text-right">Hostname</div>
                    <div class="col-xs-8"><strong>{{sysInfo.hostname}}</strong></div>
                    <div class="col-xs-4 text-right">Tiempo en Línea</div>
                    <div class="col-xs-8"><strong>{{sysInfo.uptime}}</strong></div>
                    <div class="col-xs-4 text-right">Carga Promedio</div>
                    <div class="col-xs-8"><strong>{{sysInfo.loadAverage}}</strong></div>
                    <div class="col-xs-4 text-right">Interfaces de red</div>
                    <div class="col-xs-8" style="font-family: 'Verdana'; font-size: 12px">
                        <div style="margin-bottom: 10px" *ngFor="let net of sysInfo.network">
                            {{net.inet}} <strong>MAC: {{net.mac}}</strong><br>
                            IPv4: {{net.ip}} máscara {{net.mascara}}<br>
                            IPv6: {{net.ipv6}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel panel-primary">
            <div class="panel-heading">
                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#Storage" aria-expanded="true" aria-controls="Storage" style="color: white; text-decoration: none">
                    <h3 class="panel-title"><i class="fa fa-database"></i> Almacenamiento</h3>
                </a>
            </div>
            <div id="Storage" class="panel-collapse collapse" role="tabpanel">
                <div class="panel-body">
                    <div style="font-family: 'Verdana'; font-size: 12px" *ngFor="let dd of sysInfo.discos">
                         <div class="col-xs-4 text-right" style="margin-bottom: 10px">{{dd.Filesystem}}</div>
                        <div class="col-xs-8" style="margin-bottom: 10px">
                            Capacidad: {{dd.Size}}<br>
                            Disponible: {{dd.Avail}}<br>
                            En Uso: <strong>{{dd.Use}}</strong> {{dd.Used}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel panel-primary">
            <div class="panel-heading">
                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#Mtto" aria-expanded="true" aria-controls="Mtto" style="color: white; text-decoration: none">
                    <h3 class="panel-title"><i class="fa fa-wrench"></i> Operaciones y Mantenimiento</h3>
                </a>
            </div>
            <div id="Mtto" class="panel-collapse collapse" role="tabpanel">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-xs-5">
                            <label>Fecha y Hora</label>
                            <div class="" *ngIf="sysInfo.date">
                                <input type="text" class="form-control text-center" *ngIf="!editDate" value="{{sysInfo.date | date:'y-MM-dd HH:mm:ss'}}" (mousedown)="editarFecha($event)">
                                <input type="text" class="form-control text-center" *ngIf="editDate" [(ngModel)]="nuevaFecha">                            
                            </div>
                            <div class="text-right" style="margin-top: 10px">
                                <button class="btn btn-sm btn-primary" type="button" (click)="actualizarFecha()">Actualizar</button>
                            </div>
                        </div>
                        <div class="col-xs-7 text-center">
                            <button type="button" class="btn btn-sm btn-primary" (click)="mtto()"><i class="fa fa-wrench"></i> Ejecutar Mtto.</button>
                            <button type="button" class="btn btn-sm btn-primary" (click)="generarBackUP()"><i class="fa fa-recycle"></i> Generar Backup</button>
                            <hr>
                            <button type="button" class="btn btn-sm btn-warning" (click)="setOperacion('reiniciando')"><i class="fa fa-refresh"></i> Reiniciar</button>
                            <button type="button" class="btn btn-sm btn-danger" (click)="setOperacion('apagando')"><i class="fa fa-power-off"></i> Apagar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="panel panel-primary">
            <div class="panel-heading">
                <h3 class="panel-title"><i class="fa fa-globe"></i> Configuración de Red</h3>
            </div>
            <div class="panel-body">
                <p>Establece las direcciones IP de las diferentes interfaces de red en su planta telefónica IP.</p>
                <div class="input-group input-group-sm">
                    <select class="form-control" style="min-width: 60px" #inets
                            (change)="editarNet(inets.value)"
                            (mousedown)="cargarNets(sysInfo.network)">
                        <option value="">...</option>
                        <option value="{{eth.inet}}" *ngFor="let eth of nets">{{eth.inet}}</option>
                    </select>
                    <span class="input-group-addon" id="basic-addon1">IP</span>
                    <input type="text" class="form-control" placeholder="IPv4" name="ip" [(ngModel)]="netEdit.ip" aria-describedby="basic-addon1">
                    <span class="input-group-addon" id="basic-addon1">Mask</span>
                    <input type="text" class="form-control" placeholder="Máscara de red" name="mascara" [(ngModel)]="netEdit.mascara" aria-describedby="basic-addon1">                    
                </div>
                <div class="text-right" style="margin-top: 10px">
                    <button class="btn btn-sm btn-warning" type="button" (click)="guardarInet()"><i class="fa fa-check"></i> Actualizar interfaz</button>
                </div>
            </div>
        </div>
        <div class="bs-callout bs-callout-danger" style="margin-top: 15px">
            <form novalidate class="form-group form-group-sm" style="margin-bottom: 0" #fRutas="ngForm">
                <h5 class="text-danger"><i class="fa fa-random"></i> Enrutamiento Estático</h5>
                Creación de rutas estáticas para trafico especializado en SIP, IAX o puertos RTP.
                <div style="margin-top: 10px" class="row">
                    <form novalidate #f_rutas="ngForm">
                        <div style="margin-bottom: 10px" class="col-xs-6">
                            <label>DNS, host o net/mask</label>
                            <input type="text" class="form-control" name="destino" ngModel placeholder="mi.destino.com | 10.0.0.0/8" required>
                        </div>
                        <div style="margin-bottom: 10px" class="col-xs-6">
                            <label>Puerta de Enlace</label>
                            <input type="text" class="form-control" name="gw" ngModel placeholder="Gateway..." required>
                        </div>
                        <div style="margin-bottom: 10px" class="col-xs-12 text-right">
                            <button type="button" class="btn btn-sm btn-success" 
                                    [disabled]="f_rutas.invalid"
                                    (click)="generarRuta(f_rutas.value)"
                                    >
                                <i class="fa fa-check"></i> Generar Ruta
                            </button>
                        </div>
                    </form>
                </div>
            </form>
        </div>

        <table class="table table-striped" style="margin-bottom: 0; font-size: 12px; border: 1px solid #cccccc">
            <thead style="background-color: #cccccc; font-weight: 600">
                <tr>
                    <td>Destino</td>
                    <td>Mascara</td>
                    <td colspan="2" >Gateway</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let r of sysInfo.rutas">
                    <td>{{r.destino}}</td>
                    <td>{{r.mascara}}</td>
                    <td>{{r.gateway}}</td>
                    <td class="text-center"><a href="javascript:void(0)" (click)="eliminarRuta(r)"><i class="fa fa-trash"></i></a></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-3">
        <div class="panel panel-primary">
            <div class="panel-heading">
                <h3 class="panel-title"><i class="fa fa-fire"></i> Firewall</h3>
            </div>
            <div class="panel-body" style="padding-bottom: 0">
                <form novalidate class="form-group form-group-sm" style="margin-bottom: 0; font-size: 13px" #fFirewall="ngForm">
                    <p>Establece permisos para evitar accesos no deseados.</p>
                    <div style="margin-bottom: 10px">
                        <label>Descripción</label>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control" ngModel name="descr" placeholder="Mi nueva regla..." required >
                            <span class="input-group-btn">
                                {{allow}}
                                <input type="hidden" [ngModel]="fw.operacion" name="operacion">
                                <input type="hidden" [ngModel]="fw.allow" name="allow" required>
                                <button class="btn btn-success" type="button" title="Permitir" (click)="fw.allow = 1" [ngClass]="{'active': fw.allow === 1}">
                                    <i class="fa fa-thumbs-up"></i></button>
                                <button class="btn btn-success" type="button" title="Bloquear" (click)="fw.allow = 0" [ngClass]="{'active': fw.allow === 0}">
                                    <i class="fa fa-thumbs-down"></i></button>
                            </span>
                        </div><!-- /input-group -->
                    </div>
                    <div style="margin-bottom: 10px">
                        <label>Dirección IP o DNS</label>
                        <input type="text" class="form-control" name="ip" ngModel placeholder="Dirección IP o DNS" required >
                    </div>
                    <div style="margin-bottom: 15px" class="text-right">
                        <button type="button" class="btn btn-sm btn-warning" (click)="bloquarSIP()"><i class="fa fa-bell-slash"></i> Bloquear SIP</button>
                        <button type="button" class="btn btn-sm btn-success" [disabled]="fFirewall.invalid" (click)="crearRegla(fFirewall.value)"><i class="fa fa-check"></i> Agregar</button>
                    </div>
                </form>
            </div>
            <ul class="list-group" style="font-size: 12px">
                <li class="list-group-item" style="padding: 6px; padding-left: 10px"
                    [ngClass]="{'text-danger': f.politica === 'DROP', 'text-success': f.politica === 'ACCEPT'}" *ngFor="let f of sysInfo.firewall">
                    <a href="javascript:void(0)" (click)="eliminarRegla(f)"><i class="fa fa-trash" style="margin-right: 10px"></i></a> 
                    <i class="fa fa-arrow-circle-o-right"></i> 
                    <span *ngIf="f.politica === 'DROP'"><i class="fa fa-close"></i></span> 
                    <span *ngIf="f.politica === 'ACCEPT'"><i class="fa fa-check-square-o"></i></span> 
                    {{f.descripcion}} <strong>{{f.fuente}}<span *ngIf="f.dport">: {{f.dport}}({{f.protocolo}})</span></strong>
                </li>
            </ul>
        </div>
    </div>
</div>


<div class="modal fade" tabindex="-1" role="dialog" id="dialogConfirm">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Atención!</h4>
      </div>
      <div class="modal-body">
        Esta seguro de ejecutar esta operación?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-sm btn-danger" (click)="ejecutarOperacion()">Aceptar</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div class="modal fade" tabindex="-1" role="dialog" id="dialogProcess">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">      
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title"><i class="fa fa-info"></i> Información</h4>
      </div>
      <div class="modal-body text-justify">
          {{mensaje}}
      </div>  
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Aceptar</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->