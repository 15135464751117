import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HeaderService } from "./header.service";
import { Observable, of, throwError } from "rxjs";
import { MD5, SHA1 } from "crypto-js";

import { IDBResponse, EDBResponseType } from "../_interfaces/responses";
import { IAgentStatusReason, Agente } from "../_interfaces/agente";

import { LocalStorageService } from "angular-2-local-storage";
import { switchMap, map, catchError } from "rxjs/operators";
import { IPBXID } from "../../_environments/environment";

@Injectable()
export class AgenteService {
   constructor(
      private $http: HttpClient,
      private $header: HeaderService,
      private $localStorage: LocalStorageService
   ) {}

   setReason(reason: IAgentStatusReason): Observable<IDBResponse> {
      return this.$http.put<IDBResponse>(
         this.$header.getAPIurl() + "/agente/reason",
         reason,
         { headers: this.$header.getHttpHeaders() }
      );
   }

   getReason(idreason: string): Observable<IAgentStatusReason[]> {
      return this.$http.get<IAgentStatusReason[]>(
         this.$header.getAPIurl() + "/agente/reason/" + idreason,
         { headers: this.$header.getHttpHeaders() }
      );
   }

   updateAgente(data: {
      agente: Agente;
      update: boolean;
   }): Observable<IDBResponse> {
      let http$: Observable<IDBResponse>;
      if (data.update === undefined) {
         http$ = this.$http.delete<IDBResponse>(
            this.$header.getAPIurl() + "/agente/" + data.agente.idagente,
            { headers: this.$header.getHttpHeaders() }
         );
      } else if (data.update) {
         http$ = this.$http.post<IDBResponse>(
            this.$header.getAPIurl() + "/agente",
            data.agente,
            { headers: this.$header.getHttpHeaders() }
         );
      } else {
         http$ = this.$http.put<IDBResponse>(
            this.$header.getAPIurl() + "/agente",
            data.agente,
            { headers: this.$header.getHttpHeaders() }
         );
      }

      return http$;
   }

   setContactOnChannel(channel: string, idcontacto: string) {
      return of(true);
   }

   getAgente(idAgente: string): Observable<Agente> {
      return this.$http.get<Agente>(
         `${this.$header.getAPIurl()}/agente/${idAgente}`,
         {
            headers: this.$header.getHttpHeaders(),
         }
      );
   }

   getAgentes(): Observable<Agente[]> {
      return this.$http.get<Agente[]>(this.$header.getAPIurl() + "/agente", {
         headers: this.$header.getHttpHeaders(),
      });
   }

   colgarLlamada(request: { callid: string; channel: string; agente: string }) {
      return this.$http.post<Agente[]>(
         this.$header.getAPIurl() + "/agent-api/call/end",
         request,
         {
            headers: this.$header.getHttpHeaders(),
         }
      );
   }

   cambiarSkill(
      agenteid: string,
      modoAgente: string,
      skill: number,
      queue?: string
   ) {
      return this.$http.post<{}>(
         this.$header.getAPIurl() + "/agent-api/skill",
         {
            idagente: agenteid,
            modoAgente: modoAgente,
            skill: skill,
            queue: queue,
         },
         {
            headers: this.$header.getHttpHeaders(),
         }
      );
   }

   /**
    * @returns Observable<{token: string, usuario: string}>
    */
   getSesionAgente(): Observable<{ token: string; idagente: string }> {
      const sesion = this.$localStorage.get("kerberus_ipbx_token") as string;

      if (!!sesion) {
         const ipbxid =
            IPBXID ||
            window.location.href
               .substring(0, window.location.href.lastIndexOf(".kerberusipbx."))
               .replace("http://", "")
               .replace("https://", "");

         this.$header.setHeader({ nombre: "authorization", valor: sesion });
         this.$header.setHeader({ nombre: "ipbxid", valor: ipbxid });
         return this.$http.get<{ token: string; idagente: string }>(
            this.$header.getAPIurl() + "/agente/auth",
            { headers: this.$header.getHeaders() }
         );
      } else {
         return throwError({ ERR: "Sin logueo de agente" });
      }
   }

   /**
    * @description Autenticación de agentes
    * @returns Observable boolean true si la autenticación en exitosa
    */
   loginAgente(agente: {
      usuario: string;
      password: string;
   }): Observable<{ token: string }> {
      return of(agente).pipe(
         map((orgAgente) =>
            Object.assign({}, orgAgente, {
               password: MD5(SHA1(orgAgente.password).toString()).toString(),
            })
         ),
         switchMap((usuario) =>
            this.$http.post<IDBResponse>(
               this.$header.getAPIurl() + "/agente/auth",
               usuario
            )
         ),
         map((response) => {
            if (response.tipo === EDBResponseType.AUTH_OK) {
               this.$localStorage.set(
                  "kerberus_ipbx_token",
                  response.data as string
               );
               return { token: response.data as string };
            } else {
               return undefined;
            }
         }),
         catchError((err) => throwError(err.error))
      );
   }

   /**
    * @returns Observable string
    */
   cerrarSesion(): Observable<string> {
      return this.$http
         .delete<IDBResponse>(this.$header.getAPIurl() + "/agente/auth", {
            headers: this.$header.getHeaders(),
         })
         .pipe(
            map((res) => {
               if (res.tipo === EDBResponseType.OK) {
                  this.$localStorage.remove("kerberus_ipbx_token");
               }
               return res.data as string;
            })
         );
   }
}
