export enum ETroncalCodec {
    OPUS = 'opus',
    G729 = 'g729',
    GSM = 'gsm',
    G711 = 'ulaw',
    G711a = 'alaw'
}

export class Troncal {
    nombre: string;
    host: string;
    rtp = '';
    type = 'friend';
    codec?: string | {codec: string}[] = [] ;
    protocolo = '';
    usuario = '';
    password = '';
    dtmf = 'rfc2833';
    callerid = '';
    nat = 'auto_comedia';
    //
    prefijos?: TroncalPrefijo[] = [];
    
    constructor() {}
}

export class TroncalPrefijo {
    troncal: string;
    contexto: string;
    prioridad = 9;
    prefijo: string;
    
    constructor() {}
}
