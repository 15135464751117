import { Component, OnInit, OnDestroy } from '@angular/core';
import { DialerService } from 'src/app/_services/dialer.service';
import { SocketService } from 'src/app/_services/socket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ICampanaDialer, ICampanaStats, EEstadoCampanaDialer, IContactoReparto, EEstadoContacto, EEstadoContactoDialer, IContacto } from 'src/app/_interfaces/dialer';
import { Observable, of, empty, throwError, combineLatest, BehaviorSubject, merge, zip, from } from 'rxjs';
import { share, map, flatMap, defaultIfEmpty, filter, tap, catchError, debounceTime, finalize, shareReplay, groupBy, mergeMap, toArray, withLatestFrom, startWith, last, delay } from 'rxjs/operators';
import { PaginationInstance } from 'ngx-pagination';
import { UsuarioService } from 'src/app/_services/usuario.service';

@Component({
  selector: 'app-campain-view',
  templateUrl: './campain-view.component.html',
  styleUrls: ['./campain-view.component.css']
})
export class CampainViewComponent implements OnInit, OnDestroy {

    inProgress = false;
    inProgressReparto = {
        msj: false,
        progress: false,
        err: false
    };
    campana$: Observable<ICampanaDialer>;
    contactos$: Observable<IContactoReparto[]>;
    currContactos$ = new BehaviorSubject<IContactoReparto[]>([]);
    seleccionContactos: IContactoReparto[] = [];
    
    stats$ = new BehaviorSubject<ICampanaStats>(undefined);
    config: PaginationInstance = {
        id: 'custom',
        itemsPerPage: 15,
        currentPage: 1
    };

    get enCurso() { return EEstadoContacto.EN_CURSO } 
    get enAudio() { return EEstadoContacto.EN_AUDIO } 
    get enAtencion() { return EEstadoContacto.EN_LLAMADA } 

    get repartoEnCurso() { return EEstadoContactoDialer.EN_CURSO }
    get repartoEnEspera() { return EEstadoContactoDialer.EN_ESPERA }
    get repartoReintentar() { return EEstadoContactoDialer.REINTENTAR }
    get repartoFallido() { return EEstadoContactoDialer.FALLIDO }
    get repartoFinalizado() { return EEstadoContactoDialer.FINALIZADO }

    get pausedEnum() { return EEstadoCampanaDialer.EN_PAUSA }
    get stoppedEnum() { return EEstadoCampanaDialer.DETENIDA }
    get progressEnum() { return EEstadoCampanaDialer.EN_CURSO }

    get isRootUser() { return this.$usuario.usuario.usuario === 'root' }

    constructor(
        private $router: Router,
        private $route: ActivatedRoute,
        private $dialer: DialerService, 
        private $monitor: SocketService, 
        private $usuario: UsuarioService
    ) { }

    ngOnInit() {

        const { idcampana } = this.$route.snapshot.params;
        this.campana$ = this.$dialer.getCampana$(idcampana).pipe(
            flatMap(campana => !!campana ? of(campana) : throwError({})),
            tap(() => this.$monitor.setRooms([`dialer-${idcampana}`, `reparto-${idcampana}`])),
            tap(campana => this.stats$.next(this.$dialer.initStats(campana))),
            catchError(err => {
                this.$router.navigateByUrl('dashboard/(view:dialer)');
                return of(undefined);
            }),
            shareReplay(1)
        );

        this.contactos$ = this.$dialer.cargarReparto(idcampana).pipe(
            // iniciar la escucha de nuevos eventos.
            flatMap(repartos => this.$monitor.getDialerProgress(repartos)),
            // Agruparlos por estado.
            flatMap(repartos => from(repartos).pipe(
                groupBy(reparto => reparto.estado),
                mergeMap(agrupado => zip( of(agrupado.key), agrupado.pipe(toArray()) )),
                map(([ estado, repartos ]) => ({ estado, repartos })),
                toArray()
            )),
            map(data => {
                // Organizar por estados.
                const enCurso = data.find(r => r.estado === EEstadoContactoDialer.EN_CURSO) || { repartos: [] };
                const reIntento = data.find(r => r.estado === EEstadoContactoDialer.REINTENTAR) || { repartos: [] };
                const enEspera = data.find(r => r.estado === EEstadoContactoDialer.EN_ESPERA) || { repartos: [] };
                const fallidos = data.find(r => r.estado === EEstadoContactoDialer.FALLIDO) || { repartos: [] };
                const finalizados = data.find(r => r.estado === EEstadoContactoDialer.FINALIZADO) || { repartos: [] };

                // Stats
                const newStats = {
                    enCurso: enCurso.repartos.length,
                    reIntento: reIntento.repartos.length,
                    enEspera: enEspera.repartos.length,
                    atendidas: finalizados.repartos.length,
                    fallidas: fallidos.repartos.length,
                };
                this.stats$.next({ ...this.stats$.value, ...newStats });
                return [ ...enCurso.repartos, ...reIntento.repartos, ...enEspera.repartos, ...fallidos.repartos, ...finalizados.repartos ];
            }),
            share()
        );
        
        /* this.stats$ = this.campana$.pipe(
            map(campana => this.$dialer.initStats(campana)),
            flatMap(stat => this.$monitor.getDialerStats$().pipe(
                map(s => s.find(s => s.idcampana === stat.idcampana)),
                filter(stream => !!stream),
                map(streamedStats => ({...stat, ...streamedStats})),
                defaultIfEmpty(stat)
            ))
        );*/
    }

    ngOnDestroy() {
        const { idcampana } = this.$route.snapshot.params;
        this.$monitor.exitRooms([`dialer-${idcampana}`, `reparto-${idcampana}`]);
    }

    editarCampana(idcampana: string) {
        this.$router.navigateByUrl(`dashboard/(view:dialer/setup/${idcampana})`);
    }

    cambiarEstado(campana: ICampanaDialer, estado: EEstadoCampanaDialer) {
        of({ ...campana, estado: estado}).pipe(
            debounceTime(200),
            tap(() => this.inProgress = true),
            flatMap(campana => this.$dialer.guardarCampana$(campana)),
            // darle 3 segundos a las campañas para actualizar si la campaña es detenidao pausada.
            delay(estado !== EEstadoCampanaDialer.EN_CURSO ? 5000 : 5),
            tap(campana => this.campana$ = of(campana).pipe(share())),
            finalize(() => this.inProgress = false)
        ).subscribe()
    }

    relanzar(repartos: IContactoReparto[], estado?: EEstadoContactoDialer, agenteid?: string) {
        const predicado = !estado ? 
            (contacto: IContactoReparto) => !!contacto && (contacto.estado !== EEstadoContactoDialer.EN_ESPERA) : 
            (contacto: IContactoReparto) => !!contacto && contacto.estado === estado;
        
        this.inProgressReparto = {
            err: false,
            msj: false,
            progress: true
        };
        from(repartos).pipe(
            filter(predicado),
            map(reparto => ({
                ...reparto,
                estado: EEstadoContactoDialer.EN_ESPERA,
                contacto: reparto.contacto.map(c => ({...c, estado: EEstadoContacto.EN_ESPERA}))
            })),
            toArray(),
            defaultIfEmpty([]),
            flatMap(repartos => repartos.length > 0 ? of(repartos) : throwError(repartos.length)),

            withLatestFrom(this.campana$),
            map(([ repartos, campana ]) => ({ ...campana, reparto: repartos } as ICampanaDialer)),
            flatMap(campana => this.$dialer.guardarCampana$(campana, true)),
        ).subscribe(
            res => {
                this.inProgressReparto = { err: false, progress: false, msj: true }
                console.log(res);
            },
            err => {
                this.inProgressReparto = {
                    err: true,
                    msj: false,
                    progress: false
                }
            }
        );
    }

    seleccionar(contacto: IContactoReparto, seleccion: boolean) {
        if (seleccion && !this.seleccionContactos.find(c => c.id === contacto.id)) {
            this.seleccionContactos.push(contacto);
        } else if (!seleccion && !!this.seleccionContactos.find(c => c.id === contacto.id)) {
            this.seleccionContactos = [ ...this.seleccionContactos.filter(c => c.id !== contacto.id) ];
        }
    }

    relanzarSeleccion(estado: EEstadoContactoDialer) {
        this.inProgressReparto = {
            err: false,
            msj: false,
            progress: true
        };
        from(this.seleccionContactos).pipe(
            map(reparto => ({
                ...reparto,
                estado: estado, // Nuevo estado
                contacto: reparto.contacto.map(c => ({
                    ...c, 
                    estado: estado === EEstadoContactoDialer.FALLIDO ? EEstadoContacto.FALLIDO : EEstadoContacto.FINALIZADO 
                }))
            })),
            toArray(),
            withLatestFrom(this.campana$),
            map(([ repartos, campana ]) => ({ ...campana, reparto: repartos } as ICampanaDialer)),
            flatMap(campana => this.$dialer.guardarCampana$(campana, true)),
        ).subscribe(
            () => {
                this.seleccionContactos = [];
                this.inProgressReparto = { err: false, progress: false, msj: true };
            },
            err => {
                console.error(err);
                this.inProgressReparto = { err: true, progress: false, msj: false };
            }
        )
    }

}
