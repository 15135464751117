<form autocomplete="off" novalidate class="form-group" style="margin-bottom: 0" #f="ngForm">
      <div class="row">
          <div class="col-sm-4" style="margin-bottom: 15px">
              <div class="input-group" ngModelGroup="fecha">
                  <div class="input-group-btn">
                    <button type="button" class="btn btn-success" style="min-width: 100px">Fecha</button>
                  </div>
                  <input type="text" ngDatePicker ngModel name="inicio" required>
                  <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                  <input type="text" ngDatePicker ngModel name="fin" required>
              </div>
          </div>
          <div class="col-sm-4" style="margin-bottom: 15px">
              <div class="input-group" ngModelGroup="fuente">
                  <div class="input-group-btn">
                    <button type="button" class="btn btn-success" style="min-width: 100px">Fuente</button>
                  </div>
                  <select class="form-control" [(ngModel)]="queryParams.fuente" name="operador" style="min-width: 120px">
                      <option value="{{o[o[indexOp]]}}" *ngFor="let indexOp of [0,1,2,3]">{{o[indexOp] | stringReplace:'_':' '}}</option>
                  </select>
                  <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                  <input type="text" ngModel name="valor" class="form-control" placeholder="3303030...">
              </div>
          </div>
          <div class="col-sm-4" style="margin-bottom: 15px">
            <div class="input-group"  ngModelGroup="destino">
                <div class="input-group-btn">
                    <button type="button" class="btn btn-success" style="min-width: 100px">Destino</button>
                </div>
                <select class="form-control" [(ngModel)]="queryParams.destino" name="operador" style="min-width: 120px">
                      <option value="{{o[o[indexOp]]}}" *ngFor="let indexOp of [0,1,2,3]">{{o[indexOp] | stringReplace:'_':' '}}</option>
                </select>
                <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                <input type="text" ngModel name="valor" class="form-control" placeholder="3303030...">
              </div>
          </div>
          <div class="col-sm-4" style="margin-bottom: 15px">
              <div class="input-group" ngModelGroup="contexto">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-success" style="min-width: 100px">Contexto</button>
                </div>
                <select class="form-control" [(ngModel)]="queryParams.contexto" name="operador" style="min-width: 120px">
                    <option value="{{o[o[indexOp]]}}" *ngFor="let indexOp of [0,1,2,3]">{{o[indexOp] | stringReplace:'_':' '}}</option>
                </select>
                <span class="input-group-addon"><i class="fa fa-code"></i></span>
                <input type="text" ngModel name="valor" class="form-control" placeholder="Entrante...">
              </div>
          </div>
          <div class="col-sm-4" style="margin-bottom: 15px">
              <div class="input-group">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-success" style="min-width: 100px">Disposición</button>
                </div>              
                <select class="form-control" ngModel name="disposicion" style="min-width: 120px">
                    <option value="{{d.TODAS}}">{{d[d.TODAS]}}</option>
                    <option value="{{d.CONTESTADA}}">{{d[d.CONTESTADA]}}</option>
                    <option value="{{d.OCUPADO}}">{{d[d.OCUPADO]}}</option>
                    <option value="{{d.FALLIDA}}">{{d[d.FALLIDA]}}</option>
                    <option value="{{d.NO_CONTESTADA}}">{{d[d.NO_CONTESTADA] | stringReplace:'_':' '}}</option>
                </select>
              </div>
          </div>
          <div class="col-sm-4 text-right" style="margin-bottom: 15px">
              <!--<button class="btn btn-default" type="button">Limpiar</button>-->
              <button class="btn btn-primary" type="button" (click)="ejecutarBusqueda(f.value, _q.LIST)">Ejecutar Búsqueda</button>
          </div>
      </div>
</form>

<div class="table-responsive">
    <table class="table table-striped" style="margin-bottom: 0; font-size: 12px; border: 1px solid #e2e2e2">
        <thead style="background-color: #0d1e40; color: white">
            <tr>
                <td colspan="2" class="text-center">
                    <a href="javascript:void(0)" (click)="sortBy('calldate')"> 
                        <i class="fa fa-sort-alpha-asc"></i>
                    </a> FECHA
                </td>            
                <td><a href="javascript:void(0)" (click)="sortBy('clid')"> 
                        <i class="fa fa-sort-alpha-asc"></i>
                    </a> CALLERID</td>
                <td class="text-center"><a href="javascript:void(0)" (click)="sortBy('src')"> 
                        <i class="fa fa-sort-alpha-asc"></i>
                    </a> FUENTE</td>
                <td class="text-center"><a href="javascript:void(0)" (click)="sortBy('channel')"> 
                        <i class="fa fa-sort-alpha-asc"></i>
                    </a> CANAL FUENTE</td>
                <td class="text-center"><a href="javascript:void(0)" (click)="sortBy('dst')"> 
                        <i class="fa fa-sort-alpha-asc"></i>
                    </a> DESTINO</td>
                <td class="text-center"><a href="javascript:void(0)" (click)="sortBy('dstchannel')"> 
                        <i class="fa fa-sort-alpha-asc"></i>
                    </a> CANAL DESTINO</td>
                <td class="text-center"><a href="javascript:void(0)" (click)="sortBy('disposition')"> 
                        <i class="fa fa-sort-alpha-asc"></i>
                    </a> DISPOSICION</td>
                <td><a href="javascript:void(0)" (click)="sortBy('dcontext')"> 
                        <i class="fa fa-sort-alpha-asc"></i>
                    </a> CONTEXTO</td>
                <td class="text-center"><a href="javascript:void(0)" (click)="sortBy('duration')"> 
                        <i class="fa fa-sort-alpha-asc"></i>
                    </a> DURACIÓN</td>
                <td class="text-center"><a href="javascript:void(0)" (click)="sortBy('billsec')"> 
                        <i class="fa fa-sort-alpha-asc"></i>
                    </a> BILLSEC</td>
                <td class="text-center">AUDIO</td>
            </tr>
        </thead>
        <tbody *ngIf="llamadas && llamadas.length > 0">
            <tr *ngFor="let llamada of llamadas | paginate: config; let i=index; trackBy:trackByFn">
                <td class="text-center">
                    <span class="label label-info">
                    {{(i+1 + ((p.getCurrent()-1)*12))}}.
                    </span>
                </td>
                <td>{{llamada?.calldate | toTimeFormat:'YYYY-MM-DD HH:mm:ss'}}</td>
                <td>{{llamada?.clid}}</td>
                <td class="text-right">{{llamada?.src}}</td>
                <td class="text-center">{{llamada?.channel | channelFormat}}</td>
                <td class="text-right">{{llamada?.dst}}</td>
                <td class="text-center">{{llamada?.dstchannel | channelFormat}}</td>
                <td class="text-center">{{d[llamada?.disposition]}}</td>
                <td>{{llamada?.dcontext}}</td>
                <td class="text-center">{{llamada?.duration | toTime | date:'HH:mm:ss'}}</td>
                <td class="text-center">{{llamada?.billsec | toTime | date:'HH:mm:ss'}}</td>
                <td class="text-center">
                    <button class="btn btn-xs btn-info"
                    *ngIf="llamada.billsec && llamada.billsec > 0 && llamada.userfield !== ''"
                    (click)="escuchar(llamada)"><i class="fa fa-music"></i></button>
                    <span class="text-muted" *ngIf="!llamada.billsec || llamada.billsec === 0 || llamada.userfield === ''">sin-audio</span>
                </td>
            </tr>
            <tr>
                <td colspan="12" class="text-right">
                    <pagination-template #p="paginationApi"
                        [id]="config.id"
                        (pageChange)="config.currentPage = $event">

                        <div class="btn-group">
                            <button type="button" class="btn btn-default" 
                                [disabled]="p.isFirstPage()"
                                (click)="p.previous()">
                                <i class="fa fa-arrow-circle-o-left"></i>
                            </button>

                            <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                                [disabled]="p.getCurrent() === page.value"
                                [ngClass]="{'active': p.getCurrent() === page.value}"
                                (click)="p.setCurrent(page.value)">
                                {{ page.label }}
                            </button>
                            
                            <button type="button" class="btn btn-default" 
                                [disabled]="p.isLastPage()"
                                (click)="p.next()">
                                <i class="fa fa-arrow-circle-o-right"></i>
                            </button>
                        </div>
                    </pagination-template>
                </td>
            </tr>
        </tbody>
        <!--<tbody *ngIf="llamadas && llamadas.length > 0">
            <tr *ngFor="let llamada of llamadas | paginate: config; let i=index; trackBy:trackByFn">
                <td class="text-center">
                    <span class="label label-info">
                    {{(i+1 + ((p.getCurrent()-1)*12))}}.
                    </span>
                </td>
                <td>{{llamada?.calldate | toTimeFormat:'YYYY-MM-DD HH:mm:ss'}}</td>
                <td>{{llamada?.src}}</td>
                <td>{{llamada?.clid}}</td>
                <td class="text-right">{{llamada?.dst}}</td>
                <td class="text-center">{{d[llamada?.disposition]}}</td>
                <td>{{llamada?.dcontext}}</td>
                <td class="text-center">{{llamada?.duration | toTime | date:'HH:mm:ss'}}</td>
                <td class="text-center">{{llamada?.billsec | toTime | date:'HH:mm:ss'}}</td>
                <td class="text-center">
                    <button class="btn btn-xs btn-info"
                    *ngIf="llamada.billsec && llamada.billsec > 0 && llamada.userfield !== ''"
                    (click)="escuchar(llamada)"><i class="fa fa-music"></i></button>
                    <span class="text-muted" *ngIf="!llamada.billsec || llamada.billsec === 0 || llamada.userfield === ''">sin-audio</span>
                </td>
            </tr>
            <tr>
                <td colspan="10" class="text-right">
                    <pagination-template #p="paginationApi"
                        [id]="config.id"
                        (pageChange)="config.currentPage = $event">

                        <div class="btn-group">
                            <button type="button" class="btn btn-default" 
                                [disabled]="p.isFirstPage()"
                                (click)="p.previous()">
                                <i class="fa fa-arrow-circle-o-left"></i>
                            </button>

                            <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                                [disabled]="p.getCurrent() === page.value"
                                [ngClass]="{'active': p.getCurrent() === page.value}"
                                (click)="p.setCurrent(page.value)">
                                {{ page.label }}
                            </button>
                            
                            <button type="button" class="btn btn-default" 
                                [disabled]="p.isLastPage()"
                                (click)="p.next()">
                                <i class="fa fa-arrow-circle-o-right"></i>
                            </button>
                        </div>
                    </pagination-template>
                </td>
            </tr>
        </tbody>-->
        <tbody *ngIf="!llamadas || llamadas.length === 0">
            <tr>
                <td colspan="12">
                    <h5 class="text-muted">No existen registros...</h5>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<hr>
<div class="panel panel-primary">
  <div class="panel-heading">
      <strong><i class="fa fa-bar-chart"></i>  Estadísticas de la Consulta</strong>
  </div>
  <div class="panel-body">
      <div class="row">
          <div class="col-md-3">
              <ul class="list-group">
                  <li class="list-group-item">Total Llamadas <span class="badge">{{llamadas?.length || 0}}</span></li>
                <li class="list-group-item">Llamadas Contestadas <span class="badge">{{(llamadas | filtroPorTipo:d.CONTESTADA:true)?.length || 0}}</span></li>
                <li class="list-group-item">Llamadas No Contestadas <span class="badge">{{(llamadas | filtroPorTipo:d.NO_CONTESTADA:true)?.length || 0}}</span></li>
                <li class="list-group-item">Llamadas Fallidas <span class="badge">{{(llamadas | filtroPorTipo:d.FALLIDA:true)?.length || 0}}</span></li>
            </ul>
          </div>
          <div class="col-md-5">
              <ul class="list-group">
                  <li class="list-group-item">
                      Tiempo Total <span class="badge">{{((llamadas | tTotal:true) || 0) | toTime | date:'HH:mm:ss'}}</span>
                  </li>
                  <li class="list-group-item">
                      Promedio de Llamada <span class="badge">{{((llamadas | tPromedio:true) || 0) | toTime | date:'HH:mm:ss'}}</span>
                  </li>
              </ul>
          </div>
          <div class="col-md-4">
              Puede descaragar la información en CSV para ser filtrada en MSExcel&trade;
              <hr>
              <div class="text-center">
                 <!--<button type="button" 
                        (click)="ejecutarBusqueda(f.value, _q.CVSFILE)"
                        [disabled]="!llamadas || llamadas?.length === 0" class="btn btn-warning">Descargar en *.CSV
                </button>-->
                <button type="button" 
                        (click)="descargarCalllog(llamadas)"
                        [disabled]="!llamadas || llamadas?.length === 0" class="btn btn-warning">Descargar en *.CSV
                </button>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" id="modalAudioLog">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h5 style="font-weight: 500" class="modal-title"><i class="fa fa-music"></i> De {{llamada?.src}} <i class="fa fa-arrow-circle-o-right"></i> {{llamada?.dst}}</h5>
      </div>
      <div class="modal-body" *ngIf="llamada">
          <audio-player [src]="llamada.audio"></audio-player>
      </div>      
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->