<div class="row" *ngIf="campana$ | async as campana; else loading">
    <div class="col-md-3">
        <div class="bs-callout bs-callout-success">
            <h4 class="text-success">Campaña {{campana?.nombre || 'nueva'}}.</h4>
            Administración de opciones de campañas de marcación.
        </div>
        <ul class="nav nav-pills nav-stacked" id="tabDialer">
            <li role="presentation" class="active">
                <a href="#basic" aria-controls="basic" role="tab" data-toggle="tab">Básico</a></li>
            <li role="presentation" [ngClass]="{disabled: !canActivateReparto}">
                <a href="#addressbook" *ngIf="canActivateReparto" aria-controls="addressbook" role="tab" data-toggle="tab">
                    Contactos y numeración</a>
                <a href="javascript:void(0)" *ngIf="!canActivateReparto">
                    Contactos y numeración</a>
            </li>
            <li role="presentation">
                <a href="#outgoing" aria-controls="outgoing" role="tab" data-toggle="tab">Enrutamiento de llamadas</a></li>
            <li role="presentation">
                <a href="#advanced" aria-controls="advanced" role="tab" data-toggle="tab">Avanzado</a></li>
        </ul>
    </div>
    <div class="col-md-9" style="color: #585858;">
        <form novalidate autocomplete="off" [formGroup]="formDialer" (ngSubmit)="guardarCampana(formDialer.value, !campana.nombre)">
            <input type="hidden" formControlName="idcampana">
            <input type="hidden" formControlName="estado">
            <div class="tab-content row" style="padding-top: 20px; color: #585858;">
                <div role="tabpanel" class="tab-pane fade in active col-md-offset-1 col-md-10" id="basic" [formGroup]="basico">
                    <h4><i class="fa fa-check-square-o"></i> Configuraciones básicas.</h4>
                    <hr>
                    <div class="form-group">
                        <label>Nombre de la campaña.</label>
                        <div class="input-group col-md-6" style="margin-bottom: 7px;">
                            <input type="text" formControlName="nombre" class="form-control">
                            <div class="input-group-addon">
                                <span><i class="fa fa-id-badge"></i></span>
                            </div>
                        </div>
                        <small>Ingrese un nombre único para su campaña.</small>
                    </div>
                    <hr>
                    <div class="form-group">
                        <label>Tipo de marcación.</label>
                        <div class="input-group col-md-6" style="margin-bottom: 10px;">
                            <select class="form-control" formControlName="tipo">
                                <option value="preview">Vista previa</option>
                                <option value="progresivo">Progresivo</option>
                                <option value="predictivo">Predictivo</option>
                                <option value="predictivo-press">Predictivo - press1</option>
                            </select>
                        </div>
                        <div class="small">
                            Seleccione un tipo de marcación automático que se ajuste a sus necesidades.
                            <ul style="margin-top: 7px;">
                                <li><strong>Vista previa :</strong> Marcación manual con reparto de contactos a agente, también conocido como <i>marcación con vista previa</i>.</li>
                                <li><strong>Progresivo :</strong> Marcación automática con reparto de contactos a agente.</li>
                                <li><strong>Predictivo :</strong> Marcación automática de acuerdo a la disponiblidad de agentes.</li>
                                <li><strong>Predictivo - press1 :</strong> Marcación automática con paso a cola de llamadas en caso de que el contacto presione alguna tecla.</li>
                            </ul>
                        </div>
                    </div>
                    <hr>
                    <div class="form-group">
                        <label>Directorio de contactos.</label>
                        <div class="input-group col-md-6" style="margin-bottom: 10px;">
                            <select class="form-control" (change)="setContactos(directorio.value)" 
                            #directorio formControlName="directorio" *ngIf="directorios$ | async as directorios">
                                <option value="">...</option>
                                <option [value]="directorio.iddb" *ngFor="let directorio of directorios">{{directorio.nombre}}</option>
                            </select>
                        </div>
                        <small>Seleccione el directorio de contactos objetivo de esta campaña; si aun no ha creado uno, visite 
                            <a [routerLink]="['/dashboard', { outlets: { view: 'address-book' } }]">
                                <strong>el módulo de directorios.</strong></a> 
                        </small>
                        <div class="alert alert-info small" *ngIf="!canActivateReparto" style="margin-top: 10px;">
                            <i class="fa fa-info-circle" style="margin-right: 7px;"></i> <strong>Contactos y numeración.</strong><br>
                            El reparto y creación de numeración se habilitará cuando seleccione un directorio y una cola de llamadas.
                        </div>
                    </div>
                    <hr>
                    <div class="form-group">
                        <label>Destino / Cola de llamada.</label>
                        <div class="input-group col-md-6" style="margin-bottom: 10px;">
                            <select class="form-control" (change)="setAgentes(cola.value)" #cola formControlName="cola" *ngIf="queues$ | async as queues">
                                <option value="">...</option>
                                <option [value]="queue.nombre" *ngFor="let queue of queues">{{queue.nombre}}</option>
                            </select>
                        </div>
                        <div *ngIf="canActivateReparto">
                            <a href="javascript:void(0)" (click)="vistaAddressBook()">
                                <strong>
                                <i class="fa fa-random"></i> Configurar contactos y numeración.
                                </strong>
                            </a>
                        </div>
                    </div>
                    <hr>
                    <div class="form-group">
                        <label>Eliminar Campaña</label>
                        <br>
                        <small>
                            Si esta campaña ya no es últil, puede eliminarla.
                            <div class="alert alert-warning" style="margin-top: 5px;">
                                <i class="fa fa-warning text-danger"></i> Esta operación no puede deshacerse.
                            </div>
                        </small>
                        <button type="button" style="margin-bottom: 7px;" [disabled]="!campana.nombre || !isPrivileged || inProgress.progress"
                        class="btn btn-default" (click)="eliminarCampana(campana)">
                            <i class="fa" 
                                [ngClass]="{
                                    'fa-spinner fa-spin': inProgress.action === 'delete',
                                    'fa-trash-o' : !inProgress.progress
                                }"></i> Eliminar campaña
                        </button>
                    </div>
                    <div class="text-right" *ngIf="campana.nombre === ''">
                        <hr>
                        <button type="button" class="btn btn-default" (click)="cancelar()" style="margin-right: 10px;">Cancelar</button>
                        <button type="button" class="btn btn-info" [disabled]="!basico.valid" (click)="vistaAddressBook()">Siguiente <i class="fa fa-arrow-right"></i></button>
                    </div>
                </div>
                <div role="tabpanel" class="tab-pane fade col-md-12" id="addressbook">
                    <h4><i class="fa fa-address-card-o"></i> Contactos y numeración.</h4>
                    <hr>
                    <app-reparto 
                        [cola]="cola.value"
                        [dirID]="directorio.value"
                        [tipoDialer]="tipo.value"
                        [agentes]="agentes$ | async" 
                        [contactos]="contactos$ | async" 
                        formControlName="reparto"></app-reparto>
                    <div class="text-right" *ngIf="campana.nombre === ''">
                        <hr>
                        <button type="button" class="btn btn-default" (click)="cancelar()" style="margin-right: 10px;">Cancelar</button>
                        <button type="button" class="btn btn-info" [disabled]="!reparto.valid" (click)="vistaEnrutamiento()">Siguiente <i class="fa fa-arrow-right"></i></button>
                    </div>
                </div>
                <div role="tabpanel" class="tab-pane fade col-md-offset-1 col-md-10" id="outgoing" [formGroup]="ruta">
                    <h4><i class="fa fa-plug"></i> Datos del proveedor o troncal para realizar llamadas</h4>
                    <hr>
                    <div class="form-group" style="margin-top: 15px;">
                        <label>Troncal / Proveedor</label>
                        <div class="input-group col-md-6" style="margin-bottom: 10px;">
                            <select class="form-control" formControlName="troncal" *ngIf="troncales$ | async as troncales">
                                <option value="">...</option>
                                <option [value]="troncal.protocolo + '/' + troncal.nombre" *ngFor="let troncal of troncales">{{troncal.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <hr>
                    <div class="form-group">
                        <label>Prefijo</label>
                        <div class="input-group col-md-6" style="margin-bottom: 10px;">
                            <input type="tel" min="1" formControlName="prefijo" class="form-control" placeholder="Ej: +1">
                            <div class="input-group-addon">
                                <span><i class="fa fa-globe"></i></span>
                            </div>
                        </div>
                        <div class="small" style="margin-top: 10px;">
                            Si su proveedor requiere agregar un prefijo específico para realizar las llamadas, en este campo puede determinarlo.
                        </div>
                    </div>
                    <hr>
                    <div class="form-group" style="margin-top: 15px;">
                        <label>Tiempo de expiración</label>
                        <div class="input-group col-md-6" style="margin-bottom: 10px;">
                            <input type="number" formControlName="timeout" class="form-control">
                            <div class="input-group-addon">
                                <span><i class="fa fa-hourglass-end"></i></span>
                            </div>
                        </div>
                        <small>Tiempo máximo de espera antes de determinar que la llamada ha sido fallida, si requiere ajustar este parámetro, realice una llamada manual y determine el tiempo que le toma a su proveedor conectar la llamada al menos hasta el tercer timbre.</small>
                    </div>
                    <div class="text-right" *ngIf="campana.nombre === ''">
                        <hr>
                        <app-response-message [inProgress]="inProgress"></app-response-message>
                        <button class="btn btn-default" (click)="cancelar()" style="margin-right: 10px;">Cancelar</button>
                        <button type="submit" 
                        [disabled]="!formDialer.valid || inProgress.progress"
                        class="btn btn-primary">
                        <i class="fa fa-spinner fa-spin" *ngIf="inProgress.progress"></i> Crear campaña</button>
                    </div>
                </div>
                <div role="tabpanel" class="tab-pane fade col-md-offset-1 col-md-10" id="advanced">
                    <h4><i class="fa fa-rocket"></i> Configuración Avanzada.</h4>
                    <hr>
                    <div class="form-group">
                        <label>Detección de máquina contestadora (AMD).</label>
                        <div style="margin-bottom: 10px; margin-top: 10px;">
                            <div style="display: inline-block;"> 
                                <ui-toggleV1 formControlName="amd"></ui-toggleV1> 
                            </div>
                            <div style="position: relative; display: inline; top: -7px; margin-left: 20px;"> Habilitar</div>
                        </div>
                        <small>
                            La detección de máquina colgará las llamadas antes de enviarla al agente; esta opción solo tiene efecto en campañas de <strong>Marcador Predictivo</strong>.
                        </small>
                    </div>
                    <hr>
                    <div class="form-group">
                        <label>Intentos de marcación por contacto.</label>
                        <div class="input-group col-md-6" style="margin-bottom: 10px;">
                        <select class="form-control" formControlName="intentos" #intentos>
                            <option [value]="(i-1)" *ngFor="let i of [1,2,3,4]">{{i}}</option>
                        </select>
                        </div>
                        <small>
                            Si el contacto posee múltiples números, hará al menos 1 intento por cada número.
                            {{intentos.value}}
                        </small>
                    </div>
                    <div class="form-group" *ngIf="intentos.value > 0">
                        <hr>
                        <label>Tiempo en segundos entre intentos.</label>
                        <div class="input-group col-md-6">
                            <input type="number" min="1" formControlName="tiempoIntento" class="form-control">
                            <div class="input-group-addon">
                                <span id="my-addon"><i class="fa fa-hourglass-half"></i></span>
                            </div>
                        </div>
                        <div style="margin-top: 5px;" class="text-danger small" 
                            *ngIf="tiempoIntento.invalid && (tiempoIntento.dirty || tiempoIntento.touched) && tiempoIntento.errors.min">
                            * Tiempo mínimo espera no debe ser menor a 5 Segundos.
                        </div>
                        <div class="alert alert-info small" style="margin-top: 10px;">
                            <i class="fa fa-info-circle"></i> Esta función solo tiene efecto en marcaciones automáticas, solo tiene efecto por número de contacto y no entre contactos.
                        </div>
                    </div>
                    <hr>
                    <div class="form-group">
                        <label>Tasa de marcación.</label>
                        <div class="input-group col-md-6">
                            <input type="number" step="0.2" formControlName="tasa" class="form-control">
                            <div class="input-group-addon">
                                <span id="my-addon"><i class="fa fa-flash"></i></span>
                            </div>
                        </div>
                        <div class="small" style="padding-top: 10px;">
                            <div class="alert alert-warning">
                                <i class="fa fa-warning"></i> Verifique con su operador cuantas llamadas simultáneas puede realizar.
                            </div>
                            Cantidad de llamadas que se realizaran simultáneamente de acuerdo a disponiblidad de agentes.
                            <ul style="margin-top: 7px;">
                                <li>= 0 : Se lanzarán tantas llamadas como agentes disponibles libres hayan.</li>
                                <li>> 0 : Se lanzarán la cantidad agente disponibles multiplicado por este factor. ( ag.libres * tasa )</li>
                            </ul>
                        </div>
                    </div>
                    <hr>
                    <div class="form-group">
                        <label>Audio de campaña.</label>
                        <input type="file" style="display: none;" #fileUpload name="fileUpload" multiple="multiple" accept="audio/*">
                        <div class="input-group col-md-8" style="margin-bottom: 10px;">
                            <input type="text" class="form-control" [readOnly]="'readOnly'">
                            <span class="input-group-btn">
                                <button type="button" class="btn btn-default" (click)="seleccionarArchivo()">
                                    <i class="fa fa-folder-open-o"></i>
                                </button>
                                <button type="button" class="btn btn-info" [disabled]="!campana.nombre">
                                    <i class="fa fa-play"></i>
                                </button>
                                <button type="button" class="btn btn-info">
                                    <i class="fa fa-pause"></i>
                                </button>
                                <button type="button" class="btn btn-danger" [disabled]="!campana.nombre">
                                    <i class="fa fa-trash-o"></i>
                                </button>
                            </span>
                        </div>
                        <small>
                            
                            Audio que será reproducido tan pronto el destinatario conteste la llamada, tenga en cuenta las siguientes recomendaciones.
                            <div style="margin-top: 10px;">
                                <strong>Audio notificación</strong>
                                <ul>
                                    <li>Inicie el audio despues de 400ms para que la llamada sea natural.</li>
                                    <li>Si el audio es comercial, procure que no dure más de 15 segundos.</li>
                                </ul>
                                <strong>Audio con opciones</strong>
                                <ul>
                                    <li>Inicie el audio despues de 400ms para que la llamada sea natural.</li>
                                    <li>Deje al menos 2 segundos al final del audio para que el usuario pueda presionar las opciones.</li>
                                </ul>
                            </div>
                        </small>
                    </div>
                    <div class="text-right" *ngIf="campana.nombre === ''">
                        <hr>
                        <app-response-message [inProgress]="inProgress"></app-response-message>
                        <button class="btn btn-default" (click)="cancelar()" style="margin-right: 10px;">Cancelar</button>
                        <button type="submit" 
                        [disabled]="!formDialer.valid || inProgress.progress"
                        class="btn btn-primary">
                        <i class="fa fa-spinner fa-spin" *ngIf="inProgress.progress"></i> Crear campaña</button>
                    </div>
                </div>
            </div>
            
            <div class="text-right" *ngIf="campana.nombre !== ''">
                <hr>
                <app-response-message [inProgress]="inProgress"></app-response-message>
                <button class="btn btn-default" (click)="cancelar()" style="margin-right: 10px;">Cancelar</button>
                <button type="submit" 
                [disabled]="!formDialer.valid || inProgress.progress"
                class="btn btn-primary">
                <i class="fa fa-spinner fa-spin" *ngIf="inProgress.progress"></i> Guardar campaña</button>
            </div>
        </form>
    </div>
</div>
<ng-template #loading>
    <div style="padding: 80px;" class="text-primary text-center">
        <i class="fa fa-2x fa fa-spinner fa-spin" style="margin-bottom: 10px;"></i><br>
        Un momento por favor...
    </div>
</ng-template>