<div style="padding: 10px" class="row">
    <div class="col-md-3" style="margin-bottom: 10px">        
        <div class="text-muted bs-callout bs-callout-success" style="font-size: 13px">
            <h4 class="text-success">
                Administración de Agentes
            </h4>
            <p>
            En esta sección puedes crear los agentes que requieras para las diferentes colas, una vez creados, podrás agregarlos en cada cola de llamada.
            </p>
        </div>
        <button class="btn btn-sm btn-success" (click)="crearNuevoAgente()"><i class="fa fa-plus-circle"></i> Crear nuevo agente</button>
        <hr class="visible-xs">
    </div>

    <div class="col-md-9">
        <!-- Nav tabs -->
        <ul class="nav nav-pills" role="tablist">
            <li role="presentation" class="active">
                <a href="#agentes" aria-controls="agentes" role="tab" data-toggle="tab">
                    <i class="fa fa-headphones"></i> Agentes disponibles</a>
            </li>
            <li role="presentation">
                <a href="#pausereasons" aria-controls="pausereasons" role="tab" data-toggle="tab">
                    <i class="fa fa-hand-stop-o"></i> Motivos de Auxiliar</a></li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
            <div role="tabpanel" class="tab-pane fade in active" id="agentes">
                <div class="row" style="padding: 10px;">
                    <div class="col-md-3" *ngFor="let a of (agentes$ | async)" style="margin-bottom: 10px">
                        <button type="button" class="btn btn-block text-left" (click)="editarAgente(a)">
                            <div class="text-left">
                            <i class="fa fa-user-circle-o"></i> Agente: {{a.idagente}}
                            <br>
                            <span class="text-muted"><strong>{{a.nombre}}</strong></span>
                            </div>
                        </button>                 
                    </div>
                </div>
            </div>
            <div role="tabpanel" class="tab-pane fade" id="pausereasons">
                <app-pause-reasons></app-pause-reasons>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" id="nuevoAgente">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h5 class="modal-title"><i class="fa fa-headphones"></i> Creación de nuevo Agente</h5>
      </div>
      <div class="modal-body">
            <agente-detail [agente]="nuevoAgente" [readOnly]="readOnly" (agenteEmitter)="guardarAgente($event)"></agente-detail>
            <div *ngIf="errMsj">
                <hr style="margin-top: 10px">  
                <p class="text-center">
                    <span class="text-danger" style="padding-top: 10px">{{errMsj}}</span>
                </p>
            </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
